import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'rza-mean-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
    @Input() cardClasses: string | undefined;
    @Input() headerClasses: string | undefined;
    @Input() bodyClasses: string | undefined;
    @Input() footerClasses: string | undefined;
    @Input() headerText: string | undefined;
    @Input() bodyText: any | undefined;
    @Input() footerText: string | undefined;
    @Input() sideText: string | undefined;
    @Input() infoText: string | undefined;
    @Input() alignment: 'left' | 'center' | 'right' = 'left';
    @Input() selectable: boolean | undefined;
    @Input() checkedClasses: string | undefined;
    @Input() uncheckedClasses: string | undefined;
    @Input() checkboxHasBorder = true;
    @Input() selectionType: 'radio' | 'checkbox' | 'mixed' | undefined;
    @Input() isSelected = false;
    @Input() tooltipWidth = 0;
    @Input() color: string = 'secondary';
    @Input() hasBorder: boolean = false;
    @Input() borderColor: string = 'primary';
    @Input() absoluteHeaderClasses: string | undefined;

    checkbox: boolean | undefined;
    radio: boolean | undefined;
    mixed: boolean | undefined;

    @ContentChild('header', { descendants: true }) header: TemplateRef<any> | null;
    @ContentChild('body', { descendants: true }) body: TemplateRef<any> | null;
    @ContentChild('footer', { descendants: true }) footer: TemplateRef<any> | null;
    @ContentChild('side', { descendants: true }) side: TemplateRef<any> | null;
    @ContentChild('info', { descendants: true }) info: TemplateRef<any> | null;

    constructor() {
        this.header = null;
        this.body = null;
        this.footer = null;
        this.side = null;
        this.info = null;
    }

    ngOnInit(): void {
        this.checkbox = this.selectionType === 'checkbox';
        this.radio = this.selectionType === 'radio';
        this.mixed = this.selectionType === 'mixed';
    }
}
