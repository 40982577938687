import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalServicesComponent } from './additional-services.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '@rza-mean/ui';
import { ServiceCardComponent } from '../../components/service-card/service-card.component';

const routes: Routes = [
    {
        path: '',
        component: AdditionalServicesComponent,
    },
];

@NgModule({
    declarations: [AdditionalServicesComponent, ServiceCardComponent],
    imports: [CommonModule, RouterModule.forChild(routes), SharedModule, UiModule],
})
export class AdditionalServicesModule {}
