<div [formGroup]="companyPersonForm">
    <div class="mb-6 grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
        <div class="">
            <rza-mean-select
                formControlName="salutation"
                label="Anrede*"
                [options]="salutationList"
                selectId="salutation"
            ></rza-mean-select>
        </div>
        <div class="">
            <div rzaMeanDropdown #dropDown="rzaMeanDropdown" [closeOnToggle]="false">
                <rza-mean-input
                    #input
                    rzaMeanTypeahead
                    #typeahead="rzaMeanTypeahead"
                    [options]="titleList"
                    formControlName="title"
                    label="Titel"
                    rzaMeanDropdownToggle
                    btnAppend="x"
                    (btnAppendClick)="clearTitle(input)"
                    [disableOnBlurFromTemplate]="true"
                ></rza-mean-input>
                <div rzaMeanDropdownMenu class="-mt-[7px] rounded-md border bg-white py-2 shadow-2xl ring-1 ring-black ring-opacity-5">
                    <div rzaMeanDropdownItem *ngFor="let item of typeahead.filtered | async">
                        <div
                            (click)="selectTitle(item); input.onBlur()"
                            class="hover:bg-secondary-light cursor-pointer px-5 py-1 font-semibold"
                            [innerHtml]="item | highlight : input.value : true"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <rza-mean-input formControlName="lastname" label="Nachname*"></rza-mean-input>
        </div>
        <div class="">
            <rza-mean-input formControlName="firstname" label="Vorname*"></rza-mean-input>
        </div>
    </div>

    <div formGroupName="address" class="mb-6 grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
        <div class="">
            <rza-mean-input formControlName="street" label="Strasse & Nr.*"></rza-mean-input>
        </div>
        <div class="">
            <rza-mean-input formControlName="zip" label="PLZ*"></rza-mean-input>
        </div>
        <div class="">
            <rza-mean-input formControlName="city" label="Unternehmensstandort*"></rza-mean-input>
        </div>
        <div class="">
            <rza-mean-select formControlName="country" label="Land*" [options]="countryList" selectId="country"></rza-mean-select>
        </div>
    </div>

    <div class="mb-6 grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
        <div class="">
            <rza-mean-input formControlName="email" label="E-Mail*"></rza-mean-input>
        </div>
        <div class="">
            <rza-mean-input formControlName="phone" label="Telefon-Nr.*"></rza-mean-input>
        </div>
    </div>
</div>
