<div class="flex items-stretch justify-center gap-x-4 md:gap-x-10">
    <div class="flex basis-1/2 justify-end">
        <div *ngIf="!isFirstPage">
            <button class="btn max-md:btn-sm btn-outline-primary flex h-full items-center bg-white text-lg" (click)="navToPreviousPage()">
                <fa-icon class="text-primary hidden text-xl leading-none max-sm:inline" icon="arrow-left"></fa-icon>
                <span class="max-sm:hidden">Zurück</span>
            </button>
        </div>

        <button *ngIf="isFirstPage" (click)="redirectOrContactForm()" class="btn max-sm:btn-sm btn-outline-secondary bg-white text-lg">
            Jetzt beraten lassen
        </button>
    </div>

    <div class="basis-1/2">
        <button rzaMeanShiny (click)="navToNextPage()" [disabled]="disabled" class="btn max-md:btn-sm btn-primary h-full text-lg" #nextBtn>
            <div *ngIf="oneClickFlag || showSpinner" class="text-center">
                <img
                    class="inline animate-spin"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CjxjaXJjbGUgY3g9IjciIGN5PSI3IiByPSI2IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS1vcGFjaXR5PSIuMSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGZpbGw9IiNmZmZmZmYiIGZpbGwtb3BhY2l0eT0iMSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNNyAwYTcgNyAwIDAgMSA3IDdoLTJhNSA1IDAgMCAwLTUtNVYweiIvPgo8L2c+Cjwvc3ZnPg=="
                    alt=""
                />
            </div>
            <div
                style="hyphens: auto"
                *ngIf="!(oneClickFlag || showSpinner)"
                [innerHTML]="nextPageBtnText ? nextPageBtnText : (nextBreadcrumb$ | async)?.navigationText"
            ></div>
        </button>
    </div>
</div>
