import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, tap } from 'rxjs';
import { addDiscount, addDiscounts } from '../store/discount/discount.actions';
import { Store } from '@ngrx/store';
import { ToastService } from '@rza-mean/ui';
import { Discount, DiscountCode } from '../shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class DiscountService {
    constructor(private apiService: ApiService, private store: Store, private toastr: ToastService) {}

    loadDiscountCode(code: string): Observable<Discount | DiscountCode> {
        return this.apiService.loadDiscountCode(code).pipe(
            tap((discount) => {
                if ('discounts' in discount) {
                    this.store.dispatch(addDiscounts({ discounts: (discount as DiscountCode).discounts }));
                } else {
                    this.store.dispatch(addDiscount({ discount }));
                }
                if (discount != null) {
                    this.toastr.success(undefined, `Rabattcode '${code}' erfolgreich aktiviert.`, 3000);
                } else {
                    this.toastr.warning(undefined, 'Rabattcode nicht valide.', 3000);
                }
            })
        );
    }
}
