<rza-mean-page>
    <rza-mean-section>
        <rza-mean-title
            headline="Unternehmens&shy;daten"
            subHeadline="Super, Sie haben es geschafft. Tragen Sie nun bitte Ihre Unternehmensdaten ein."
        ></rza-mean-title>
    </rza-mean-section>
    <rza-mean-section class="flex flex-grow flex-col justify-center">
        <div class="mb-6">
            <rza-mean-company-form
                (updateCompany)="updateCompanyForm($event)"
                (isValid)="isCompanyFormValid = $event"
            ></rza-mean-company-form>
        </div>
        <div class="mb-6">
            <rza-mean-company-person-form
                (updateCompanyPerson)="updateCompanyPersonForm($event)"
                (isValid)="isCompanyPersonFormValid = $event"
            ></rza-mean-company-person-form>
        </div>
        <div>
            <rza-mean-company-identification-form
                (updateCompanyIdentification)="updateCompanyIdentificationForm($event)"
                (isValid)="isCompanyIdentificationFormValid = $event"
            ></rza-mean-company-identification-form>
        </div>
        <div class="mb-5"><small>*Pflichtfelder</small></div>
    </rza-mean-section>

    <rza-mean-page-navigation [disabled]="!disableNavigation()"></rza-mean-page-navigation>
</rza-mean-page>
