import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from './company.state';
import { Company, CompanySubsidiary, Customer } from '../../shared/interfaces';

const companyState = createFeatureSelector<CompanyState>('company');

export const selectCompany = createSelector(companyState, (state: CompanyState) => state.company);

export const selectCustomer = createSelector(companyState, (state: CompanyState) => state.customer);

export const selectDocuments = createSelector(companyState, (state: CompanyState) => state.documents);

export const selectCompanyLoadingStatus = createSelector(companyState, (state: CompanyState) => state.loading);

export const selectCompanyErrorStatus = createSelector(companyState, (state: CompanyState) => state.error);

export const selectMainCompanyBranch = createSelector(selectCompany, (company: Company) => {
    return {
        _id: company._id,
        contact: company.contact,
        institutionCode: company.institutionCode,
    } as CompanySubsidiary;
});

export const selectIdBusinessForm = createSelector(selectCompany, (company: Company) => {
    return company.businessForm._id;
});

export const selectSubsidiaries = createSelector(selectCompany, (company: Company) => (company.subsidiaries ? company.subsidiaries : []));

export const selectContactPersons = createSelector(selectCompany, (company: Company) =>
    company.contactPersons ? company.contactPersons : []
);

export const selectBankCredentials = createSelector(selectCustomer, (customer: Customer) => customer.bankCredentials);

export const selectPreviousSettlementCenter = createSelector(selectCustomer, (customer: Customer) => customer.previousSettlementCenter);

export const selectReceiptEntranceNotification = createSelector(
    selectCustomer,
    (customer: Customer) => customer.receiptEntranceNotification
);

export const selectReceiptSubmission = createSelector(selectCustomer, (customer: Customer) => customer.receiptSubmission);

export const selectCompanyContact = createSelector(selectCompany, (company: Company) => company.contact);
