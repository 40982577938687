import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { filter, map, mergeMap, Observable, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionService } from '../../services/session.service';
import { Store } from '@ngrx/store';
import { MinimalProfession, MinimalProfessionBranch, Profession } from '../../shared/interfaces';
import { selectConfiguredIdProfession } from '../../store/configuration/configuration.selectors';
import { ProfessionStatusEnum } from '@rza-mean/api-interfaces';
import {
    selectMinimalProfessionsByBranch,
    selectProfessionBranches,
    selectSelectedProfession,
    selectSelectedProfessionBranch,
} from '../../store/profession/profession.selectors';
import { setSelectedProfessionBranch } from '../../store/profession/profession.actions';
import { ContactRequestService } from '../../services/contact-request.service';
import { resetConfig } from '../../store/configuration/configuration.actions';

@Component({
    selector: 'rza-mean-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    professionBranches$!: Observable<MinimalProfessionBranch[]>;
    selectedIdProfession: string | undefined;
    private readonly apiUrl: string;
    selectedProfession$!: Observable<Profession>;
    private unsubscribe$ = new Subject();
    professionStatusEnum = ProfessionStatusEnum;

    selectedProfessionBranch$!: Observable<string | undefined>;
    professions$!: Observable<MinimalProfession[]>;

    constructor(private sessionService: SessionService, public store: Store, private contactRequestService: ContactRequestService) {
        this.apiUrl = environment.apiUrl;
    }

    ngOnInit() {
        this.professionBranches$ = this.store.select(selectProfessionBranches);
        this.selectedProfessionBranch$ = this.store.select(selectSelectedProfessionBranch);
        this.selectedProfessionBranch$.subscribe();
        this.professions$ = this.selectedProfessionBranch$.pipe(
            filter((branch) => branch != undefined),
            mergeMap((selectedProfessionBranch) =>
                this.store.select(selectMinimalProfessionsByBranch(selectedProfessionBranch)).pipe(
                    map((profession) =>
                        profession.sort((a, b) => {
                            if (a.status === ProfessionStatusEnum.CONTACT && b.status !== ProfessionStatusEnum.CONTACT) return 1;
                            if (a.status !== ProfessionStatusEnum.CONTACT && b.status === ProfessionStatusEnum.CONTACT) return -1;
                            if (a.status !== ProfessionStatusEnum.CONTACT && b.status !== ProfessionStatusEnum.CONTACT) {
                                return a.name.localeCompare(b.name);
                            }
                            return 0;
                        })
                    )
                )
            )
        );
        this.store
            .select(selectConfiguredIdProfession)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((id) => {
                this.selectedIdProfession = id;
            });
        this.selectedProfession$ = this.store.select(selectSelectedProfession);
        this.selectedProfession$.subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    onSelectProfession(minimalProfession: MinimalProfession) {
        if (this.selectedIdProfession !== minimalProfession._id && ProfessionStatusEnum.ACTIVE.valueOf() === minimalProfession.status) {
            this.selectedIdProfession = minimalProfession._id;
            this.sessionService.init(this.selectedIdProfession).subscribe();
        }
        if (ProfessionStatusEnum.CONTACT.valueOf() === minimalProfession.status) {
            this.selectedIdProfession = minimalProfession._id;
            this.contactRequestService.setIdProfession(this.selectedIdProfession);
            this.contactRequestService.openContactRequestModal();
        }
    }

    onSelectProfessionBranch(branch: string) {
        this.store.dispatch(setSelectedProfessionBranch({ branch }));
    }

    onUnSelectProfessionBranch() {
        this.store.dispatch(setSelectedProfessionBranch({ branch: undefined }));
        if (this.selectedIdProfession) {
            this.store.dispatch(resetConfig());
        }
        this.selectedIdProfession = undefined;
    }

    protected readonly ProfessionStatusEnum = ProfessionStatusEnum;
}
