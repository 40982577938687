import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { ContactRequest } from '../../../shared/interfaces';
import { ToastService } from '@rza-mean/ui';
import { NavigationService } from '../../../services/navigation.service';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';

@Component({
    selector: 'rza-mean-contact-request-form',
    templateUrl: './contact-request-form.component.html',
    styleUrls: ['./contact-request-form.component.scss'],
})
export class ContactRequestFormComponent {
    @Input() idProfession!: string;
    contactForm: FormGroup;
    disableBtn = false;

    @Output() closeEvent = new EventEmitter<boolean>();

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        private navigationService: NavigationService,
        private gtmService: GoogleTagManagerService
    ) {
        this.contactForm = this.fb.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            // TODO: change to gender
            // salutation: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            subject: ['', Validators.required],
            message: ['', Validators.required],
            companyName: ['', Validators.required],
        });
    }

    sendContactRequest() {
        if (this.contactForm.valid && this.idProfession !== '') {
            const request: ContactRequest = {
                ...this.contactForm.value,
                profession: this.idProfession,
            };
            this.gtmService.contactRequestSent(request);
            this.disableBtn = true;
            this.apiService.sendContactRequest(request, this.navigationService.isTestMode).subscribe((resp) => {
                this.disableBtn = false;
                this.contactForm.reset();
            });
        }
    }

    close() {
        this.closeEvent.emit(true);
    }
}
