<div class="col-span-12 max-w-4xl lg:col-span-8 xl:col-span-6">
    <form [formGroup]="contactForm">
        <div class="mb-6">
            <rza-mean-input formControlName="companyName" label="Firmenname" inputId="companyName"></rza-mean-input>
        </div>
        <div class="mb-6">
            <rza-mean-input formControlName="firstname" label="Vorname" inputId="firstname"></rza-mean-input>
        </div>
        <div class="mb-6">
            <rza-mean-input formControlName="lastname" label="Nachname" inputId="lastname"></rza-mean-input>
        </div>
        <div class="mb-6">
            <rza-mean-input formControlName="email" label="E-Mail-Adresse" inputId="email"></rza-mean-input>
        </div>
        <div class="mb-6">
            <rza-mean-input formControlName="phone" label="Telefon-Nr." inputId="phone"></rza-mean-input>
        </div>
        <div class="mb-6">
            <rza-mean-input formControlName="subject" label="Betreff" inputId="subject"></rza-mean-input>
        </div>
        <rza-mean-text-area label="Ihre Nachricht" formControlName="message" inputId="message"></rza-mean-text-area>
    </form>
    <small class="text-muted inline-block">*Alle Felder sind Pflichtfelder</small>

    <div class="flex justify-end gap-4 max-sm:flex-col">
        <button class="btn max-md:btn-sm !btn-outline-primary" (click)="close()">Abbrechen</button>
        <button
            rzaMeanShiny
            class="btn max-md:btn-sm btn-primary"
            [disabled]="!contactForm.valid || disableBtn"
            (click)="sendContactRequest()"
        >
            Absenden
        </button>
    </div>
</div>
