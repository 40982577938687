import {
    ApplicationRef,
    Component,
    ComponentRef,
    createComponent,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Injectable,
    Injector,
    Input,
    OnDestroy,
    Output,
    ViewContainerRef,
} from '@angular/core';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { delay, Subject, takeUntil, timer } from 'rxjs';
import { fadeInOut, slideInOut } from '../misc/animations';

type ToastData = {
    title?: string;
    content?: string;
    bgColorSuffix?: string;
    colorSuffix?: string;
};

type ToastrOptions = {
    closeTime?: number;
    colorSuffix?: string;
};
@Component({
    selector: 'rza-mean-toast',
    standalone: true,
    template: `
        <div
            [@fadeInOut]="animationStatus"
            [@slideInOut]="{ value: animationStatus, params: { translate: 'translate(100%, 0)' } }"
            *ngIf="data"
            (click)="animationStatus = 'out'; closed.emit()"
            class="card mb-6 min-w-[360px] cursor-pointer p-5"
            [ngClass]="['!bg-' + data.bgColorSuffix, 'text-' + data.colorSuffix]"
        >
            <div class="card-header p-4 pb-0" *ngIf="data.title">
                <div class="text-lg font-semibold" [innerHtml]="data.title"></div>
            </div>
            <div class="card-body !p-4 text-lg" [ngClass]="{ '!pt-2': data.title }" [innerHtml]="data.content"></div>
        </div>
    `,
    imports: [NgClass, NgIf],
    animations: [fadeInOut, slideInOut],
})
class ToastComponent {
    @Input() data: ToastData | undefined;
    @Output() closed = new EventEmitter();
    animationStatus = 'in';
}

@Component({
    selector: 'rza-mean-toastr',
    standalone: true,
    template: ``,
})
export class ToastrComponent implements OnDestroy {
    @HostBinding('class') classes = 'fixed z-[1000] bottom-8 right-8 flex flex-col-reverse overflow-hidden';
    @Input() options!: ToastrOptions;

    toasts: Map<string, ToastComponent> = new Map<string, ToastComponent>();
    private unsubscribe$ = new Subject<void>();

    constructor(public elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {}

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createToast(title: string | undefined, content: string, colorSuffix: string) {
        const toastRef = this.viewContainerRef.createComponent(ToastComponent);
        this.elementRef.nativeElement.appendChild(toastRef.location.nativeElement);
        toastRef.instance.data = {
            title,
            content,
            bgColorSuffix: colorSuffix,
            colorSuffix: this.options.colorSuffix,
        };

        const hash = this.hash(toastRef.instance.data);
        this.toasts.set(hash, toastRef.instance);
        toastRef.instance.closed.pipe(takeUntil(this.unsubscribe$), delay(250)).subscribe(() => {
            this.toasts.delete(hash);
            toastRef.destroy();
        });

        if (this.options.closeTime) {
            timer(this.options.closeTime).subscribe(() => {
                this.toasts.delete(hash);
                toastRef.destroy();
            });
        }
    }

    private hash(data: ToastData) {
        const str = Object.keys(data).join('');
        const seed = 0;
        let h1 = 0xdeadbeef ^ seed,
            h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }

        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

        return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
    }
}
@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private toastr!: ComponentRef<ToastrComponent>;
    private colorSuffix = 'white';

    constructor(private appRef: ApplicationRef, private injector: Injector, @Inject(DOCUMENT) private document: Document) {}

    success(title: string | undefined, content: string, timer: number = 3000) {
        this.open(title, content, timer, 'success');
    }

    error(title: string | undefined, content: string, timer: number = 3000) {
        this.open(title, content, timer, 'danger');
    }

    info(title: string | undefined, content: string, timer: number = 3000) {
        this.open(title, content, timer, 'info');
    }

    warning(title: string | undefined, content: string, timer: number = 3000) {
        this.open(title, content, timer, 'warning');
    }

    private open(title: string | undefined, content: string, timer: number = 0, colorSuffix: string) {
        this.createToastr(timer);
        this.toastr.instance.createToast(title, content, colorSuffix);
    }

    private createToastr(timer: number) {
        if (this.document.getElementsByTagName('rza-mean-toastr').length === 0) {
            this.toastr = createComponent(ToastrComponent, {
                environmentInjector: this.appRef.injector,
                elementInjector: this.injector,
            });
            this.toastr.instance.options = {
                closeTime: timer,
                colorSuffix: this.colorSuffix,
            };
            this.appRef.attachView(this.toastr.hostView);
            this.document.body.appendChild(this.toastr.location.nativeElement);
        }
    }
}
