<label class="relative flex cursor-pointer {{ classes }}">
    <span class="relative inline-block h-full">
        <fa-icon
            [ngClass]="{ 'opacity-0': !withBorder }"
            class="text-white text-2xl {{ uncheckedClasses }}"
            [icon]="['far', 'square']"
        ></fa-icon>
        <fa-icon
            *ngIf="selected"
            class="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 text-secondary text-lg {{ checkedClasses }}"
            icon="check"
        ></fa-icon>
    </span>

    <input
        type="checkbox"
        hidden=""
        [(ngModel)]="selected"
        (change)="change($event)"
        [disabled]="disabled || readOnly"
        [readOnly]="readOnly"
        class="focus:ring-secondary checked:bg-primary checked:border-primary relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-300 bg-gray-100 focus:ring"
    />
    <span *ngIf="label" class="ml-3" [innerHtml]="label"></span>
</label>
