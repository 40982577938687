import {
    ApplicationRef,
    Component,
    ComponentRef,
    createComponent,
    ElementRef,
    EventEmitter,
    Inject,
    Injectable,
    Injector,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { hideScrollbar, isDescendantOf } from '../misc/utils';
import { Observable } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

type ModalOptions = {
    denyText?: string;
    confirmText?: string;
    context?: any;
};
type ModalData = ModalOptions & {
    content: string | TemplateRef<any> | any;
};
type ModalConfig = {
    title: string;
    data: ModalData;
};

@Component({
    selector: 'rza-mean-modal-container',
    standalone: true,
    template: `
        <div (click)="onClick($event)" class="fixed left-0 top-0 z-50 flex h-full w-full">
            <div class="mx-auto flex w-full max-w-[800px] flex-col items-center justify-center p-5">
                <div #card class="card w-full overflow-y-auto bg-white">
                    <div class="card-header flex px-8 py-5">
                        <div class="text-primary text-xl font-medium leading-tight" [innerHTML]="config?.title"></div>
                        <div class="ml-auto cursor-pointer" (click)="close(false)">
                            <fa-icon class="text-gray-500" icon="xmark"></fa-icon>
                        </div>
                    </div>
                    <div class="">
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
        </div>
    `,
    imports: [FontAwesomeModule],
})
class ModalContainerComponent {
    @Input() config: ModalConfig | undefined;

    @Output() close$ = new EventEmitter<boolean>();

    @ViewChild('card') private card!: ElementRef;

    onClick($event: MouseEvent) {
        if (!isDescendantOf(this.card.nativeElement, $event.target as HTMLElement)) {
            this.close(false);
        }
    }

    close(isConfirmed: boolean) {
        this.close$.emit(isConfirmed);
    }
}

@Component({
    selector: 'rza-mean-info-modal',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="px-8 py-5">
            <ng-container *ngIf="template">
                <ng-container *ngTemplateOutlet="template; context: config.data.context"></ng-container>
            </ng-container>

            <ng-container *ngIf="content">
                <div [innerHtml]="content"></div>
            </ng-container>

            <ng-container *ngIf="component">
                <ng-container *ngComponentOutlet="component"></ng-container>
            </ng-container>
        </div>
    `,
})
class InfoModalComponent implements OnInit {
    template: TemplateRef<any> | undefined;
    content: string | undefined;
    component: any | undefined;

    @Input() config!: ModalConfig;
    @Input() ref!: ModalContainerComponent;

    ngOnInit() {
        if (this.config.data?.content instanceof TemplateRef) {
            this.template = this.config.data.content;
        } else if (typeof this.config.data.content === 'string') {
            this.content = this.config.data?.content;
        } else {
            this.component = typeof this.config.data.content;
        }
    }
}

@Component({
    selector: 'rza-mean-confirm-modal',
    standalone: true,
    template: `
        <div class="px-8 py-5">
            <ng-container *ngIf="template">
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </ng-container>

            <ng-container *ngIf="content">
                <div [innerHtml]="content"></div>
            </ng-container>

            <ng-container *ngIf="component">
                <ng-container *ngComponentOutlet="component"></ng-container>
            </ng-container>
        </div>

        <hr />

        <div class="flex justify-end px-8 py-5">
            <button (click)="deny()" type="button" class="btn btn-outline-primary">
                {{ this.config.data.denyText || 'Abbrechen' }}
            </button>
            <button (click)="confirm()" type="button" class="btn btn-primary ml-4">
                {{ this.config.data.confirmText || 'Ok' }}
            </button>
        </div>
    `,
    imports: [CommonModule],
})
export class ConfirmModalComponent implements OnInit {
    template: TemplateRef<any> | undefined;
    content: string | undefined;
    component: any | undefined;
    @Input() config!: ModalConfig;
    @Input() ref!: ModalContainerComponent;

    ngOnInit() {
        if (this.config.data.content instanceof TemplateRef) {
            this.template = this.config.data.content;
        } else if (typeof this.config.data.content === 'string') {
            this.content = this.config.data.content;
        } else {
            this.component = typeof this.config.data.content;
        }
    }

    confirm() {
        this.ref.close(true);
    }

    deny() {
        this.ref.close(false);
    }
}

@Component({
    selector: 'rza-mean-modal-backdrop',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'fixed top-0 left-0 h-full w-full bg-black/50 animate-fade-in z-50',
    },
    template: ``,
    standalone: true,
    styles: [':host {opacity: 1; transition: opacity .25s;}'],
})
class ModalBackdropComponent {}

@Injectable({ providedIn: 'root' })
export class ModalService {
    private readonly defaultConfig = {};

    constructor(private appRef: ApplicationRef, private injector: Injector, @Inject(DOCUMENT) private document: Document) {}

    open(
        title: string,
        content: string | TemplateRef<any> | any,
        options: ModalOptions | null = null,
        type: typeof InfoModalComponent | typeof ConfirmModalComponent = InfoModalComponent
    ): { ref: ComponentRef<ModalContainerComponent>; destroy: () => void; close: EventEmitter<boolean> } {
        const config: ModalConfig = {
            ...this.defaultConfig,
            title,
            data: {
                content,
                ...options,
            },
        };

        const showScrollbar = hideScrollbar(this.document);

        let contentRef: ComponentRef<any>;
        if (!(content instanceof TemplateRef) && !(typeof content === 'string')) {
            contentRef = createComponent(content, {
                environmentInjector: this.appRef.injector,
                elementInjector: this.injector,
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            contentRef = createComponent(type, {
                environmentInjector: this.appRef.injector,
                elementInjector: this.injector,
            });
            contentRef.instance.config = config;
        }
        this.appRef.attachView(contentRef.hostView);

        const backdropRef = createComponent(ModalBackdropComponent, {
            environmentInjector: this.appRef.injector,
            elementInjector: this.injector,
        });
        this.appRef.attachView(backdropRef.hostView);
        this.document.body.appendChild(backdropRef.location.nativeElement);

        const containerRef = createComponent(ModalContainerComponent, {
            environmentInjector: this.appRef.injector,
            elementInjector: this.injector,
            projectableNodes: [[contentRef.location.nativeElement]],
        });
        containerRef.instance.config = config;
        contentRef.instance.ref = containerRef.instance;
        this.appRef.attachView(containerRef.hostView);
        this.document.body.appendChild(containerRef.location.nativeElement);

        containerRef.instance.close$.subscribe(() => {
            backdropRef.destroy();
            containerRef.destroy();
            contentRef.destroy();
            showScrollbar();
        });

        return {
            ref: containerRef,
            destroy: function () {
                backdropRef.destroy();
                containerRef.destroy();
                contentRef.destroy();
                showScrollbar();
            },
            close: containerRef.instance.close$,
        };
    }
}

// @NgModule({
//     imports: [
//       CommonModule
//     ],
//     declarations: [
//         ModalContainerComponent,
//         ModalBackdropComponent,
//         InfoModalComponent,
//         ConfirmModalComponent,
//     ],
//     providers: [
//         ModalService
//     ]
// })
// export class RzaMeanModalModule {
//     // static forRoot(): ModuleWithProviders<unknown> {
//     //     return {
//     //         ngModule: RzaMeanModalModule,
//     //         providers: [ModalService]
//     //     }
//     // }
// }
