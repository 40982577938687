import { Pipe, PipeTransform } from '@angular/core';
import { PaymentSpeed } from '../shared/interfaces';

@Pipe({ name: 'paymentSpeed' })
export class PaymentSpeedPipe implements PipeTransform {
    transform(paymentSpeed: PaymentSpeed | undefined, valueOnly = false): string {
        if (!paymentSpeed) return '';
        if (paymentSpeed.value === '0' || paymentSpeed.milliseconds === 0) {
            return 'sofort';
        }
        if (valueOnly) {
            return paymentSpeed.value;
        }
        return `${paymentSpeed.value} ${paymentSpeed.unit}`;
    }
}
