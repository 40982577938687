import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BusinessForm } from '../shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BusinessFormsStoreService {
    baseUrl = environment.apiUrl;

    public state$: Observable<BusinessForm[]>;
    private _state$: BehaviorSubject<BusinessForm[]>;
    private fetchPending: boolean;

    constructor(private apiService: ApiService, private http: HttpClient) {
        this._state$ = new BehaviorSubject([] as BusinessForm[]);
        this.state$ = this._state$.asObservable();
        this.fetchPending = false;
        this.loadBusinessForms();
    }

    loadBusinessForms() {
        if (this.state && !this.fetchPending) {
            this.fetchPending = true;
            this.http.get<BusinessForm[]>(`${this.baseUrl}/business-forms`).subscribe((businessforms) => {
                this.fetchPending = false;
                this._state$.next(businessforms);
            });
        }
    }

    get state(): BusinessForm[] {
        return this._state$.getValue();
    }

    set state(nextState: BusinessForm[]) {
        this._state$.next(nextState);
    }
}
