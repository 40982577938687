export enum SettlementCenterEnum {
    SELBSTABRECHNER = 'Selbstabrechner',
    EXISTENZGRUENDER = 'Existenzgründer/keins',
    AZH = 'azh',
    OPTICA = 'Optica',
    RZH = 'RZH',
    SCHWERINER = 'Schweriner',
    MEDIFOX = 'Medifox Finance',
    ASBREMEN = 'as Bremen',
    ARNI = 'ARNI',
    ZRK = 'zrk',
    DMRZ = 'DMRZ',
    SONSTIGE = 'sonstige',
}
