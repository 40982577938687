import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { UiModule } from '@rza-mean/ui';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { ConfigurationEffects } from './store/configuration/configuration.effects';
import { configurationReducer } from './store/configuration/configuration.reducers';
import { companyReducer } from './store/company/company.reducer';
import { CompanyEffects } from './store/company/company.effects';
import { discountReducer } from './store/discount/discount.reducers';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { GlobalHttpInterceptor } from './interceptors/http-interceptor.service';
import { TestModeComponent } from './components/test-mode/test-mode.component';
import {
    faEnvelope,
    faXmark,
    faHeadset,
    faArrowDown,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faCircleCheck,
    faCircleDot,
    faExclamation,
    faFilePdf,
    faInfo,
    faLightbulb,
    faMessage,
    faPhone,
    faPrint,
    faShareNodes,
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';

import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';

import { professionReducer } from './store/profession/profession.reducer';
import { ProfessionEffects } from './store/profession/profession.effects';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { ContactRequestFormComponent } from './components/contact-request/contact-request-form/contact-request-form.component';
import { StartModule } from './pages/start/start.module';
import { ContactRequestComponent } from './components/contact-request/contact-request.component';

@NgModule({
    declarations: [AppComponent, HeaderComponent, TestModeComponent, ContactRequestFormComponent, ContactRequestComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        StoreModule.forRoot(
            {
                config: configurationReducer,
                company: companyReducer,
                discounts: discountReducer,
                profession: professionReducer,
            },
            {}
        ),
        EffectsModule.forRoot([ConfigurationEffects, CompanyEffects, ProfessionEffects]),
        UiModule,
        HttpClientModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        StartModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    exports: [ContactRequestFormComponent],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        const icons = [
            faCheckCircle,
            faInfo,
            faCheckSquare,
            faSquare,
            faLightbulb,
            faMessage,
            faPhone,
            faEnvelope,
            faCircleCheck,
            faExclamation,
            faArrowDown,
            faShareNodes,
            faFilePdf,
            faPrint,
            faCircle,
            faCircleDot,
            faCheck,
            faCaretDown,
            faXmark,
            faCommentDots,
            faHeadset,
            faArrowLeft,
        ];
        library.addIcons(...icons);
    }
}
