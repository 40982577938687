import { FunctionEnum } from '@rza-mean/api-interfaces';

export function handleFunctionalCalculation(
    functionName: string,
    parameters: Record<string, number>,
    userInput: Record<string, unknown>
): number {
    let value = 0;
    if (functionName === FunctionEnum.TRARET_GKV) {
        value = calculateTraretGKV(parameters, userInput);
    }
    return value;
}

function calculateTraretGKV(parameters: Record<string, number>, userInput: Record<string, unknown>): number {
    let price = 0;
    if (Number.isFinite(userInput['itemValue'])) {
        price = parameters['dividend'] / (userInput['itemValue'] as number);
        if (price < parameters['minValue']) {
            price = parameters['minValue'];
        }
        if (price > parameters['maxValue']) {
            price = parameters['maxValue'];
        }
    }
    return Number(price.toFixed(2));
}
