import { AfterViewInit, ApplicationRef, createComponent, Directive, ElementRef, Injector, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

@Directive({
    selector: '[rzaMeanListBullet]',
})
export class ListBulletDirective implements AfterViewInit {
    @Input() icon: IconProp = 'circle-check';
    @Input() classes: string[] = ['text-white'];
    @Input() size: SizeProp = 'xl';

    constructor(private elementRef: ElementRef, private injector: Injector, private appRef: ApplicationRef) {}

    ngAfterViewInit(): void {
        let list: HTMLUListElement | HTMLOListElement;
        if (this.elementRef.nativeElement.nodeName === 'OL' || this.elementRef.nativeElement.nodeName === 'UL') {
            list = this.elementRef.nativeElement;
        } else {
            list = this.elementRef.nativeElement.getElementsByTagName('ol').length
                ? this.elementRef.nativeElement.getElementsByTagName('ol')[0]
                : this.elementRef.nativeElement.getElementsByTagName('ul').length
                ? this.elementRef.nativeElement.getElementsByTagName('ul')[0]
                : null;
        }
        if (list) {
            this.replaceBullets(list);
            setTimeout(() => {
                this.replaceBullets(list);
            }, 0);
        }
    }

    private replaceBullets(list: HTMLUListElement | HTMLOListElement) {
        if (list.querySelectorAll('.ng-fa-icon').length < list.getElementsByTagName('li').length) {
            list.classList.add('list-none');
            Array.from(list.getElementsByTagName('li')).forEach((li) => {
                const content = document.createElement('div');
                content.innerHTML = li.innerHTML;
                li.innerHTML = '';
                li.className = 'flex my-2 first:mt-0 last:mb-0';
                const wrapper = document.createElement('div');
                wrapper.className = 'mr-3';
                const host = document.createElement('div');
                wrapper.appendChild(host);
                li.appendChild(content);
                li.prepend(wrapper);
                const icon = createComponent(FaIconComponent, {
                    hostElement: host,
                    environmentInjector: this.appRef.injector,
                    elementInjector: this.injector,
                });
                icon.instance.icon = this.icon;
                icon.instance.classes = this.classes;
                if (this.size) icon.instance.size = this.size;
                icon.instance.render();
                this.appRef.attachView(icon.hostView);
            });
        }
    }

    // public getBundleDescriptionList(description: string): string {
    //     const parser = new DOMParser();
    //     const html = parser.parseFromString(description, 'text/html');
    //     (html.getElementsByTagName('ol').length ? html.getElementsByTagName('ol')[0] : html.getElementsByTagName('ul')[0]).classList.add(
    //         'list-none'
    //     );
    //     Array.from(html.getElementsByTagName('li')).forEach((li) => {
    //         const span = html.createElement('span');
    //         span.className = 'fa-regular fa-circle-check text-primary';
    //         const icon = this.viewContainerRef.createComponent(FaIconComponent);
    //         li.prepend(span);
    //     });
    //     return html.getElementsByTagName('body')[0].innerHTML;
    // }
}
