import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../shared/interfaces';
import { ModalService } from '@rza-mean/ui';
import { Store } from '@ngrx/store';
import { selectAdditionalServicesCategorized } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-additional-services',
    templateUrl: './additional-services.component.html',
    styleUrls: ['./additional-services.component.scss'],
})
export class AdditionalServicesComponent implements OnInit {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    servicesCategorized$!: Observable<[string, Product[]][]>;
    constructor(private store: Store, private modalService: ModalService) {}

    ngOnInit(): void {
        this.servicesCategorized$ = this.store.select(selectAdditionalServicesCategorized);
    }

    openModal({ title, content }: { title: string; content: string }) {
        this.modalService.open(title, content);
    }
}
