import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { map, Observable, Subject } from 'rxjs';
import { Breadcrumb } from '../../shared/interfaces';

@Component({
    selector: 'rza-mean-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements AfterViewInit, OnDestroy {
    @HostBinding('class') classes = 'flex h-full';
    @ViewChild('progress') progress!: ElementRef;
    @ViewChild('totalProgress') totalProgress!: ElementRef;
    @ViewChildren('breadcrumbElement') breadcrumbElements!: QueryList<ElementRef>;
    @Input() bgColor: string | undefined;
    @Input() hiddeOnSmallDevices = true;
    public breadcrumbs$: Observable<Breadcrumb[]>;
    private unsubscribe$ = new Subject<void>();
    private breadcrumbs: Breadcrumb[] | undefined;
    currentIndex = 0;
    totalIndex = 0;

    @HostListener('window:resize')
    onResize() {
        this.calculateProgressWidth();
    }

    constructor(private navigationService: NavigationService) {
        this.breadcrumbs$ = navigationService.getVisibleBreadcrumbs();
        this.breadcrumbs$.subscribe((breadcrumbs) => {
            this.breadcrumbs = breadcrumbs;
            this.currentIndex = breadcrumbs.findIndex((b) => b.isCurrent);
            this.totalIndex = breadcrumbs.indexOf(breadcrumbs.filter((b) => b.visited).at(-1) || breadcrumbs[0]);
        });
    }

    ngAfterViewInit() {
        this.calculateProgressWidth();
        this.breadcrumbElements.changes.subscribe(() => {
            this.calculateProgressWidth();
        });
    }

    private calculateProgressWidth() {
        if (this.breadcrumbElements.length > 0) {
            setTimeout(() => {
                const elem = this.breadcrumbElements.get(this.currentIndex);
                const offset = elem?.nativeElement.offsetTop + elem?.nativeElement.getBoundingClientRect().height / 2;
                if (typeof offset === 'number') {
                    this.progress.nativeElement.style.height = `${offset}px`;
                }

                const totalElem = this.breadcrumbElements.get(this.totalIndex);
                const totalOffset = totalElem?.nativeElement.offsetTop + totalElem?.nativeElement.getBoundingClientRect().height / 2;
                if (typeof totalOffset === 'number') {
                    this.totalProgress.nativeElement.style.height = `${totalOffset}px`;
                }
            }, 0);
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setPage(breadcrumb: Breadcrumb) {
        this.navigationService.setPage(breadcrumb);
    }

    moreThanOneBreadcrumb(): Observable<boolean> {
        return this.breadcrumbs$.pipe(map((breadcrumbs) => breadcrumbs.length > 1));
    }
}
