import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TaxCountryCodeEnum } from '@rza-mean/api-interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidatorsService } from '../../../services/validators.service';
import { Subject, takeUntil } from 'rxjs';
import { CompanyIdentificationForm } from '../../../shared/interfaces';
import { Store } from '@ngrx/store';
import { selectCompany } from '../../../store/company/company.selectors';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'rza-mean-company-identification-form',
    templateUrl: './company-identification-form.component.html',
    styleUrls: ['./company-identification-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyIdentificationFormComponent implements OnInit, OnDestroy {
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() updateCompanyIdentification: EventEmitter<CompanyIdentificationForm> = new EventEmitter<CompanyIdentificationForm>();

    companyIdentificationForm: FormGroup;
    taxCountryCode = Object.values(TaxCountryCodeEnum);
    private unsubscribe$ = new Subject();

    constructor(private fb: FormBuilder, private validatorService: ValidatorsService, private store: Store) {
        this.companyIdentificationForm = this.fb.group(
            {
                institutionCode: [environment.companyData?.institutionCode ?? '', this.validatorService.institutionCodeValidator()],
                taxCountryCode: ['', this.validatorService.taxCountryCodeValidator()],
                taxId: [environment.companyData?.taxId ?? ''],
            },
            { updateOn: 'blur' }
        );
    }

    ngOnInit(): void {
        this.store
            .select(selectCompany)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((company) => {
                this.companyIdentificationForm.patchValue(company, { emitEvent: false });
                if (this.companyIdentificationForm.controls['taxCountryCode'].value === '') {
                    this.companyIdentificationForm.controls['taxCountryCode'].setValue(TaxCountryCodeEnum.DE, { emitEvent: false });
                }
                this.isValid.emit(this.companyIdentificationForm.valid);
            });
        this.companyIdentificationForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
            this.isValid.emit(this.companyIdentificationForm.valid);
            this.updateCompanyIdentification.emit(form);
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
