import { createReducer, on } from '@ngrx/store';
import { initialCompanyState } from './company.state';
import * as CompanyActions from './company.actions';
import produce from 'immer';

export const companyReducer = createReducer(
    initialCompanyState,
    on(CompanyActions.setCompany, CompanyActions.updateCompanyComponentGroup.updateCompany, (state, { company }) =>
        produce(state, (newState) => {
            newState.company = {
                ...newState.company,
                ...company,
            };
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.addSubsidiary, (state, { subsidiary }) =>
        produce(state, (newState) => {
            if (newState.company.subsidiaries) {
                newState.company.subsidiaries.push(subsidiary);
            } else {
                newState.company.subsidiaries = [subsidiary];
            }
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateSubsidiary, (state, { subsidiary, index }) =>
        produce(state, (newState) => {
            if (newState.company.subsidiaries && newState.company.subsidiaries.length > index) {
                newState.company.subsidiaries[index] = subsidiary;
            }
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.removeSubsidiary, (state, { index }) =>
        produce(state, (newState) => {
            if (newState.company.subsidiaries && newState.company.subsidiaries.length > index) {
                newState.company.subsidiaries.splice(index, 1);
            }
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.addContactPerson, (state, { contact }) =>
        produce(state, (newState) => {
            if (newState.company.contactPersons) {
                newState.company.contactPersons.push(contact);
            } else {
                newState.company.contactPersons = [contact];
            }
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateContactPerson, (state, { contact, index }) =>
        produce(state, (newState) => {
            if (newState.company.contactPersons && newState.company.contactPersons.length > index) {
                newState.company.contactPersons[index] = contact;
            }
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.removeContactPerson, (state, { index }) =>
        produce(state, (newState) => {
            if (newState.company.contactPersons && newState.company.contactPersons.length > index) {
                newState.company.contactPersons.splice(index, 1);
            }
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateBankCredentials, (state, { bankCredentials }) =>
        produce(state, (newState) => {
            newState.customer.bankCredentials = bankCredentials;
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateReceiptSubmission, (state, { receiptSubmission }) =>
        produce(state, (newState) => {
            newState.customer.receiptSubmission = receiptSubmission;
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateReceiptEntranceNotification, (state, { receiptEntranceNotification }) =>
        produce(state, (newState) => {
            newState.customer.receiptEntranceNotification = receiptEntranceNotification;
        })
    ),
    on(CompanyActions.updatePreviousSettlementCenter.updatePreviousSettlementCenter, (state, { previousSettlementCenter }) =>
        produce(state, (newState) => {
            newState.customer.previousSettlementCenter = previousSettlementCenter;
        })
    ),
    on(CompanyActions.setCustomer, (state, { customer }) =>
        produce(state, (newState) => {
            newState.customer = customer;
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateCompanyForm, (state, { companyForm }) =>
        produce(state, (newState) => {
            newState.company = {
                ...newState.company,
                name: companyForm.name,
                businessForm: companyForm.businessForm,
            };
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateCompanyPerson, (state, { companyPerson }) =>
        produce(state, (newState) => {
            newState.company.contact = companyPerson;
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateCompanyIdentificationForm, (state, { companyIdentification }) =>
        produce(state, (newState) => {
            newState.company = {
                ...newState.company,
                ...companyIdentification,
            };
        })
    ),
    on(CompanyActions.updateCompanyComponentGroup.updateRecommendedBy, (state, { recommendedBy }) =>
        produce(state, (newState) => {
            newState.customer.recommendedBy = recommendedBy;
        })
    )
);
