import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { ModalService } from '@rza-mean/ui';

class ModalContainerComponent {}

@Injectable({
    providedIn: 'root',
})
export class ContactRequestService {
    contactRequestElementRef!: ElementRef;
    idProfession: string | undefined;
    private modalRef: { ref: ModalContainerComponent; destroy: () => void; close: EventEmitter<boolean> } | undefined;
    constructor(private modalService: ModalService) {}

    setIdProfession(idProfession: string | undefined) {
        this.idProfession = idProfession;
    }

    setElementRef(elementRef: ElementRef) {
        this.contactRequestElementRef = elementRef;
    }

    openContactRequestModal() {
        this.modalRef = this.modalService.open('Kontaktanfrage', this.contactRequestElementRef);
    }

    closeContactRequestModal() {
        this.modalRef?.destroy();
    }
}
