import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SmallPrintService {
    readonly smallPrints$: Observable<string[]>;
    private smallPrints: string[];
    private SmallPrints: BehaviorSubject<string[]>;

    constructor() {
        this.smallPrints = [];
        this.SmallPrints = new BehaviorSubject<Array<string>>([]);
        this.smallPrints$ = this.SmallPrints.asObservable();
    }

    public reset(): void {
        this.smallPrints = [];
        this.SmallPrints.next([]);
    }

    public register(smallPrints: string | string[]): string {
        if (typeof smallPrints === 'string') {
            smallPrints = [smallPrints];
        }
        return smallPrints.map((smallPrint) => this.add(smallPrint)).join(', ');
    }

    public getSmallPrints(): Observable<string[]> {
        return this.smallPrints$;
    }

    private add(smallPrint: string) {
        if (smallPrint) {
            const index = this.smallPrints.findIndex((print) => print === smallPrint);
            if (index === -1) {
                this.smallPrints.push(smallPrint);
                this.SmallPrints.next(this.smallPrints);
                return this.smallPrints.length.toString();
            } else {
                return (index + 1).toString();
            }
        }
        return '';
    }
}
