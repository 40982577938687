import { Component, HostBinding, OnDestroy } from '@angular/core';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { Product, ProfessionBranch } from '../../shared/interfaces';
import { Store } from '@ngrx/store';
import { setPrefinancing } from '../../store/configuration/configuration.actions';
import { selectConfiguredPrefinancing } from '../../store/configuration/configuration.selectors';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { SmallPrintService } from '@rza-mean/ui';
import { ProductService } from '../../services/product.service';
import { selectPrefinancing, selectSelectedProfession } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-prefinancing',
    templateUrl: './prefinancing.component.html',
    styleUrls: ['./prefinancing.component.scss'],
})
export class PrefinancingComponent implements OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    prefinancings$: Observable<Product[]>;
    professionBranch$: Observable<ProfessionBranch>;
    selectedPrefinancingId: string | undefined;
    private unsubcribe$ = new Subject<void>();

    constructor(
        private store: Store,
        private gtmService: GoogleTagManagerService,
        private smallPrintService: SmallPrintService,
        private productService: ProductService
    ) {
        this.prefinancings$ = this.store.select(selectPrefinancing).pipe(takeUntil(this.unsubcribe$));
        this.professionBranch$ = this.store.select(selectSelectedProfession).pipe(
            map((profession) => profession.professionBranch),
            takeUntil(this.unsubcribe$)
        );
        this.store
            .select(selectConfiguredPrefinancing)
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe((prefinancing) => (this.selectedPrefinancingId = prefinancing?._id));
    }

    ngOnDestroy(): void {
        this.smallPrintService.reset();
        this.unsubcribe$.next();
        this.unsubcribe$.complete();
    }

    selectPrefinancing($event: boolean, prefinancing: Product): void {
        if ($event && prefinancing._id !== this.selectedPrefinancingId) {
            this.selectedPrefinancingId = prefinancing._id;
            this.store.dispatch(setPrefinancing({ product: prefinancing }));
            this.gtmService.prefinancingSelected(prefinancing);
        }
    }

    getSmallPrints(smallPrints: string[] | undefined): string {
        if (smallPrints?.length) {
            return this.smallPrintService.register(smallPrints);
        }
        return '';
    }

    getPrices$(prefinancing: Product) {
        return this.productService.findPrices$(prefinancing);
    }
}
