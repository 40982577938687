import { Configuration } from '../../shared/interfaces';

export interface ConfigurationState {
    config: Configuration;
    loading: boolean;
    error: unknown;
}

export const initialConfigState: ConfigurationState = {
    config: {
        version: '',
    },
    loading: false,
    error: {},
};
