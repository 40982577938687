import { AfterViewInit, Component, ElementRef, forwardRef, Injector, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
    selector: 'rza-mean-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('error') errorWrapper: ElementRef<HTMLElement> | undefined;
    @Input() label = '';
    @Input() placeholder = 'Bitte wählen';
    @Input() options: Array<any> | null = [];
    @Input() keyToDisplay: string | undefined = undefined;
    @Input() selectId = 'select';

    @Input()
    indicateValidity = true;

    @Input()
    inputClasses: string | undefined;

    disabled = false;
    value = undefined;
    private control: FormControl | undefined;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: any = (val: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    constructor(private injector: Injector, private elementRef: ElementRef) {}

    ngAfterViewInit() {
        if (this.indicateValidity) {
            this.elementRef.nativeElement.classList.add('group');
            const ngControl = this.injector.get(NgControl, null);
            if (ngControl) {
                this.control = ngControl.control as FormControl;
            }
        }
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouch: any) {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
        }
    }

    change(value: any) {
        if (this.options) {
            const selectedOption = this.options.find((option: any) => {
                if (this.keyToDisplay === undefined) {
                    return option === value;
                } else {
                    return option[this.keyToDisplay] === value[this.keyToDisplay];
                }
            });
            this.onChange(selectedOption);
            this.onTouched();
        }
    }

    onBlur() {
        this.onTouched();
        if (this.indicateValidity && this.errorWrapper) {
            if (this.control?.touched && this.control.status === 'INVALID') {
                if (this.control.errors) {
                    const messages: string[] = [];
                    for (const errorKey in this.control.errors) {
                        if (errorKey === 'required') {
                            messages.push(`Das Feld '${this.label}' ist verpflichtend.`);
                        } else {
                            messages.push(this.control.errors[errorKey].value);
                        }
                    }
                    this.errorWrapper.nativeElement.innerHTML = messages.join('<br>');
                }
            }
            if (this.control?.errors == null) {
                this.errorWrapper.nativeElement.innerHTML = '';
            }
        }
    }
}
