import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, Observable } from 'rxjs';
import { Bundle, MinimalProfession, Product, Profession, ProfessionBranch, Promotion } from '../shared/interfaces';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { addObligatoryProduct } from '../store/configuration/configuration.actions';

@Injectable({
    providedIn: 'root',
})
export class ProfessionService {
    private readonly professionsApiUrL: string;
    private readonly professionBranchesApiUrL: string;
    private renderer: Renderer2;

    constructor(private httpClient: HttpClient, private rendererFactory: RendererFactory2, private store: Store) {
        this.professionsApiUrL = `${environment.apiUrl}/professions`;
        this.professionBranchesApiUrL = `${environment.apiUrl}/profession-branches`;
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    getProfessionBranches(): Observable<ProfessionBranch[]> {
        return this.httpClient.get<ProfessionBranch[]>(this.professionBranchesApiUrL).pipe(filter((_) => _ != null));
    }

    getMinimalProfessions(): Observable<MinimalProfession[]> {
        return this.httpClient.get<MinimalProfession[]>(this.professionsApiUrL + '/minimal').pipe(filter((_) => _ != null));
    }

    getProfession(id: string): Observable<Profession> {
        return this.httpClient.get<Profession>(`${this.professionsApiUrL}/${id}`).pipe(filter((_) => _ != null));
    }

    getStyledPromotionTitle(promotion?: Promotion): string {
        if (promotion?.title) {
            const parser = new DOMParser();
            const html = parser.parseFromString(promotion.title, 'text/html');
            const spans = html.getElementsByTagName('span');
            if (spans.length > 1) {
                if (promotion.color && promotion.backgroundColor) {
                    spans[0].style.color = promotion.color;
                    spans[1].style.color = promotion.color;
                } else {
                    spans.item(0)?.setAttribute('class', 'text-secondary');
                    spans.item(1)?.setAttribute('class', 'text-primary');
                }
                return html.getElementsByTagName('body')?.item(0)?.innerHTML ?? '';
            }
            const span = this.renderer.createElement('span');
            this.renderer.addClass(span, 'text-primary');
            this.renderer.appendChild(span, this.renderer.createText(promotion.title));
            return span.outerHTML;
        }
        return '';
    }

    addObligatoryProducts(products: Product[]) {
        products.forEach((product) => {
            this.store.dispatch(addObligatoryProduct({ product }));
        });
    }
}
