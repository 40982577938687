import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'rza-mean-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
})
export class PageComponent {
    @HostBinding('class') classes = 'flex flex-col flex-grow bg-white';
    @Input() showBreadcrumbs = true;
    @Input() hideBreadcrumbs = false;
    @Input() center = false;
}
