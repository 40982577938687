import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'rza-mean-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true,
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor {
    @Input() acceptedFileFormats = 'application/pdf, image/*';
    @Input() label: string | undefined;
    @Input() inputId = 'fileUpload';

    disabled = false;
    value: File | undefined;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: any = (val: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    writeValue(value: File) {
        if (value) {
            this.value = value;
        }
    }

    registerOnTouched(onTouch: any) {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    change($event: Event) {
        const filesArray = ($event.target as HTMLInputElement).files;
        if (filesArray != null) {
            this.onChange(filesArray[0]);
        }
    }
}
