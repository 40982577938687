import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { debounceTime, fromEvent, merge, Subject, takeUntil } from 'rxjs';

@Directive({
    selector: '[rzaMeanTypeahead]',
    exportAs: 'rzaMeanTypeahead',
})
export class TypeaheadDirective implements OnDestroy {
    @Input() options?: string[];
    @Output() filtered = new EventEmitter<string[]>();
    private unsubscribe$ = new Subject<void>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        merge(
            fromEvent(this.elementRef.nativeElement, 'input'),
            fromEvent(this.elementRef.nativeElement, 'change'),
            fromEvent(this.elementRef.nativeElement, 'focusin')
        )
            .pipe(debounceTime(200), takeUntil(this.unsubscribe$))
            .subscribe((event) => {
                const searchString: string = (event as any).target?.value.toLowerCase().trim();
                if (searchString.length) {
                    const regex = new RegExp(searchString.split(' ').join('.*'), 'i');
                    this.filtered.emit(this.options?.filter((o) => regex.test(o.toLowerCase())) || []);
                } else {
                    this.filtered.emit(this.options);
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
