import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rza-mean-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
