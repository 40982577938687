<rza-mean-card
    rzaMeanSelectable
    [selectionStyleClasses]="getSelectionClass(product)"
    [bodyClasses]="'bg-' + getProductColor(product) + ' text-white'"
    [headerClasses]="'bg-white items-center text-' + getProductColor(product) + ' text-center !text-xl font-bold'"
    [isSelected]="isSelected || product.isObligatory || false"
    (selected)="toggleSelection($event)"
    [isObligatory]="product.isObligatory || false"
    [headerText]="product.name"
    [hasBorder]="true"
    [color]="getProductColor(product)"
    selectionType="checkbox"
    [checkedClasses]="'!text-' + getProductColor(product)"
    [uncheckedClasses]="'!text-' + getProductColor(product)"
>
    <ng-template #body>
        <div class="text-lg">
            <rza-mean-revenue-form [product]="product" [disableInputs]="!isSelected"></rza-mean-revenue-form>

            <ng-container *ngIf="prices$ as prices">
                <div *ngFor="let price of prices | async" class="mt-4">
                    <div class="flex">
                        <div class="mr-2">{{ price?.name }}:</div>
                        <div class="font-semibold text-white">
                            {{ price | price }}
                            <sup *ngIf="price?.smallPrint?.length">{{ smallprintService.register(price?.smallPrint || []) }}</sup>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>

    <ng-container *ngIf="product.modalContent != null">
        <ng-template #info>
            <div rzaMeanListBullet [classes]="['text-primary']" [innerHTML]="product.modalContent.content"></div>
        </ng-template>
    </ng-container>
</rza-mean-card>
