import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, map, mergeMap, of, take, tap } from 'rxjs';
import { BundleActions, selectProfession } from '../store/configuration/configuration.actions';
import { Store } from '@ngrx/store';
import { SessionService } from './session.service';
import { DiscountService } from './discount.service';
import { selectMinimalProfessionsByBranch, selectPromotedBundles } from '../store/profession/profession.selectors';
import { setSelectedProfessionBranch } from '../store/profession/profession.actions';

@Injectable({
    providedIn: 'root',
})
export class PreSelectResolver {
    constructor(
        private store: Store,
        private router: Router,
        private sessionService: SessionService,
        private discountService: DiscountService
    ) {}

    private professionFound!: boolean;
    private bundleFound!: boolean;

    resolve(route: ActivatedRouteSnapshot): boolean {
        this.professionFound = false;
        this.bundleFound = false;
        const professionIdentifier = route.queryParams['profession'];
        const bundleName = route.queryParams['paket'];
        const code = route.queryParams['code'];
        if (bundleName && professionIdentifier) {
            this.selectProfession(professionIdentifier)
                .pipe(mergeMap(() => this.selectBundle(bundleName)))
                .subscribe(() => {
                    if (this.professionFound && this.bundleFound) {
                        this.router.navigate(['umsatz']);
                    }
                });
        } else if (professionIdentifier) {
            this.selectProfession(professionIdentifier).subscribe();
        }
        if (code) {
            this.selectDiscountCode(code).subscribe();
        }
        return true;
    }

    private selectBundle(bundleName: string) {
        return this.store.select(selectPromotedBundles).pipe(
            take(2),
            tap((promotedBundles) => {
                if (promotedBundles?.bundles.length) {
                    const bundle = promotedBundles.bundles.find((bundle) => bundle.name?.toLowerCase() === bundleName.toLowerCase());
                    if (bundle) {
                        this.bundleFound = true;
                        this.store.dispatch(BundleActions.setBundle({ bundle }));
                    }
                }
            })
        );
    }

    private selectProfession(professionIdentifier: string) {
        return this.store.select(selectMinimalProfessionsByBranch(undefined)).pipe(
            filter((minimalProfession) => minimalProfession.length > 0),
            map((minimalProfessions) => {
                const foundMinimalProfession = minimalProfessions.find(
                    (minimalProfession) => minimalProfession.identifier?.toLowerCase() === professionIdentifier.toLowerCase()
                );
                if (foundMinimalProfession) {
                    this.store.dispatch(setSelectedProfessionBranch({ branch: foundMinimalProfession.professionBranch.name }));
                }
                return foundMinimalProfession?._id;
            }),
            mergeMap((professionId) => {
                if (professionId) {
                    return this.sessionService.init(professionId).pipe(map(() => professionId));
                }
                return of(null);
            }),
            tap((professionId) => {
                if (professionId) {
                    this.professionFound = true;
                    this.store.dispatch(selectProfession({ _id: professionId }));
                }
            }),
            take(1)
        );
    }

    private selectDiscountCode(code: string) {
        return this.discountService.loadDiscountCode(code);
    }
}
