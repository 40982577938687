import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigurationState } from './configuration.state';
import { Configuration, Product } from '../../shared/interfaces';
import { ProductCategoryEnum, ProductTypeEnum } from '@rza-mean/api-interfaces';

const configState = createFeatureSelector<ConfigurationState>('config');

export const selectConfig = createSelector(configState, (state: ConfigurationState) => state.config);

export const selectConfigLoadingStatus = createSelector(configState, (state: ConfigurationState) => state.loading);

export const selectConfigErrorStatus = createSelector(configState, (state: ConfigurationState) => state.error);

export const selectConfigVersion = createSelector(selectConfig, (state: Configuration) => state.version);

export const selectConfigCode = createSelector(selectConfig, (state: Configuration) => state.code);

export const selectConfiguredIdProfession = createSelector(selectConfig, (state: Configuration) => state.configuredProfession?.profession);

export const selectAllConfiguredProducts = createSelector(
    selectConfig,
    (state: Configuration) => state.configuredProfession?.products ?? []
);

export const selectConfiguredPrefinancing = createSelector(selectAllConfiguredProducts, (state: Product[]) =>
    state.find((prod) => prod.type === ProductTypeEnum.PREFINANCING)
);

export const selectConfiguredProduct = (id: string) =>
    createSelector(selectAllConfiguredProducts, (state: Product[]) => state?.find((prod) => prod._id === id));

export const selectIsProductInConfig = (id: string) =>
    createSelector(selectAllConfiguredProducts, (state: Product[]) => state?.find((prod) => prod._id === id) != null);

export const selectIsSubProductInConfig = (id: string, subId: string) =>
    createSelector(
        selectAllConfiguredProducts,
        (state: Product[]) => state?.find((prod) => prod._id === id)?.products?.find((subProd) => subProd._id === subId) != null
    );

export const selectAllConfiguredSettlementProducts = createSelector(selectAllConfiguredProducts, (state: Product[]) =>
    state?.filter((prod) => prod.type === ProductTypeEnum.SETTLEMENT)
);

export const selectAllConfiguredNonSettlementProducts = createSelector(selectAllConfiguredProducts, (state: Product[]) =>
    state.filter((prod) => prod?.type !== ProductTypeEnum.SETTLEMENT)
);

export const selectAllConfiguredServiceProducts = createSelector(selectAllConfiguredProducts, (state: Product[]) =>
    state?.filter((prod) => prod.type === ProductTypeEnum.SERVICE || prod.type === ProductTypeEnum.BASIC)
);

export const selectAllConfiguredSoftwareProducts = createSelector(selectAllConfiguredProducts, (state: Product[]) =>
    state?.filter((prod) => prod.type === ProductTypeEnum.SOFTWARE)
);

export const selectConfiguredBundleId = createSelector(selectConfig, (state: Configuration) => state.configuredProfession?.bundle);

export const selectConfiguredDiscount = (id: string) =>
    createSelector(selectConfig, (state: Configuration) => state.configuredProfession?.discounts?.find((discount) => discount._id === id));

export const selectAllConfiguredDiscounts = createSelector(selectConfig, (state: Configuration) => state.configuredProfession?.discounts);

export const selectAllProductsByCategory = (category: string | string[]) =>
    createSelector(selectConfig, (state: Configuration) => {
        if (Array.isArray(category)) {
            return state.configuredProfession?.products.filter((product) => category.includes(product.category as string));
        }
        return state.configuredProfession?.products.filter((product) => product.category === category);
    });

export const selectGKVProduct = createSelector(selectAllConfiguredSettlementProducts, (state: Product[]) =>
    state.find((product) => product.category === ProductCategoryEnum.GKV)
);

export const selectIsStartUpConfirmed = createSelector(selectConfig, (state: Configuration) => state.isStartUpConfirmed ?? false);
