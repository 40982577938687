import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Price, Product } from '../../shared/interfaces';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ConfigurationState } from '../../store/configuration/configuration.state';
import { Store } from '@ngrx/store';
import { selectIsProductInConfig } from '../../store/configuration/configuration.selectors';
import { SettlementProductActions } from '../../store/configuration/configuration.actions';
import { SmallPrintService } from '@rza-mean/ui';
import { ProductService } from '../../services/product.service';
import { getProductColor, getSelectionClass } from '../../shared/utils';

@Component({
    selector: 'rza-mean-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit, OnDestroy {
    @Input() product!: Product;
    isSelected: boolean;
    prices$: Observable<(Price | undefined)[]> | undefined;

    private unsubscribe$ = new Subject();

    constructor(
        private store: Store<ConfigurationState>,
        public smallprintService: SmallPrintService,
        private productService: ProductService
    ) {
        this.isSelected = false;
    }

    ngOnInit(): void {
        this.store
            .select(selectIsProductInConfig(this.product._id))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isSelected) => {
                this.isSelected = this.product.isObligatory || isSelected || false;
            });

        this.prices$ = this.productService.findPrices$(this.product);
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    toggleSelection($event: boolean) {
        if (!this.product.isObligatory && this.isSelected !== $event) {
            this.isSelected = $event;
            if (this.isSelected) {
                this.store.dispatch(SettlementProductActions.addSettlementProduct({ product: this.product }));
            } else {
                this.store.dispatch(SettlementProductActions.removeSettlementProduct({ _id: this.product._id }));
            }
        }
    }

    protected readonly getSelectionClass = getSelectionClass;
    protected readonly getProductColor = getProductColor;
}
