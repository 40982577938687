import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { BusinessFormsStoreService } from '../../../services/business-forms-store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyForm } from '../../../shared/interfaces';
import { Store } from '@ngrx/store';
import { selectCompany } from '../../../store/company/company.selectors';
import { environment } from '../../../../environments/environment';
import { selectDefaultBusinessForm } from '../../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyFormComponent implements OnInit, OnDestroy {
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() updateCompany: EventEmitter<CompanyForm> = new EventEmitter<CompanyForm>();

    companyForm: FormGroup;
    businessForms$: Observable<
        {
            _id: string;
            name: string;
        }[]
    >;
    businessForms: {
        _id: string;
        name: string;
    }[] = [];

    private unsubscribe$ = new Subject();

    constructor(private fb: FormBuilder, private businessFormsStore: BusinessFormsStoreService, private store: Store) {
        this.companyForm = this.fb.group(
            {
                name: [environment.companyData?.name ?? '', Validators.required],
                businessForm: [{}, Validators.required],
            },
            { updateOn: 'blur' }
        );
        this.businessForms$ = this.businessFormsStore.state$.pipe(
            filter((bf) => bf.length > 0),
            // takeUntil(this.unsubscribe$),
            map((bf) =>
                bf.map((businessForm) => {
                    return {
                        _id: businessForm._id,
                        name: businessForm.name,
                    };
                })
            )
        );
    }

    ngOnInit(): void {
        combineLatest([
            this.store.select(selectCompany).pipe(filter((company) => company != null)),
            this.businessForms$,
            this.store.select(selectDefaultBusinessForm),
        ])
            .pipe(
                map(([company, businessForms, defaultBusinessForm]) => ({
                    company,
                    businessForms,
                    defaultBusinessForm,
                })),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(({ company, businessForms, defaultBusinessForm }) => {
                this.businessForms = businessForms;
                if (company.businessForm != null) {
                    const selectedBusinessForm = this.businessForms.find((form) => form._id === company.businessForm._id);
                    this.companyForm.controls['businessForm'].setValue(selectedBusinessForm, { emitEvent: false });
                }
                if (this.companyForm.controls['businessForm'].value == null) {
                    this.companyForm.controls['businessForm'].setValue(
                        this.businessForms.find((bf) => bf._id === defaultBusinessForm?._id) || this.businessForms[0]
                    );
                }
                this.companyForm.controls['name'].setValue(company.name, { emitEvent: false });
                this.isValid.emit(this.companyForm.valid);
            });
        this.companyForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
            this.isValid.emit(this.companyForm.valid);
            this.updateCompany.emit(form);
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
