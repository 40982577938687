<main class="my-8 flex flex-grow flex-col">
    <div class="container grid flex-grow grid-cols-12" [ngClass]="{ 'items-center': center }">
        <div class="col-span-12 flex flex-grow flex-col lg:col-span-9 2xl:col-span-10">
            <ng-content></ng-content>
        </div>
        <div class="fixed right-8 top-24 bottom-24">
            <rza-mean-breadcrumbs
                class="print:hidden"
                [ngClass]="{ invisible: hideBreadcrumbs }"
                *ngIf="showBreadcrumbs"
            ></rza-mean-breadcrumbs>
        </div>
    </div>
</main>
