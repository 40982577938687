<div rzaMeanDropdown [position]="position">
    <rza-mean-input
        class="cursor-pointer"
        rzaMeanDropdownToggle
        [readonly]="true"
        type="text"
        [label]="label"
        [size]="size"
        [ngModel]="dateString"
        textAppend="calendar"
    ></rza-mean-input>
    <div rzaMeanDropdownMenu class="border bg-white">
        <div class="border-b bg-gray-100 p-3 pb-0">
            <div class="flex">
                <div class="relative mr-3 mb-3 basis-1/2">
                    <select
                        name="month"
                        id="month"
                        [ngModel]="selectedDate.month"
                        (ngModelChange)="updateSelectedDate({ month: $event })"
                        class="focus:border-success inline-block w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-3 py-2.5 text-sm text-gray-900 focus:outline-none focus:ring-0 disabled:bg-gray-200 disabled:opacity-100"
                    >
                        <option
                            *ngFor="let monthName of monthNames; let index = index"
                            [ngValue]="index"
                            [disabled]="isMonthDisabled(index)"
                        >
                            {{ monthName }}
                        </option>
                    </select>
                </div>
                <div class="basis-1/2">
                    <rza-mean-input
                        type="number"
                        [ngModel]="selectedDate.year"
                        size="sm"
                        [min]="min?.getFullYear() || ''"
                        [max]="max?.getFullYear() || ''"
                        (input)="onYearInput($event)"
                    ></rza-mean-input>
                </div>
            </div>
            <div class="text-center text-lg">
                {{ monthNames[selectedDate.month] + ' ' + selectedDate.year }}
            </div>
            <div class="grid grid-cols-7">
                <div *ngFor="let day of dayNames" class="p-1 text-center italic">{{ day }}</div>
            </div>
        </div>
        <div class="grid grid-cols-7 p-3 pt-0">
            <div *ngFor="let day of daysBeforeFirst(selectedDate.month, selectedDate.year)" class="p-1">&nbsp;</div>
            <div
                *ngFor="let day of daysInMonth(selectedDate.month, selectedDate.year)"
                (click)="updateSelectedDate({day})"
                class="p-1 text-center"
                [ngClass]="{
                    'bg-gray-300': isToday(day),
                    '!bg-primary text-white': isSelected(day),
                    'cursor-pointer hover:bg-gray-300': isDateInRange(day, selectedDate.month, selectedDate.year),
                    'text-muted italic': !isDateInRange(day, selectedDate.month, selectedDate.year)
                }"
            >
                {{ day }}
            </div>
        </div>
    </div>
</div>
