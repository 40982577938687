import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import {
    selectAllConfiguredDiscounts,
    selectAllConfiguredNonSettlementProducts,
    selectAllConfiguredProducts,
    selectAllConfiguredServiceProducts,
    selectAllConfiguredSettlementProducts,
    selectConfiguredPrefinancing,
    selectGKVProduct,
} from '../store/configuration/configuration.selectors';
import { Discount, Price, Product } from '../shared/interfaces';
import {
    CalculationMethodEnum,
    FunctionEnum,
    PriceUnitEnum,
    ProductCategoryEnum,
    ProductTypeEnum,
    RevenueTypeEnum,
    StaggeredPriceReferenceEnum,
} from '@rza-mean/api-interfaces';
import { handleFunctionalCalculation } from '@rza-mean/calculation';

export type TotalPricePerPriceTypeAndSettlement = {
    percent: Record<'GKV' | 'Privat' | 'Zuzahlung', number>;
    absolute: number;
};

export type TotalPricePerPriceType = { absolute: number; percent: number };

@Injectable({
    providedIn: 'root',
})
export class CalculationService {
    private discountsByProductId$: Observable<Record<string, Discount[]> | undefined>;

    constructor(private store: Store) {
        this.discountsByProductId$ = this.store.select(selectAllConfiguredDiscounts).pipe(
            map((discounts) => {
                return discounts?.reduce((acc, discount) => {
                    discount.discountedProducts.forEach((id) => {
                        if (id in acc) {
                            acc[id].push(discount);
                        } else {
                            acc[id] = [discount];
                        }
                    });
                    return acc;
                }, {} as Record<string, Discount[]>);
            })
        );
    }

    calculatePrice$(): Observable<{ price: number; discount: number }> {
        return combineLatest([
            this.getAllSettlementProductPrices(),
            this.getPrefinancingPrice(),
            this.getServicePrices(),
            this.getDiscountPrices(),
        ]).pipe(
            map(([settlements, prefinancing, services, discounts]) => ({
                settlements,
                prefinancing,
                services,
                discounts,
            })),
            map(({ settlements, prefinancing, services, discounts }) => {
                return {
                    price: +(settlements + prefinancing + services).toFixed(2),
                    discount: +discounts.toFixed(2),
                };
            })
        );
    }

    getTotalPricePerPriceType(): Observable<TotalPricePerPriceType> {
        return combineLatest([
            this.store.select(selectAllConfiguredSettlementProducts),
            this.store.select(selectAllConfiguredNonSettlementProducts),
            this.discountsByProductId$,
        ]).pipe(
            map(([settlements, nonSettlements, discountsByProductId]) => {
                const products: Product[] = [];
                settlements
                    .filter((settlement) => !settlement.forRevenueOnly)
                    .forEach((settlement) => {
                        const samePriceAlreadyInProducts = products.find((prod) => {
                            if (prod.prices && settlement.prices) {
                                return prod.prices[0].unit === settlement.prices[0].unit;
                            }
                            return false;
                        });
                        if (!samePriceAlreadyInProducts) {
                            products.push(settlement);
                        }
                    });
                products.push(...nonSettlements);

                const prices: Price[] = [];
                // undiscounted products
                products
                    .filter((product) => (discountsByProductId ? !Object.keys(discountsByProductId).includes(product._id) : true))
                    .forEach((product) => {
                        let localPrices: Price[] = [];
                        if (product.products?.length) {
                            localPrices = product.products
                                .flatMap((prod) => this.getPrices(prod))
                                .filter((price) => price != null) as Price[];
                        } else {
                            const prices = this.getPrices(product);
                            if (prices) {
                                localPrices = prices;
                            }
                        }
                        if (localPrices.length) {
                            prices.push(...localPrices);
                        }
                    });

                // discounted products
                if (discountsByProductId && Object.keys(discountsByProductId).length) {
                    for (const [productId, discounts] of Object.entries(discountsByProductId)) {
                        const product = products.find((p) => p._id === productId);
                        if (product) {
                            const initialPrices = this.getPrices(product);
                            if (initialPrices && initialPrices.length) {
                                const discountedPrices = initialPrices.map((initPrice) =>
                                    discounts.reduce(
                                        (price, discount) => {
                                            if (
                                                discount.calculationMethod === CalculationMethodEnum.ABSOLUTE &&
                                                discount.price &&
                                                discount.price.unit === price.unit
                                            ) {
                                                price.value += discount.price.value;
                                            } else if (
                                                discount.calculationMethod === CalculationMethodEnum.RELATIVE &&
                                                discount.price &&
                                                discount.price.unit === PriceUnitEnum.PERCENT
                                            ) {
                                                price.value += Number((price.value * discount.price.value) / 100);
                                            }
                                            return price;
                                        },
                                        { ...initPrice }
                                    )
                                );
                                prices.push(...discountedPrices);
                            }
                        }
                    }
                }

                return prices.reduce(
                    (acc, price) => {
                        if (price.unit === PriceUnitEnum.EURO) {
                            acc.absolute += price.value;
                        } else {
                            acc.percent += price.value;
                        }
                        return acc;
                    },
                    {
                        percent: 0,
                        absolute: 0,
                    }
                );
            })
        );
    }

    getTotalRevenue(): Observable<number> {
        return this.store.select(selectAllConfiguredSettlementProducts).pipe(
            map((prods: Product[]) => {
                if (prods && prods?.length) {
                    return prods
                        .map((prod) => {
                            if (prod) {
                                return this.calculateRevenueForSettlementProduct(prod);
                            }
                            return 0;
                        })
                        .reduce((acc, curr) => acc + curr, 0);
                }
                return 0;
            })
        );
    }

    getAllSettlementProductPrices(): Observable<number> {
        return this.store.select(selectAllConfiguredSettlementProducts).pipe(
            map((prods: Product[]) => {
                if (prods && prods?.length) {
                    return Number(
                        prods
                            .map((prod) => {
                                if (prod) {
                                    return this.getSettlementProductPrice(prod).price;
                                }
                                return 0;
                            })
                            .reduce((acc, curr) => acc + curr, 0)
                    );
                } else {
                    return 0;
                }
            })
        );
    }

    getPrefinancingPrice(): Observable<number> {
        return combineLatest([this.store.select(selectConfiguredPrefinancing), this.getTotalRevenue()]).pipe(
            map(([prefinancing, revenue]) => ({
                prefinancing,
                revenue,
            })),
            switchMap(({ prefinancing, revenue }) => {
                if (prefinancing?.prices?.length) {
                    const price = this.getPrices(prefinancing)?.at(0);
                    if (price) {
                        if (price.unit === PriceUnitEnum.PERCENT) {
                            return of(Number((revenue * prefinancing.prices[0].value) / 100));
                        } else {
                            return of(Number(price.value));
                        }
                    }
                }
                return of(0);
            })
        );
    }

    getServicePrices(): Observable<number> {
        return combineLatest([this.store.select(selectAllConfiguredServiceProducts), this.getTotalRevenue()]).pipe(
            map(([services, revenue]) => ({
                services,
                revenue,
            })),
            map(({ services, revenue }) => {
                if (services != null && services.length > 0) {
                    return services
                        .map((service) => {
                            return this.calculatePriceForService(service, revenue);
                        })
                        .reduce((acc, curr) => curr + acc, 0);
                }
                return 0;
            })
        );
    }

    getDiscountPrices(): Observable<number> {
        return combineLatest([this.store.select(selectAllConfiguredProducts), this.getTotalRevenue(), this.discountsByProductId$]).pipe(
            map(([products, totalRevenue, discountsByProductId]) => {
                let discountPrice = 0;
                if (discountsByProductId) {
                    let settlementProductPrice: { price: number; isMinPrice: boolean };
                    for (const [productId, discounts] of Object.entries(discountsByProductId)) {
                        const product = products.find((p) => p._id === productId);
                        if (product) {
                            let initialCost: number | undefined;
                            switch (product.type) {
                                case ProductTypeEnum.SETTLEMENT:
                                    settlementProductPrice = this.getSettlementProductPrice(product);
                                    initialCost = Number(settlementProductPrice.price);
                                    break;
                                case ProductTypeEnum.PREFINANCING:
                                    if (product.prices?.length) {
                                        initialCost = Number((totalRevenue * product.prices[0].value) / 100);
                                    }
                                    break;
                                case ProductTypeEnum.SERVICE:
                                    initialCost = Number(this.calculatePriceForService(product, totalRevenue));
                                    break;
                                case ProductTypeEnum.BASIC:
                                    initialCost = Number(this.calculatePriceForService(product, totalRevenue));
                            }
                            if (initialCost !== undefined) {
                                const remainingCost = discounts.reduce((cost, discount) => {
                                    if (discount.price && discount.calculationMethod === CalculationMethodEnum.ABSOLUTE) {
                                        if (discount.price.unit === PriceUnitEnum.EURO) {
                                            cost += discount.price.value;
                                        } else {
                                            switch (product.type) {
                                                case ProductTypeEnum.SETTLEMENT:
                                                    cost += Number(
                                                        this.getSettlementProductPrice({
                                                            ...product,
                                                            prices: [discount.price || ({} as Price)],
                                                        }).price
                                                    );
                                                    break;
                                                case ProductTypeEnum.PREFINANCING:
                                                    cost += Number(((discount.price?.value || 0) * totalRevenue) / 100);
                                                    break;
                                                case ProductTypeEnum.BASIC:
                                                    cost += Number(((discount.price?.value || 0) * totalRevenue) / 100);
                                                    break;
                                            }
                                        }
                                    } else if (
                                        discount.calculationMethod === CalculationMethodEnum.RELATIVE &&
                                        discount.price &&
                                        discount.price.unit === PriceUnitEnum.PERCENT
                                    ) {
                                        if (!(product.type === ProductTypeEnum.SETTLEMENT && settlementProductPrice.isMinPrice)) {
                                            cost += Number((discount.price.value * cost) / 100);
                                        }
                                    }
                                    return cost;
                                }, initialCost);
                                discountPrice += remainingCost - initialCost;
                            }
                        }
                    }
                }
                return discountPrice;
            })
        );
    }

    getSettlementProductPrice(product: Product): { price: number; isMinPrice: boolean } {
        let price = 0;
        let revenue = 0;
        let revenuePerReceipt = 0;
        let receiptCount = 0;
        let isMinPrice = false;
        if (product.revenue) {
            if (product.revenue?.type === RevenueTypeEnum.RECEIPT) {
                revenue = this.calculateRevenueForSettlementProduct(product);
                if (product.revenue.itemValue && product.revenue.count) {
                    revenuePerReceipt = product.revenue.itemValue;
                    receiptCount = product.revenue.count;
                }
            } else if (product.revenue?.type === RevenueTypeEnum.PATIENT && product.revenue.value) {
                revenue = product.revenue.value;
                if (product.revenue.count) {
                    receiptCount = product.revenue.count;
                    revenuePerReceipt = revenue / product.revenue.count;
                    if (product.revenue.factor) {
                        receiptCount *= product.revenue.factor;
                        revenuePerReceipt = revenue / receiptCount;
                    }
                }
            } else if (product.revenue?.type === RevenueTypeEnum.RECEIPT_REVENUE && product.revenue.value) {
                revenue = product.revenue.value;
                if (product.revenue.count) {
                    receiptCount = product.revenue.count;
                    revenuePerReceipt = revenue / receiptCount;
                }
            }
        }
        if (product.category === ProductCategoryEnum.ADDITIONAL_PAYMENT && product.revenue && product.revenue.count) {
            if (product.revenue?.type === RevenueTypeEnum.RECEIPT) {
                receiptCount = product.revenue.count;
            } else if (product.revenue?.type === RevenueTypeEnum.PATIENT) {
                receiptCount = product.revenue.count;
                if (product.revenue.factor) {
                    receiptCount *= product.revenue.factor;
                }
            }
        }
        if (product.prices?.length) {
            price = product.prices
                .map((price) => {
                    if (price) {
                        const settlementPrice = this.calculatePriceForSettlementProduct(
                            product,
                            price,
                            revenue,
                            revenuePerReceipt,
                            receiptCount
                        );
                        if (!isMinPrice) {
                            isMinPrice = settlementPrice.isMinPrice;
                        }
                        return settlementPrice.sum;
                    }
                    return 0;
                })
                .reduce((acc, curr) => curr + acc, 0);
        }
        return {
            price,
            isMinPrice,
        };
    }

    calculateRevenueForSettlementProduct(product: Product): number {
        if (product) {
            if (product.revenue?.type === RevenueTypeEnum.RECEIPT && product.revenue.count) {
                return product.revenue.itemValue != null ? product.revenue.count * product.revenue.itemValue : 0;
            } else if (product.revenue?.type === RevenueTypeEnum.PATIENT || product.revenue?.type === RevenueTypeEnum.RECEIPT_REVENUE) {
                return product.revenue.value || 0;
            }
        }
        return 0;
    }

    calculatePriceForSettlementProduct(
        product: Product,
        price: Price,
        revenue: number,
        revenuePerReceipt: number,
        receiptCount: number
    ): { sum: number; isMinPrice: boolean } {
        let sum = 0;
        let isMinPrice = false;
        if (price.unit === PriceUnitEnum.PERCENT) {
            if (price.minPricePerUnit != null && Object.keys(price.minPricePerUnit).length > 0) {
                isMinPrice = revenuePerReceipt <= price.minPricePerUnit.value;
                sum += isMinPrice ? receiptCount * price.minPricePerUnit.value : (revenue * price.value) / 100;
            } else {
                sum += (revenue * price.value) / 100;
            }
            if (price.minPrice != null && Object.keys(price.minPrice).length > 0) {
                sum = sum <= price.minPrice.value ? price.minPrice.value : sum;
                if (!isMinPrice) {
                    isMinPrice = sum <= price.minPrice.value;
                }
            }
        } else {
            if (product.category === ProductCategoryEnum.ADDITIONAL_PAYMENT) {
                sum += price.value * receiptCount;
            } else {
                sum += price.value;
            }
        }
        return {
            sum,
            isMinPrice,
        };
    }

    calculatePriceForService(service: Product, revenue: number): number {
        if (service.products != null && service.products.length > 0) {
            return service.products
                .flatMap((serviceItem) => {
                    if (serviceItem.prices) {
                        return serviceItem.prices.map((price) => {
                            if (price.billingType.name !== 'once') {
                                if (price.unit === PriceUnitEnum.PERCENT) {
                                    return (price.value * revenue) / 100;
                                } else {
                                    return price.value;
                                }
                            }
                            return 0;
                        });
                    }
                    return 0;
                })
                .reduce((prev, curr) => prev + curr, 0);
        } else {
            if (service.prices) {
                return service.prices
                    .map((price) => {
                        if (price.billingType.name !== 'once') {
                            if (price.unit === PriceUnitEnum.PERCENT) {
                                return (price.value * revenue) / 100;
                            } else {
                                return price.value;
                            }
                        }
                        return 0;
                    })
                    .reduce((prev, curr) => prev + curr, 0);
            }
            return 0;
        }
    }

    getValueFromFunctionalPrice(configuredProduct: Product, price: Price): number {
        let value = price.value;
        const functionalPrice = price.functionalPrice;
        if (
            configuredProduct.revenue &&
            functionalPrice?.functionName === FunctionEnum.TRARET_GKV &&
            functionalPrice.parameters &&
            functionalPrice.userInput
        ) {
            const userInput = {};
            functionalPrice.userInput?.forEach((input) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                userInput[input] = configuredProduct.revenue[input];
            });
            value = handleFunctionalCalculation(functionalPrice.functionName, functionalPrice.parameters, userInput);
        }
        return value;
    }

    findStaggeredPrice$(product: Product) {
        if (product.staggeredPrices?.length) {
            if (product.staggeredPrices[0].reference === StaggeredPriceReferenceEnum.GKV_REVENUE) {
                return this.store.select(selectGKVProduct).pipe(
                    map((gkv) => {
                        if (gkv) {
                            const revenue = this.calculateRevenueForSettlementProduct(gkv);
                            return product.staggeredPrices?.find(
                                (staggered) => staggered.lowerThreshold <= revenue && (staggered.upperThreshold ?? Infinity) >= revenue
                            )?.price;
                        }
                        return undefined;
                    })
                );
            }
            if (product.staggeredPrices[0].reference === StaggeredPriceReferenceEnum.GKV_RECEIPT_COUNT) {
                return this.store.select(selectGKVProduct).pipe(
                    map((gkv) => {
                        if (gkv && gkv.revenue?.count) {
                            const count = gkv.revenue.count;
                            return product.staggeredPrices?.find(
                                (staggered) => staggered.lowerThreshold <= count && (staggered.upperThreshold ?? Infinity) >= count
                            )?.price;
                        }
                        return undefined;
                    })
                );
            }
        }
        return of(undefined);
    }

    private getPrices(product: Product): Price[] | undefined {
        if (product.products && product.products.length > 0 && product.products[0].prices?.length) {
            return product.products[0].prices;
        } else if (product.prices?.length) {
            if (
                product.revenue?.count &&
                product.prices[0].unit === PriceUnitEnum.EURO &&
                product.category === ProductCategoryEnum.ADDITIONAL_PAYMENT
            ) {
                return [
                    {
                        ...product.prices[0],
                        value: product.prices[0].value * product.revenue.count,
                    },
                ];
            }
            return product.prices;
        }
        return undefined;
    }
}
