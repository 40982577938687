import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { MinimalProfession, Profession } from '../../shared/interfaces';

export const MinimalProfessionActions = createActionGroup({
    source: 'Minimal Professions',
    events: {
        Load: emptyProps(),
        'Load Success': props<{ minimalProfessions: MinimalProfession[] }>(),
        'Load Failure': props<{ error: unknown }>(),
    },
});

export const ProfessionActions = createActionGroup({
    source: 'Profession',
    events: {
        Load: props<{ id: string }>(),
        'Load Success': props<{ profession: Profession }>(),
        'Load Failure': props<{ error: unknown }>(),
    },
});
export const setSelectedProfessionBranch = createAction('[Start Page] Set Profession Branch', props<{ branch: string | undefined }>());
