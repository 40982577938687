<rza-mean-page [hideBreadcrumbs]="(selectedProfession$ | async)?.status?.toString() === professionStatusEnum.CONTACT.valueOf()">
    <rza-mean-section>
        <rza-mean-title
            headline="Konfigurieren Sie jetzt Ihr<br>Dienst&shy;leistungs&shy;paket!"
            subHeadline="<b>Vorfinanzierung, Abrechnung und beschleunigte Auszahlung:</b><br>Stellen Sie Ihren Tarif individuell zusammen."
        ></rza-mean-title>
    </rza-mean-section>

    <rza-mean-section class="flex flex-grow flex-col justify-center">
        <div *ngIf="(selectedProfessionBranch$ | async) === undefined; else professionSelect" class="flex justify-center">
            <div class="grid max-w-[420px] flex-grow grid-cols-2 gap-10 max-sm:gap-4">
                @for (branch of professionBranches$ | async; track branch.name) {
                <div
                    rzaMeanShiny
                    (click)="onSelectProfessionBranch(branch.name)"
                    class="bg-secondary xs:p-4 flex aspect-square cursor-pointer flex-col items-center justify-center rounded-xl p-3 text-white sm:justify-between"
                    data-cy="profession-branch-select"
                >
                    <rza-mean-graphic
                        [image]="branch.iconFile?.destination"
                        classes="text-white max-sm:h-[50px] sm:h-[100px] pb-2"
                    ></rza-mean-graphic>
                    <div class="text-center text-lg uppercase max-sm:text-base">{{ branch.name }}</div>
                </div>
                }
            </div>
        </div>
        <ng-template #professionSelect>
            <div class="flex flex-col items-center justify-center">
                <div class="bg-secondary rounded-xl p-8 text-white" data-cy="profession-select">
                    <div class="text-xl uppercase">{{ selectedProfessionBranch$ | async }}</div>
                    @for (profession of (professions$ | async) || []; track profession._id) {
                    <div (click)="onSelectProfession(profession)" class="flex cursor-pointer items-center">
                        @if (profession.status === ProfessionStatusEnum.ACTIVE.valueOf()) {
                        <rza-mean-radio [selected]="profession._id === selectedIdProfession"></rza-mean-radio>
                        } @else {
                        <fa-icon class="text-xl text-white" [icon]="['far', 'comment-dots']"></fa-icon>
                        }
                        <div
                            class="font ml-4"
                            [ngClass]="{
                                italic: profession.status.toString() === professionStatusEnum.CONTACT.valueOf()
                            }"
                        >
                            {{ profession.name }}
                        </div>
                    </div>
                    }
                </div>
                <button class="btn btn-xs btn-outline-primary mt-3" (click)="onUnSelectProfessionBranch()">Zurück</button>
            </div>
        </ng-template>
    </rza-mean-section>

    <rza-mean-page-navigation
        class="w-full"
        *ngIf="(selectedProfession$ | async)?.status?.toString() !== professionStatusEnum.CONTACT.valueOf()"
        [disabled]="selectedIdProfession === undefined || (selectedProfession$ | async)?._id !== selectedIdProfession"
    ></rza-mean-page-navigation>
</rza-mean-page>
