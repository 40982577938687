import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { PreSelectResolver } from './services/pre-select-resolver.service';

export const preSelectResolverFn: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => inject(PreSelectResolver).resolve(route);

export const MainAppRoutes: Routes = [
    {
        path: '',
        component: HeaderComponent,
        children: [
            {
                path: '',
                resolve: {
                    preSelect: preSelectResolverFn,
                },
                pathMatch: 'full',
                loadChildren: () => import('./pages/start/start.module').then((m) => m.StartModule),
            },
            {
                path: 'paket',
                loadChildren: () => import('./pages/bundle/bundle.module').then((m) => m.BundleModule),
            },
            {
                path: 'umsatz',
                loadChildren: () => import('./pages/configurator/configurator.module').then((m) => m.ConfiguratorModule),
            },
            {
                path: 'vorfinanzierung',
                loadChildren: () => import('./pages/prefinancing/prefinancing.module').then((m) => m.PrefinancingModule),
            },
            {
                path: 'beworben/:name',
                // resolve: {
                //     service: PromotedServiceResolver,
                // },
                loadChildren: () => import('./pages/promoted-service/promoted-service.module').then((m) => m.PromotedServiceModule),
            },
            {
                path: 'zusatzoptionen',
                loadChildren: () =>
                    import('./pages/additional-services/additional-services.module').then((m) => m.AdditionalServicesModule),
            },
            {
                path: 'software/:name',
                // resolve: {
                //     software: SoftwareResolver
                // },
                runGuardsAndResolvers: 'always',
                loadChildren: () => import('./pages/software/software.module').then((m) => m.SoftwareModule),
            },
            {
                path: 'zusammenfassung',
                loadChildren: () => import('./pages/summary/summary.module').then((m) => m.SummaryModule),
            },
            {
                path: 'unternehmensdaten',
                // resolve: {
                //     businessForms: BusinessFormsResolver
                // },
                loadChildren: () => import('./pages/company-data/company-data.module').then((m) => m.CompanyDataModule),
            },
            {
                path: 'kontodaten',
                // resolve: {
                //     businessForms: BusinessFormsResolver
                // },
                loadChildren: () => import('./pages/bank-account-data/bank-account-data.module').then((m) => m.BankAccountDataModule),
            },
            {
                path: 'unterlagen',
                // resolve: {
                //     documents: DocumentsResolver
                // },
                loadChildren: () => import('./pages/documents/documents.module').then((m) => m.DocumentsModule),
            },
            {
                path: 'verifizierung',
                loadChildren: () => import('./pages/verification/verification.module').then((m) => m.VerificationModule),
                // resolve: {
                //     documents: VerificationResolver
                // },
                // canActivate: [VerificationGuard]
            },
            {
                path: 'zusammenfassung-ende',
                loadChildren: () => import('./pages/summary/summary.module').then((m) => m.SummaryModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(MainAppRoutes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
