import { Pipe, PipeTransform } from '@angular/core';
import { Price } from '../shared/interfaces';
import { DecimalPipe } from '@angular/common';

type Options = {
    abs?: boolean;
};

@Pipe({
    name: 'price',
})
export class PricePipe implements PipeTransform {
    constructor(private numberPipe: DecimalPipe) {}
    transform(price?: Price, options?: Options): string {
        if (price) {
            if (price.value) {
                const value = options?.abs ? Math.abs(price.value) : price.value;
                return `${price.isVariable ? 'ab ' : ''}${this.numberPipe.transform(value, '1.2-2', 'de')}\u00A0${price.unit}`;
            }
            return 'inklusive';
        }
        return '';
    }
}
