import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BillingType } from '../shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BillingTypeStoreService {
    baseUrl = environment.apiUrl;

    public state$: Observable<BillingType[]>;
    private _state$: BehaviorSubject<BillingType[]>;
    private fetchPending: boolean;

    constructor(private apiService: ApiService, private http: HttpClient) {
        this._state$ = new BehaviorSubject([] as BillingType[]);
        this.state$ = this._state$.asObservable();
        this.fetchPending = false;
        this.loadBillingTypes();
    }

    loadBillingTypes() {
        if (this.state && !this.fetchPending) {
            this.fetchPending = true;
            this.http.get<BillingType[]>(`${this.baseUrl}/billing-types`).subscribe((billingTypes) => {
                this.fetchPending = false;
                this._state$.next(billingTypes);
            });
        }
    }

    get state(): BillingType[] {
        return this._state$.getValue();
    }

    set state(nextState: BillingType[]) {
        this._state$.next(nextState);
    }
}
