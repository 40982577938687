import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, Subject, take, takeUntil } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { Breadcrumb } from '../../shared/interfaces';
import { ContactRequestService } from '../../services/contact-request.service';

@Component({
    selector: 'rza-mean-page-navigation',
    templateUrl: './page-navigation.component.html',
    styleUrls: ['./page-navigation.component.scss'],
})
export class PageNavigationComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'mt-auto';
    @Input() nextPageBtnText: string | undefined;
    @Input() disabled: boolean | undefined;
    @Input() callback: Observable<Breadcrumb> | (() => void) | undefined;
    @Input() showSpinner: boolean | undefined;

    @ViewChild('nextBtn') private nextBtn!: ElementRef;

    public isFirstPage: boolean | undefined;
    public isLastPage: boolean | undefined;
    public nextBreadcrumb$: Observable<Breadcrumb | null>;
    public oneClickFlag: boolean;

    private unsubscribe = new Subject();

    constructor(private navigationService: NavigationService, public contactRequestService: ContactRequestService) {
        this.oneClickFlag = false;
        this.nextBreadcrumb$ = of({} as Breadcrumb);
    }

    ngOnInit(): void {
        this.navigationService
            .isFirstPage()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isFirstPage) => (this.isFirstPage = isFirstPage));
        this.navigationService
            .isLastPage()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isLastPage) => (this.isLastPage = isLastPage));
        this.nextBreadcrumb$ = this.navigationService.getNextBreadcrumb();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    public navToNextPage(): void {
        if (typeof this.callback === 'function') {
            this.callback();
            this.navigationService.navigateToNextPage();
        } else if (this.callback instanceof Observable) {
            this.callback.pipe(take(1)).subscribe({
                next: (response) => {
                    if (response) {
                        this.navigationService.navigateToNextPage();
                    } else {
                        this.oneClickFlag = false;
                    }
                },
                error: () => {
                    this.oneClickFlag = false;
                },
            });
        } else {
            this.navigationService.navigateToNextPage();
        }
        if (this.isLastPage) {
            this.nextBtn.nativeElement.style.width = this.nextBtn.nativeElement.getBoundingClientRect().width + 'px';
        }
    }

    public navToPreviousPage(): void {
        this.navigationService.navigateToPreviousPage();
    }

    redirectOrContactForm() {
        if (this.contactRequestService.idProfession) {
            this.contactRequestService.openContactRequestModal();
        } else {
            const supportURL = 'https://www.optadata.de/service/online/interessenten';
            window.open(supportURL, '_blank');
        }
    }
}
