export enum ProductCategoryEnum {
    GKV = 'GKV',
    PRIVATE = 'Privat',
    ADDITIONAL_PAYMENT = 'Zuzahlung',
    SETTLEMENT = 'Abrechnung',
    ACTIVESERVICE = 'AktivService',
    ACTIVEREPORT = 'AktivReport',
    ACTIVEPROTECTION = 'AktivSchutz',
    SOFTWARE = 'Software',
}
