<rza-mean-page>
    <rza-mean-section *ngIf="promotedBundles$ | async as bundlePackage">
        <rza-mean-title [headline]="bundlePackage.promotion?.title" [subHeadline]="bundlePackage.promotion?.subtitle"></rza-mean-title>
    </rza-mean-section>

    <div class="flex flex-grow flex-col justify-center">
        <rza-mean-section>
            <div
                *ngIf="startBundles$ | async as bundles"
                class="grid grid-cols-1 gap-12 lg:grid-cols-2"
                [ngClass]="['2xl:grid-cols-' + bundles.length]"
            >
                <div *ngFor="let bundle of bundles; let i = index" class="flex flex-col justify-end">
                    <rza-mean-card
                        rzaMeanSelectable
                        selectionStyleClasses="primary-selected"
                        cardClasses="to-secondary bg-gradient-to-b from-[#d2ece7] flex flex-col"
                        (selected)="selectBundle($event, bundle)"
                        [isSelected]="selectedBundleId === bundle._id"
                        selectionType="radio"
                        bodyClasses="!pb-4 flex-grow"
                        footerClasses="mb-[3px] mx-[3px] bg-white"
                        [ngClass]="{ separator: (separatorIndex$ | async) === i || false }"
                        #bundleCard
                    >
                        <ng-template #header>
                            <div class="w-full p-4 text-center">
                                <span
                                    class="bg-primary mt-5 rounded-full p-1 font-semibold text-white"
                                    [ngClass]="[
                                        bundles.length > 3 && (bundle.promotion?.title ?? '').length > 10
                                            ? 'px-4 text-base'
                                            : 'px-8 text-lg'
                                    ]"
                                    [innerHTML]="bundle.promotion?.title"
                                ></span>
                            </div>
                        </ng-template>
                        <ng-container *ngIf="bundle.promotion?.description?.length">
                            <ng-template #body>
                                <div class="text-white" rzaMeanListBullet [innerHTML]="bundle.promotion?.description"></div>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="getVisibleDiscounts(bundle) as discounts">
                            <ng-template #footer>
                                <div class="grid py-2" [ngClass]="['grid-cols-' + discounts.length]">
                                    <div
                                        *ngFor="let price of discounts; let first = first; let last = last"
                                        class="bundle-discount relative"
                                        [ngClass]="'grid-cols-' + discounts.length"
                                    >
                                        <div class="flex h-full flex-col items-center" [ngClass]="{ 'border-r-2': !last }">
                                            <span class="text-secondary font-medium">
                                                {{ price | price : { abs: true } }}
                                                <sup *ngIf="price.smallPrint?.length">{{
                                                    smallPrintService.register(price.smallPrint || [])
                                                }}</sup>
                                            </span>
                                            <span class="px-2 text-center text-xs font-light">{{ price.appendix }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="getBannerItems$(bundle) | async as bannerItems">
                            <ng-template #footer [ngIf]="bannerItems.length > 0">
                                <div class="grid" [ngClass]="['grid-cols-' + bannerItems.length]">
                                    <div
                                        *ngFor="let item of bannerItems; let first = first; let last = last"
                                        class="bundle-discount relative flex items-center"
                                        [ngClass]="'grid-cols-' + bannerItems.length"
                                    >
                                        <div
                                            class="text-paragraph px-2 text-center font-medium"
                                            [ngClass]="{ 'border-r-2': !last }"
                                            [innerHtml]="item"
                                        ></div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngIf="bundle.promotion?.modalContent != null">
                            <ng-template #info>
                                <div
                                    class="text-primary mb-1 text-lg font-medium"
                                    *ngIf="bundle.promotion?.modalContent?.title"
                                    [innerHTML]="bundle.promotion?.modalContent?.title"
                                ></div>
                                <div
                                    rzaMeanListBullet
                                    [classes]="['text-primary']"
                                    [innerHTML]="bundle.promotion?.modalContent?.content"
                                ></div>
                            </ng-template>
                        </ng-container>
                    </rza-mean-card>
                </div>
            </div>
        </rza-mean-section>

        <ng-container *ngIf="buttonBundles$ | async as bundles">
            <rza-mean-section *ngIf="bundles.length">
                <rza-mean-card
                    cardClasses="inline-block bg-primary max-md:w-full"
                    rzaMeanSelectable
                    selectionType="radio"
                    (selected)="selectBundle($event, bundles[0])"
                    [isSelected]="selectedBundleId === bundles[0]._id"
                    headerText="Weiter ohne Paket?"
                    headerClasses="text-center text-white !text-lg"
                    bodyClasses="!text-white"
                    [bodyText]="bundles[0].promotion?.title || ''"
                >
                    <ng-template #info>
                        <div
                            class="text-primary mb-1 text-lg font-medium"
                            *ngIf="bundles[0].promotion?.modalContent?.title"
                            [innerHTML]="bundles[0].promotion?.modalContent?.title"
                        ></div>

                        <div rzaMeanListBullet [innerHTML]="bundles[0].promotion?.modalContent?.content"></div>
                    </ng-template>
                </rza-mean-card>
            </rza-mean-section>
        </ng-container>

        <rza-mean-section>
            <rza-mean-small-print></rza-mean-small-print>
        </rza-mean-section>
    </div>

    <rza-mean-page-navigation [disabled]="!selectedBundleId"> </rza-mean-page-navigation>
</rza-mean-page>
