import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TaxCountryCodeEnum } from '@rza-mean/api-interfaces';

@Injectable({
    providedIn: 'root',
})
export class ValidatorsService {
    taxCountryCodeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value.length === 2) {
                return Object.values(TaxCountryCodeEnum).includes(control.value.toString().toUpperCase())
                    ? null
                    : {
                          taxCountryCodeInvalid: { value: 'Länderkennung ungültig' },
                      };
            } else if (control.value == null || control.value === '') {
                return null;
            }
            return { taxCountryCodeInvalid: { value: 'Länderkennung ungültig' } };
        };
    }

    ibanValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                const forbidden = control.value.replace(/\s/g, '').match(/^([a-zA-Z]{2})(\d{2})(\d{12,30})$/);
                if (!forbidden) {
                    return { ibanInvalid: { value: 'IBAN ungültig' } };
                }
                let letters = forbidden[1].toUpperCase();
                letters = `${forbidden[3]}${letters.charCodeAt(0) - 55}${letters.charCodeAt(1) - 55}00`;
                const partLength = 10;
                while (letters.length > partLength) {
                    const part = letters.substring(0, partLength);
                    letters = (part % 97) + letters.substring(partLength);
                }
                letters %= 97;
                const checkNumber = (98 - letters).toString().padStart(2, '0');
                return checkNumber !== forbidden[2] ? { ibanInvalid: { value: 'IBAN ungültig' } } : null;
            }
            return { ibanInvalid: { value: 'IBAN ungültig' } };
        };
    }

    institutionCodeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                const ik = control.value.toString();
                if (ik.length !== 9) {
                    return { institutionCode: { value: 'IK Nummer ungültig' } };
                } else {
                    const ikSubStr = ik.substring(2, 8);
                    const checkNumber = this.luhnAlgorithm(ikSubStr);
                    return checkNumber != ik.slice(-1) ? { institutionCode: { value: 'IK Nummer ungültig' } } : null;
                }
            } else if (control.value == null || control.value === '') {
                return null;
            }
            return { institutionCode: { value: 'IK Nummer ungültig' } };
        };
    }

    private checkSum(num: number) {
        return num < 10 ? num : Number(num.toString()[0]) + Number(num.toString()[1]);
    }
    private luhnAlgorithm(data: string) {
        const weight = [1, 2, 1, 2, 1, 2];
        const remainder = data
            .split('')
            .reverse()
            .map((num, index) => {
                return Number(num) * weight[index];
            })
            .map((_) => {
                return this.checkSum(_);
            })
            .reduce((sum, current) => sum + current);
        return remainder % 10;
    }
}
