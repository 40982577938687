export enum TaxCountryCodeEnum {
    AL = 'AL',
    AD = 'AD',
    AM = 'AM',
    AT = 'AT',
    BY = 'BY',
    BE = 'BE',
    BA = 'BA',
    BG = 'BG',
    CH = 'CH',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    EE = 'EE',
    ES = 'ES',
    FO = 'FO',
    FI = 'FI',
    FR = 'FR',
    GB = 'GB',
    GE = 'GE',
    GI = 'GI',
    EL = 'EL',
    HU = 'HU',
    HR = 'HR',
    IE = 'IE',
    IS = 'IS',
    IT = 'IT',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MC = 'MC',
    MK = 'MK',
    MT = 'MT',
    NO = 'NO',
    NL = 'NL',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    RU = 'RU',
    SE = 'SE',
    SI = 'SI',
    SK = 'SK',
    SM = 'SM',
    TR = 'TR',
    UA = 'UA',
    VA = 'VA',
}
