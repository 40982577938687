import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfessionState } from './profession.state';
import { PriceDependencyTypeEnum, ProductTypeEnum, PromotionCategoryEnum } from '@rza-mean/api-interfaces';
import { Bundle, Product, Profession } from '../../shared/interfaces';
import { by } from '../../shared/utils';
import { selectConfiguredBundleId } from '../configuration/configuration.selectors';

const serviceCategoriesOrder = [
    'AktivService-Leistungen',
    'AktivSchutz-Leistungen',
    'AktivReport-Leistungen',
    'Sonstige Zusatzleistungen',
    'Inklusive Zusatzleistungen',
];

const professionState = createFeatureSelector<ProfessionState>('profession');

export const selectProfessionBranches = createSelector(professionState, (state: ProfessionState) => state.professionBranches);
export const selectSelectedProfessionBranch = createSelector(professionState, (state: ProfessionState) => state.selectedProfessionBranch);
export const selectMinimalProfessionsByBranch = (branch: string | undefined) =>
    createSelector(professionState, (state: ProfessionState) => {
        if (branch) {
            return state.minimalProfessions.filter((profession) => profession.professionBranch.name === branch);
        }
        return state.minimalProfessions;
    });
export const selectSelectedProfession = createSelector(professionState, (state: ProfessionState) => state.selectedProfession);
export const selectSettlementProducts = createSelector(
    selectSelectedProfession,
    (profession: Profession) => profession.products?.filter((product) => product.type === ProductTypeEnum.SETTLEMENT) || []
);
export const selectSettlementProductsAvailableInBundle = (bundle: Bundle) =>
    createSelector(selectSettlementProducts, (products: Product[]) =>
        products.filter((product) => !bundle.excludedProducts?.includes(product._id))
    );
export const selectObligatoryProducts = createSelector(
    selectSelectedProfession,
    (profession: Profession) => profession.products?.filter((product) => product.isObligatory) || []
);
export const selectServices = createSelector(
    selectSelectedProfession,
    (profession: Profession) =>
        profession.products?.filter((product) => product.type === ProductTypeEnum.SERVICE).sort(by('orderPriority')) || []
);
export const selectAllBundles = createSelector(
    selectSelectedProfession,
    (profession: Profession) => profession.promotedBundles?.reduce((acc, { bundles }) => [...acc, ...bundles], [] as Bundle[]) || []
);
export const selectPhoneNumber = createSelector(selectSelectedProfession, (profession: Profession) => profession.phone);
export const selectEmail = createSelector(selectSelectedProfession, (profession: Profession) => profession.email);
export const selectFreeServices = createSelector(selectServices, (products: Product[]) =>
    products.filter(
        (product) =>
            !product.products?.length &&
            (product.prices?.length === 0 || product.prices?.every((price) => price.value === 0)) &&
            product.dependentPrices?.length === 0 &&
            product.staggeredPrices?.length === 0
    )
);
export const selectDefaultActiveServices = createSelector(selectServices, (products: Product[]) =>
    products.filter((product) => product.defaultActive)
);
export const selectSelectedBundle = createSelector(
    selectSelectedProfession,
    selectConfiguredBundleId,
    (profession: Profession, id: string | undefined) => {
        if (profession.promotedBundles?.length && id) {
            return profession.promotedBundles[0].bundles.find((b) => b._id === id);
        } else {
            return undefined;
        }
    }
);
export const selectAvailableSettlementProducts = createSelector(
    selectSettlementProducts,
    selectSelectedBundle,
    (products: Product[], bundle: Bundle | undefined) => {
        if (bundle?.settlementForRevenueOnly) {
            products = products.filter((product) => product.forRevenueOnly);
        } else {
            products = products.filter((product) => !product.forRevenueOnly);
        }
        if (bundle?.excludedProducts?.length) {
            products = products.filter((product) => !bundle.excludedProducts?.includes(product._id));
        }
        return products;
    }
);
export const selectPrefinancing = createSelector(
    selectSelectedProfession,
    selectSelectedBundle,
    (profession: Profession, bundle: Bundle | undefined) =>
        profession.products
            ?.filter((product) => product.type === ProductTypeEnum.PREFINANCING)
            .filter((product) => {
                if (bundle?.excludedProducts?.length) {
                    return !bundle.excludedProducts?.includes(product._id);
                }
                return true;
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .sort(by<Product>('paymentSpeed.milliseconds')) || []
);
export const selectPromotedBundles = createSelector(selectSelectedProfession, (profession: Profession) => {
    if (profession?.promotedBundles && profession.promotedBundles.length > 0) {
        return (
            profession.promotedBundles.find((promotedBundle) => promotedBundle.promotion?.category === PromotionCategoryEnum.START) ?? null
        );
    }
    return null;
});
export const selectAdditionalServicesCategorized = createSelector(
    selectServices,
    selectSelectedBundle,
    (services: Product[], bundle: Bundle | undefined) => getAdditionalServicesCategorized(services, bundle)
);
export const selectStartUpTypedProducts = (confirmed: boolean) =>
    createSelector(selectSelectedProfession, (profession: Profession) => {
        if (profession.products) {
            return profession.products?.filter((product) => {
                const dependentPrices = product.dependentPrices;
                if (dependentPrices?.length) {
                    const dependentPriceHasTypeStartUp = dependentPrices.find(
                        (price) => price.type === PriceDependencyTypeEnum.STARTUP && String(confirmed) === price.referenceId
                    );
                    return dependentPriceHasTypeStartUp != null;
                }
                return false;
            });
        }
        return [];
    });

export const selectDefaultBusinessForm = createSelector(
    selectSelectedProfession,
    (profession: Profession) => profession.defaultBusinessForm
);

function getAdditionalServicesCategorized(services: Product[], bundle: Bundle | undefined): [string, Product[]][] {
    const includedProductIds = bundle?.config?.configuredProfession?.products
        .filter((product) => !product.products?.length)
        .map((product) => product._id);
    const includedProductWithNestedProductIds = bundle?.config?.configuredProfession?.products
        .filter((product) => product.products?.length)
        .map((product) => product._id);
    const excludedProductIds = bundle?.excludedProducts;
    const additionalServicesCategorized = services
        .filter((product) => !product.isOmittedFromSelection)
        .filter((service) => !includedProductIds?.includes(service._id) && !excludedProductIds?.includes(service._id))
        .map((service) => {
            // filter nested products
            if (service.products?.length) {
                return {
                    ...service,
                    products: service.products?.filter((prod) => !excludedProductIds?.includes(prod._id)),
                };
            }
            return service;
        })
        .filter((service) => {
            // filter products with only one nested product, that are pre-configured and not customizable
            if (service.products?.length === 1) {
                if (includedProductWithNestedProductIds?.includes(service._id)) {
                    const preConfigured = bundle?.config?.configuredProfession?.products.find((_) => _._id === service._id);
                    if (
                        preConfigured &&
                        preConfigured.products?.length === 1 &&
                        preConfigured.products[0]._id === service.products[0]._id
                    ) {
                        return false;
                    }
                }
            }
            return true;
        })
        .reduce((acc, curr) => {
            const hasPrice =
                curr.prices?.some((price) => price.value) ||
                curr.products?.some((prod) => prod.prices?.some((price) => price.value)) ||
                curr.dependentPrices?.some((dependentPrice) => {
                    return dependentPrice.referenceId === bundle?._id && dependentPrice.price.value;
                });

            const cat = !hasPrice
                ? 'Inklusive Zusatzleistungen'
                : curr.category
                ? `${curr.category}-Leistungen`
                : 'Sonstige Zusatzleistungen';
            if (cat in acc) {
                acc[cat].push(curr);
            } else {
                acc[cat] = [curr];
            }
            return acc;
        }, {} as Record<string, Product[]>);
    return Object.entries(additionalServicesCategorized).sort((a, b) => {
        if (serviceCategoriesOrder.indexOf(a[0]) < serviceCategoriesOrder.indexOf(b[0])) {
            return -1;
        }
        if (serviceCategoriesOrder.indexOf(a[0]) > serviceCategoriesOrder.indexOf(b[0])) {
            return 1;
        }
        return 0;
    });
}
