import { MinimalProfession, MinimalProfessionBranch, Profession } from '../../shared/interfaces';

export interface ProfessionState {
    professionBranches: MinimalProfessionBranch[];
    selectedProfessionBranch: string | undefined;
    minimalProfessions: MinimalProfession[];
    selectedProfession: Profession;
    error: unknown;
}

export const initialProfessionState: ProfessionState = {
    professionBranches: [],
    selectedProfessionBranch: undefined,
    minimalProfessions: [],
    selectedProfession: {} as Profession,
    error: {},
};
