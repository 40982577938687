import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    BankCredentials,
    Company,
    CompanySubsidiary,
    ContactPerson,
    Customer,
    PreviousSettlementCenter,
    ReceiptEntranceNotification,
    ReceiptSubmission,
    Document,
    CompanyForm,
    CompanyIdentificationForm,
    CompanyPerson,
} from '../../shared/interfaces';

export const setCompany = createAction('[App Init] Set Company', props<{ company: Company }>());

export const setCustomer = createAction('[App Init] Set Customer', props<{ customer: Customer }>());

export const setDocuments = createAction('[App Init] Set Documents', props<{ documents: Document[] }>());

export const updateCompanyComponentGroup = createActionGroup({
    source: 'Company Page',
    events: {
        'Update Company': props<{ company: Company }>(),
        'Update Bank Credentials': props<{ bankCredentials: BankCredentials }>(),
        'Update Receipt Submission': props<{ receiptSubmission: ReceiptSubmission }>(),
        'Update Receipt Entrance Notification': props<{ receiptEntranceNotification: ReceiptEntranceNotification }>(),
        'Add Subsidiary': props<{ subsidiary: CompanySubsidiary }>(),
        'Update Subsidiary': props<{ subsidiary: CompanySubsidiary; index: number }>(),
        'Remove Subsidiary': props<{ index: number }>(),
        'Add Contact Person': props<{ contact: ContactPerson }>(),
        'Update Contact Person': props<{ contact: ContactPerson; index: number }>(),
        'Remove Contact Person': props<{ index: number }>(),
        'Update Company Form': props<{ companyForm: CompanyForm }>(),
        'Update Company Identification Form': props<{ companyIdentification: CompanyIdentificationForm }>(),
        'Update Company Person': props<{ companyPerson: CompanyPerson }>(),
        'Update Recommended By': props<{ recommendedBy: string }>(),
    },
});

export const updatePreviousSettlementCenter = createActionGroup({
    source: 'Documents Page',
    events: {
        'Update Previous Settlement Center': props<{ previousSettlementCenter: PreviousSettlementCenter }>(),
        'Upload Termination File': props<{ file: File }>(),
        'Update Previous Settlement Center Success': props<{ previousSettlementCenter: PreviousSettlementCenter }>(),
        'Update Previous Settlement Center Failure': props<{ error: unknown }>(),
        'Do Nothing': emptyProps(),
    },
});
