<div class="flex">
    <div *ngIf="textPrepend" class="bg-primary-lighter mr-[-1px] rounded-l" [ngClass]="getInputSizeClasses()">
        {{ textPrepend }}
    </div>
    <div class="relative flex flex-grow">
        <input
            [type]="type"
            [id]="getId()"
            [(ngModel)]="value"
            (input)="change($event)"
            (blur)="onBlur(true)"
            [disabled]="disabled"
            class="text-paragraph border-primary group-[.ng-valid.ng-touched]:border-secondary focus:drop-shadow-primary-soft peer inline-block w-full flex-grow appearance-none border-2 bg-white transition duration-300 ease-in-out focus:outline-none focus:ring-0 disabled:!border-gray-200 disabled:bg-gray-200 disabled:opacity-100 group-[.ng-invalid.ng-touched]:border-red-700"
            [ngClass]="getInputClasses()"
            [placeholder]="placeholder"
            [max]="max"
            [min]="min"
            [readOnly]="readonly"
            [autocomplete]="autocomplete"
        />
        <label
            *ngIf="label"
            [for]="getId()"
            class="text-primary absolute left-2 top-2 z-10 origin-top-left -translate-y-4 scale-75 transform bg-white px-2 font-medium duration-300 group-[.ng-invalid.ng-touched]:text-red-700 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-disabled:bg-opacity-0"
            [ngClass]="getLabelSizeClasses()"
        >
            {{ label }}
        </label>
        <div
            class="pointer-events-none absolute right-4 top-1/2 hidden h-8 w-8 -translate-y-1/2 rounded-full border-2 border-red-700 group-[.ng-invalid.ng-touched]:block"
        >
            <fa-icon class="custom-translate-exclamation-icon absolute left-1/2 top-1/2 text-red-700" icon="exclamation"></fa-icon>
        </div>
    </div>
    <div
        *ngIf="textAppend"
        [innerHtml]="textAppend"
        class="ml-[-1px] rounded-r {{ textAppendClasses }}"
        [ngClass]="getInputSizeClasses()"
    ></div>
    <div
        *ngIf="btnAppend"
        [innerHtml]="btnAppend"
        class="{{ btnAppendClasses }}"
        [ngClass]="getInputSizeClasses()"
        (click)="btnAppendClick.emit(); $event.stopPropagation()"
    ></div>
</div>
<div #error class="px-4 pt-2 text-sm text-red-700"></div>
