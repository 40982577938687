<div class="grid w-full grid-cols-1 justify-items-stretch gap-8 lg:sticky lg:grid-cols-3">
    <!--region PROFESSION-->
    <div class="flex flex-col">
        <h3 class="text-primary mb-3 text-lg font-bold">Berufsgruppe:</h3>
        <rza-mean-card
            class="flex-grow"
            cardClasses="bg-primary text-white flex items-center justify-center"
            [headerText]="(profession$ | async)?.name?.replace('/', '/&#8203;')"
            headerClasses="text-center text-xl 2xl:text-2xl"
        ></rza-mean-card>
    </div>
    <!--endregion-->

    <!--region BUNDLE-->
    <div *ngIf="bundle$ | async as bundle" class="flex flex-col">
        <h3 class="text-primary mb-3 text-lg font-bold">Gewähltes Paket:</h3>
        <rza-mean-card class="flex-grow" cardClasses="flex justify-center items-center" headerClasses="text-center" alignment="center">
            <ng-template #header>
                <h3 class="p-4 text-xl text-white 2xl:text-2xl" [innerHTML]="bundle.promotion?.title"></h3>
            </ng-template>
        </rza-mean-card>
    </div>
    <!--endregion-->

    <!--region PREFINANCING-->
    <div *ngIf="prefinancing$ | async as prefinancing" class="flex flex-col">
        <h3 class="text-primary pb-3 text-lg font-bold">Auszahlungs&shy;geschwindigkeit:</h3>
        <rza-mean-card class="flex-grow" cardClasses="bg-primary" bodyClasses="flex flex-col justify-center h-full" alignment="center">
            <ng-template #body
                ><p class="text-xl text-white 2xl:text-2xl" *ngIf="prefinancing.paymentSpeed">
                    {{ prefinancing.paymentSpeed | paymentSpeed }}
                </p>
            </ng-template>
        </rza-mean-card>
    </div>
    <!--endregion-->
</div>
