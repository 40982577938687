import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'rza-mean-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
    ],
})
export class ToggleComponent implements ControlValueAccessor {
    @Input() label = '';
    @Input() value = false;
    @Input() disabled = false;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: any = (val: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouch: any): void {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    change($event: Event) {
        this.onChange(($event.target as HTMLInputElement).checked);
    }

    writeValue(value: boolean) {
        if (value) {
            this.value = value;
        }
    }
}
