import {
    ApplicationRef,
    Component,
    ComponentRef,
    createComponent,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Injector,
    Input,
    NgModule,
    OnDestroy,
    TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { isDescendantOf } from '../../misc/utils';
import { createPopper, Placement } from '@popperjs/core';

@Component({
    selector: 'rza-mean-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
    @HostBinding('class') classes = 'animate-fade-in';
    tooltip: string | undefined;
    tooltipTemplateRef: TemplateRef<any> | undefined;

    constructor(private elementRef: ElementRef) {}

    forceWidth(width: number) {
        this.elementRef.nativeElement.style.width = width.toString() + 'px';
    }
}

@Directive({
    selector: '[rzaMeanTooltip]',
})
export class TooltipDirective implements OnDestroy {
    static tooltips: TooltipDirective[] = [];
    @Input() tooltip: string | TemplateRef<unknown> = '';
    // @Input() position: 'top' | 'right' | 'bottom' | 'left' = 'left';
    @Input() placement: Placement = 'left-start';
    @Input() forceWidth?: number;

    private componentRef: ComponentRef<any> | null = null;
    private visible = false;

    @HostListener('click', ['$event'])
    onClick(e: Event) {
        e.stopPropagation();
        this.toggle();
    }

    @HostListener('document:click', ['$event.target'])
    private onAnyClick(target: HTMLElement) {
        if (this.componentRef && !isDescendantOf(this.componentRef.location.nativeElement, target)) {
            this.close();
        }
    }

    constructor(private elementRef: ElementRef, private appRef: ApplicationRef, private injector: Injector) {}

    ngOnDestroy(): void {
        this.close();
    }

    toggle() {
        this.visible = !this.visible;
        if (this.visible) {
            this.open();
        } else {
            this.close();
        }
    }

    open() {
        while (TooltipDirective.tooltips.length > 0) {
            TooltipDirective.tooltips.pop()?.close();
        }
        this.componentRef = createComponent(TooltipComponent, {
            environmentInjector: this.appRef.injector,
            elementInjector: this.injector,
        });
        if (this.tooltip instanceof TemplateRef) {
            this.componentRef.instance.tooltipTemplateRef = this.tooltip;
        } else {
            this.componentRef.instance.tooltip = this.tooltip;
        }
        if (this.forceWidth) {
            this.componentRef.instance.forceWidth(this.forceWidth);
        }
        this.componentRef.location.nativeElement.style.visibility = 'hidden';
        this.appRef.attachView(this.componentRef.hostView);
        document.body.appendChild(this.componentRef.location.nativeElement);
        setTimeout(() => {
            if (this.componentRef) {
                const popperInstance = createPopper(this.elementRef.nativeElement, this.componentRef.location.nativeElement, {
                    placement: this.placement,
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                boundary: 'viewport',
                            },
                        },
                        {
                            name: 'center',
                            enabled: window.innerWidth < 800,
                            phase: 'beforeWrite',
                            fn: ({ state }) => {
                                const referenceRect = state.rects.reference;
                                const popperRect = state.rects.popper;
                                let offset = referenceRect.y - popperRect.height;
                                offset = offset >= window.scrollY + 15 ? offset : window.scrollY + 15;
                                state.styles['popper'] = {
                                    ...state.styles['popper'],
                                    left: '50%',
                                    top: `${offset}px`,
                                    transform: 'translateX(-50%)',
                                };
                                return state;
                            },
                        },
                    ],
                });
                popperInstance.update();
                this.componentRef.location.nativeElement.style.visibility = 'visible';
                TooltipDirective.tooltips.push(this);
            }
        }, 0);
    }

    close() {
        this.visible = false;
        if (this.componentRef != null) {
            this.componentRef.destroy();
        }
    }
}

@NgModule({
    imports: [CommonModule],
    declarations: [TooltipDirective, TooltipComponent],
    exports: [TooltipDirective],
})
export class TooltipDirectiveModule {}
