import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorsService } from '../../../services/validators.service';
import { BankCredentials } from '../../../shared/interfaces';
import { filter, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectBankCredentials } from '../../../store/company/company.selectors';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'rza-mean-bank-credentials-form',
    templateUrl: './bank-credentials-form.component.html',
    styleUrls: ['./bank-credentials-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankCredentialsFormComponent implements OnInit, OnDestroy {
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() updateBankCredentials: EventEmitter<BankCredentials> = new EventEmitter<BankCredentials>();

    bankCredentialsForm: FormGroup;
    private unsubscribe$ = new Subject();

    constructor(private fb: FormBuilder, private validatorService: ValidatorsService, private store: Store) {
        this.bankCredentialsForm = this.fb.group(
            {
                institute: [environment.companyData?.bankInstitute ?? '', Validators.required],
                owner: [environment.companyData?.accountOwner ?? '', Validators.required],
                iban: [environment.companyData?.iban ?? '', [Validators.required, this.validatorService.ibanValidator()]],
                bic: [environment.companyData?.bic ?? ''],
            },
            { updateOn: 'blur' }
        );
    }

    ngOnInit(): void {
        this.store
            .select(selectBankCredentials)
            .pipe(
                filter((bankCredentials) => bankCredentials != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((bankCredentials) => {
                if (bankCredentials != null) {
                    this.bankCredentialsForm.patchValue(bankCredentials, { emitEvent: false });
                }
                this.bankCredentialsForm.updateValueAndValidity({ emitEvent: false });
                this.isValid.emit(this.bankCredentialsForm.valid);
            });
        this.bankCredentialsForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
            this.isValid.emit(this.bankCredentialsForm.valid);
            this.updateBankCredentials.emit(form);
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
