import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, combineLatest, concatMap, map, Observable, of, take, tap, throwError } from 'rxjs';
import { Company, Configuration, Customer, PreviousSettlementCenter, Session } from '../shared/interfaces';
import { setConfig } from '../store/configuration/configuration.actions';
import { Store } from '@ngrx/store';
import { setCompany, setCustomer, setDocuments } from '../store/company/company.actions';
import { addDiscounts } from '../store/discount/discount.actions';
import { selectConfig } from '../store/configuration/configuration.selectors';
import { selectCompany, selectCustomer } from '../store/company/company.selectors';
import { ProfessionActions } from '../store/profession/profession.actions';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private apiUrl: string;
    private Session = new BehaviorSubject<Session | null>(null);
    private session$ = this.Session.asObservable();

    constructor(private http: HttpClient, private store: Store) {
        this.apiUrl = environment.apiUrl;
    }

    init(idProfession?: string | null, force = false): Observable<Session | null> {
        return this.http
            .post<Session | null>(this.apiUrl + '/sessions', idProfession || force ? { idProfession } : null, {
                withCredentials: true,
            })
            .pipe(
                concatMap((resp) => {
                    if (idProfession) {
                        return this.http.post<Session | null>(this.apiUrl + '/sessions', { idProfession }, { withCredentials: true });
                    }
                    return of(resp);
                }),
                tap((resp) => {
                    this.Session.next(resp);

                    if (resp?.config) {
                        this.store.dispatch(setConfig({ config: resp.config }));
                        if (resp.config.configuredProfession?.profession) {
                            this.store.dispatch(ProfessionActions.load({ id: resp.config.configuredProfession?.profession }));
                        }
                        if (resp.config.configuredProfession?.discounts) {
                            this.store.dispatch(addDiscounts({ discounts: resp.config.configuredProfession.discounts }));
                        }
                    }
                    if (resp?.company) {
                        this.store.dispatch(setCompany({ company: resp.company }));
                    }
                    if (resp?.customer) {
                        this.store.dispatch(setCustomer({ customer: resp.customer }));
                    }
                    if (resp?.document) {
                        this.store.dispatch(setDocuments({ documents: resp.document }));
                    }
                })
            );
    }

    hasSession(): Observable<boolean> {
        return this.session$?.pipe(map((session) => !!session && session.sid != null)) ?? of(false);
    }

    updateApiConfig(config: Configuration): Observable<unknown> {
        if (config.configuredProfession && config.configuredProfession.profession.length) {
            return this.http
                .put<Session | null>(this.apiUrl + '/sessions', { config }, { withCredentials: true })
                .pipe(tap((resp) => this.Session.next(resp)));
        }
        return of(null);
    }

    updateApiCompany(company: Company): Observable<unknown> {
        return this.http.put(this.apiUrl + '/sessions', { company }, { withCredentials: true });
    }

    updateApiCustomer(customer: Customer): Observable<unknown> {
        const newCustomer: Partial<Customer> = { ...customer };
        if (customer._id === '') {
            delete newCustomer._id;
        }
        return this.http.put(this.apiUrl + '/sessions', { customer: newCustomer }, { withCredentials: true });
    }

    updateAgbAcceptance(agbAccepted: boolean): Observable<unknown> {
        return this.http.put(this.apiUrl + '/sessions', { agbAccepted }, { withCredentials: true });
    }

    uploadTerminationFile(file: File): Observable<PreviousSettlementCenter> {
        const form = new FormData();
        form.append('previousSettlementCenter', file);

        return this.http.post<PreviousSettlementCenter>(this.apiUrl + '/sessions/upload/termination', form, {
            withCredentials: true,
        });
    }

    updateSession(session: Partial<Session>) {
        const newCustomer: Partial<Customer> = { ...session.customer };
        if (session.customer?._id === '') {
            delete newCustomer._id;
        }
        return this.http.put(
            this.apiUrl + '/sessions',
            {
                company: session.company,
                config: session.config,
                customer: newCustomer,
            },
            {
                withCredentials: true,
                observe: 'response',
            }
        );
    }

    resetSessionConfig() {
        return this.http.put(this.apiUrl + '/sessions/resetConfig', null, {
            withCredentials: true,
            observe: 'response',
        });
    }

    complete(isTestMode = false) {
        const options = { withCredentials: true };
        return combineLatest([this.store.select(selectConfig), this.store.select(selectCompany), this.store.select(selectCustomer)]).pipe(
            map(([config, company, customer]) => ({ config, company, customer })),
            take(1),
            concatMap((session) => this.updateSession(session)),
            concatMap((resp) => {
                if (resp.status === 200) {
                    return this.http.post<any>(`${this.apiUrl}/sessions/complete`, { isTestMode }, options);
                }
                return throwError(() => new Error('Es ist etwas schief gelaufen.'));
            })
        );
    }
}
