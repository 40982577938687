import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'rza-mean-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true,
        },
    ],
})
export class RadioComponent implements ControlValueAccessor, OnInit {
    @Input() labels: Array<string> = [];
    @Input() checkedLabel: string | undefined;
    @Input() readOnly = false;
    @Input() selected = false;
    @Input() classes = false;
    @Input() checkedClasses = false;

    disabled = false;
    value: any;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: any = (val: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    ngOnInit() {
        if (this.checkedLabel == null) {
            this.checkedLabel = this.labels[0];
        }
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouch: any): void {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    writeValue(value: any): void {
        if (value) {
            this.value = value;
        }
    }

    change($event: Event) {
        this.onChange(($event.target as HTMLInputElement).value);
    }
}
