export function isDescendantOf(parent: HTMLElement, child: HTMLElement | null): boolean {
    if (!child) {
        return false;
    }
    if (child === parent) {
        return true;
    }
    if (child.parentElement === parent) {
        return true;
    } else {
        return isDescendantOf(parent, child.parentElement || null);
    }
}

export function hideScrollbar(doc = document) {
    const bodyStyle = doc.body.style;
    const { overflow, paddingRight } = bodyStyle;
    const scrollbarWidth = Math.abs(window.innerWidth - doc.body.clientWidth);
    if (scrollbarWidth > 0) {
        const actualPadding = parseFloat(window.getComputedStyle(doc.body).paddingRight);
        bodyStyle.paddingRight = `${actualPadding + scrollbarWidth}px`;
    }
    doc.body.style.overflow = 'hidden';
    return () => {
        if (scrollbarWidth > 0) {
            bodyStyle.paddingRight = paddingRight;
        }
        bodyStyle.overflow = overflow;
    };
}
