import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fadeInOut, slideInOut } from '../../misc/animations';

@Component({
    selector: 'rza-mean-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [fadeInOut, slideInOut],
})
export class SidebarComponent implements AfterViewInit, OnChanges, OnDestroy {
    private backdrop!: HTMLElement;
    private backdropClickUnlisten!: () => void;

    @Input()
    visible!: boolean;

    @Output()
    visibleChange = new EventEmitter<boolean>();

    @Input()
    position: 'top' | 'right' | 'bottom' | 'left' = 'left';

    @Input()
    size: number | string = 350;

    @Input()
    hideClose = false;

    constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.initBackdrop();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['visible'].currentValue) {
            this.open();
        } else if (!changes['visible'].isFirstChange()) {
            this.close();
        }
    }

    ngOnDestroy(): void {
        this.backdropClickUnlisten();
    }

    private open(): void {
        this.renderer.setStyle(this.backdrop, 'display', 'block');
        this.renderer.setProperty(this.backdrop, '@fadeInOut', 'in');
    }

    close(): void {
        this.renderer.setProperty(this.backdrop, '@fadeInOut', 'out');
        this.renderer.setStyle(this.backdrop, 'display', 'none');
        this.visibleChange.emit(false);
    }

    getSize(): string {
        return typeof this.size === 'number' ? `${this.size}px` : this.size;
    }

    private initBackdrop(): void {
        this.backdrop = this.renderer.createElement('div');
        this.renderer.setAttribute(this.backdrop, 'id', 'sidebar-backdrop');
        this.renderer.setProperty(this.backdrop, '@fadeInOut', 'out');
        this.renderer.setStyle(this.backdrop, 'position', 'fixed');
        this.renderer.setStyle(this.backdrop, 'top', '0');
        this.renderer.setStyle(this.backdrop, 'right', '0');
        this.renderer.setStyle(this.backdrop, 'bottom', '0');
        this.renderer.setStyle(this.backdrop, 'left', '0');
        this.renderer.setStyle(this.backdrop, 'z-index', 10000);
        this.renderer.setStyle(this.backdrop, 'background-color', 'rgba(0,0,0,0.5)');
        this.renderer.setStyle(this.backdrop, 'display', 'none');
        this.backdropClickUnlisten = this.renderer.listen(this.backdrop, 'click', () => this.close());
        this.renderer.appendChild(this.document.body, this.backdrop);
    }

    getTranslate(): string {
        switch (this.position) {
            case 'top':
                return 'translate(0, -100%)';
            case 'right':
                return 'translate(100%, 0)';
            case 'bottom':
                return 'translate(0, 100%)';
            case 'left':
                return 'translate(-100%, 0)';
        }
    }

    getClass(): string {
        const classes: string[] = [];
        if (this.position === 'left' || this.position === 'right' || this.position === 'top') {
            classes.push('top-0');
        }
        if (this.position === 'left' || this.position === 'right' || this.position === 'top') {
            classes.push('left-0');
        }
        if (this.position === 'right') {
            classes.push('right-0');
        }
        if (this.position === 'bottom') {
            classes.push('bottom-0');
        }
        return classes.join(', ');
    }
}
