<div class="relative">
    <select
        [id]="selectId"
        [(ngModel)]="value"
        (ngModelChange)="change($event)"
        (blur)="onBlur()"
        class="text text-paragraph border-primary group-[.ng-valid.ng-touched]:border-secondary focus:drop-shadow-primary-soft peer block w-full appearance-none rounded border-2 bg-white px-4 py-3.5 transition duration-300 ease-in-out focus:outline-none focus:ring-0 group-[.ng-invalid.ng-touched]:border-red-700 group-[.ng-invalid.ng-touched]:text-red-700"
    >
        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">{{ placeholder }}</option>
        <option *ngFor="let option of options" [ngValue]="option">
            {{ keyToDisplay !== undefined ? option[keyToDisplay] : option }}
        </option>
    </select>
    <label
        [for]="selectId"
        class="text-primary absolute top-2 left-2 z-10 origin-top-left -translate-y-4 scale-75 transform bg-white px-2 font-medium duration-300 group-[.ng-invalid.ng-touched]:text-red-700 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
        >{{ label }}</label
    >
    <div
        class="pointer-events-none absolute top-1/2 right-10 hidden h-8 w-8 -translate-y-1/2 rounded-full border-2 border-red-700 group-[.ng-invalid.ng-touched]:block"
    >
        <fa-icon class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-red-700" icon="exclamation"></fa-icon>
    </div>
</div>
<div #error class="px-4 pt-2 text-sm text-red-700"></div>
