<rza-mean-page>
    <rza-mean-section>
        <rza-mean-title
            headline="Unterlagen"
            subHeadline="Damit wir unseren Abrechnungsservice für Sie einrichten können, benötigen wir für den Vertragsabschluss verschiedene gesetzlich vorgeschriebene Unterlagen von Ihnen. Diese können Sie uns klassisch per Post senden oder schnell und sicher online hochladen."
        ></rza-mean-title>
        <div class="card p-8">
            <div class="flex justify-between">
                <h3 class="text-primary mb-6 text-xl font-medium">Ihr bisheriges Abrechnungszentrum</h3>
                <rza-mean-info-btn (click)="showInfoDialog($event)"></rza-mean-info-btn>
            </div>
            <div rzaMeanDropdown position="bottom">
                <button
                    type="button"
                    rzaMeanDropdownToggle
                    [ngClass]="{ 'btn-success': selectedSettlementCenter }"
                    class="btn btn-primary min-w-[17rem]"
                >
                    {{
                        selectedSettlementCenter.name !== '' && selectedSettlementCenter.name != null
                            ? selectedSettlementCenter.name !== previousSettlementCenterEnum.SONSTIGE
                                ? selectedSettlementCenter.name
                                : selectedSettlementCenter.name +
                                  ': ' +
                                  (selectedSettlementCenter.customName ? selectedSettlementCenter.customName : '')
                            : 'Bisheriges Abrechnungszemtrum wählen...'
                    }}
                </button>
                <div rzaMeanDropdownMenu class="rounded border bg-white py-3">
                    <div class="text-primary px-5 py-1 font-semibold">Bisheriges Abrechnungszentrum</div>
                    <hr class="my-3" />
                    <div
                        *ngFor="let settlementCenter of previousSettlementCenterList"
                        rzaMeanDropdownItem
                        (click)="onSelectPreviousSettlementCenter(settlementCenter)"
                        class="cursor-pointer px-5 py-1 hover:bg-gray-200"
                    >
                        <div *ngIf="settlementCenter !== previousSettlementCenterEnum.SONSTIGE; else others">{{ settlementCenter }}</div>
                        <ng-template #others>
                            <div>{{ settlementCenter }}:</div>
                            <rza-mean-input size="sm" (input)="editCustomSettlementCenter($event)"></rza-mean-input>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div *ngIf="settlementCenterNeedsTermination()">
                <hr class="my-8" />
                <div class="grid grid-cols-2 gap-4">
                    <div>
                        <p>Wurde die Kündigung von Ihrem bisherigen Abrechnungszentrum bestätigt?</p>
                        <rza-mean-radio
                            [labels]="['Ja', 'Nein']"
                            [checkedLabel]="terminationConfirmation"
                            [(ngModel)]="terminationConfirmation"
                            (ngModelChange)="terminationChange()"
                        ></rza-mean-radio>
                    </div>
                    <div>
                        <div *ngIf="terminationConfirmation === 'Ja'">
                            <p class="mb-4">Zu welchem Termin wurde die Kündigung bestätigt?</p>
                            <rza-mean-datepicker
                                [min]="selectedSettlementCenter.termination || getTerminationMinDate()"
                                [(ngModel)]="selectedSettlementCenter.termination"
                                (ngModelChange)="terminationChange()"
                            ></rza-mean-datepicker>
                        </div>
                    </div>
                </div>
                <div *ngIf="terminationConfirmation === 'Ja'">
                    <hr class="my-8" />
                    <div class="mb-3 grid grid-cols-3 gap-4">
                        <div class="flex items-center" [ngClass]="{ 'opacity-30': selectedSettlementCenter.document?.handInLater }">
                            <strong>Kündigungsbestätigung Ihres bisherigen Abrechnungszeitraums</strong>
                        </div>
                        <rza-mean-file-upload
                            [(ngModel)]="uploadTermination"
                            (ngModelChange)="uploadTerminationFile()"
                            [label]="uploadTermination?.name || 'Datei auswählen'"
                            inputId="previousSettlementCenter"
                            [disabled]="selectedSettlementCenter.document?.handInLater || false"
                        ></rza-mean-file-upload>
                        <div class="flex items-center justify-end">
                            <rza-mean-checkbox
                                [(ngModel)]="terminationHandInLater"
                                (ngModelChange)="terminationFileHandInLater()"
                                label="diese Datei reiche ich nach"
                            ></rza-mean-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </rza-mean-section>
    <rza-mean-section *ngIf="obligatoryDocuments.length > 0">
        <div class="mt-14 text-center">
            <rza-mean-checkbox
                [(ngModel)]="handInLaterAllObligatoryDocuments"
                (ngModelChange)="toggleHandInLater()"
                label="Ich möchte eine Checkliste für alle benötigten Dokumente via E-Mail erhalten und sofort fortfahren"
            ></rza-mean-checkbox>
        </div>
        <div class="mt-5 mb-14 flex items-center justify-center">
            <small><strong>oder direkt hochladen</strong></small>
        </div>
        <h3 class="text-primary mb-6 text-xl font-medium">Benötigte Unterlagen</h3>
        <div
            *ngFor="let document of getDocumentsFilteredByCategory(obligatoryDocuments, documentCategory.GENERAL)"
            class="mb-3 grid grid-cols-3 gap-4"
        >
            <div class="flex items-center" [ngClass]="{ 'opacity-30': document.handInLater }">
                <strong>{{ document.documentType.name }}:</strong>
            </div>
            <rza-mean-file-upload
                [(ngModel)]="document.uploadedFile"
                [label]="document.uploadedFile?.name || 'Datei auswählen'"
                [inputId]="document._id"
                [disabled]="document.handInLater || false"
            ></rza-mean-file-upload>
            <div class="flex items-center justify-end">
                <rza-mean-checkbox [(ngModel)]="document.handInLater" label="diese Datei reiche ich nach"></rza-mean-checkbox>
            </div>
        </div>
    </rza-mean-section>
    <rza-mean-section *ngIf="optionalDocuments.length > 0">
        <h3 class="text-primary mb-6 text-xl font-medium">Optionale Unterlagen</h3>
        <div
            *ngFor="let document of getDocumentsFilteredByCategory(optionalDocuments, documentCategory.GENERAL)"
            class="mb-3 grid grid-cols-3 gap-4"
        >
            <div class="flex items-center">
                <strong>{{ document.documentType.name }}:</strong>
            </div>
            <rza-mean-file-upload
                [(ngModel)]="document.uploadedFile"
                [label]="document.uploadedFile?.name || 'Datei auswählen'"
                [inputId]="document._id"
            ></rza-mean-file-upload>
            <div class="flex items-center justify-end"></div>
        </div>
    </rza-mean-section>
    <rza-mean-page-navigation></rza-mean-page-navigation>
</rza-mean-page>

<ng-template #terminationInfo>
    <p>
        Bitte beachten Sie: Je nach Abrechnungsdienstleister kann die Kündigungsfrist bis zu einem Jahr betragen. Wann Ihr Vertrag mit Ihrem
        aktuellen Abrechnungsdienstleister endet, entnehmen Sie der Kündigungsbestätigung, die Sie nach Eingang der Kündigung bei Ihrem
        Abrechnungszentrum erhalten. Bitte teilen Sie uns das Datum des Vertragsendes mit.
    </p>
    <br />
    <p>
        <strong>Wichtig:</strong>
        Ihre neue Vereinbarung mit der opta data Finance GmbH beginnt mit Eingang der ersten Beleglieferung zur GKV-Abrechnung oder zur
        Privatabrechnung in den Geschäftsräumen der odFin (Grundvertragslaufzeit).
    </p>
</ng-template>
