<ng-template #contact>
    <p class="mb-3">
        Vielen Dank für Ihr Interesse an unserem Abrechnungsservice. Um Ihre Wünsche bestmöglich zu erfüllen, beraten wir Sie gern
        persönlich. Senden Sie uns einfach eine Nachricht zu
    </p>
    <div class="flex flex-col text-lg gap-y-4">
        @if (phone$ | async; as phoneNumber) {
            <a
                [href]="'tel:' + (hrefPhone$ | async)"
                class="text-muted hover:text-primary group flex cursor-pointer items-center lg:pl-4 mb-1rem"
            >
                <fa-icon class="text-muted group-hover:text-primary pr-2" icon="phone"></fa-icon>
                <span>{{ phoneNumber }}</span></a
            >
        } @if (email$ | async; as email) {
        <a [href]="'mailto:' + email" class="text-muted hover:text-primary group flex cursor-pointer items-center lg:pl-4 mb-1rem">
            <fa-icon class="text-muted group-hover:text-primary pr-2" icon="envelope"></fa-icon>
            <span>{{ email }}</span>
        </a>
    }
    </div>
    <p class="mb-3 mt-2">
        oder nutzen sie unser Kontaktformular. Wir melden uns umgehend bei Ihnen!
    </p>
    <ol class="mb-8 list-inside list-decimal">
        <li>Kontaktformular ausfüllen</li>
        <li>Nachricht schreiben</li>
        <li>Anfrage absenden</li>
    </ol>

    <rza-mean-contact-request-form
        (closeEvent)="contactRequestService.closeContactRequestModal()"
        [idProfession]="contactRequestService.idProfession || ''"
    ></rza-mean-contact-request-form>
</ng-template>

@if (userCentricButton && contactRequestService.idProfession && userCentricButton?.computedStyleMap()?.get('opacity') == '1') {

<div
    class="fixed z-10"
    (click)="contactRequestService.openContactRequestModal()"
    [ngStyle]="{
        top: userCentricButton.getBoundingClientRect().y - userCentricButton.offsetHeight - 15 + 'px',
        left: '0px'
    }"
>
    <div
        rzaMeanShiny
        [ngStyle]="{
            height: userCentricButton.offsetHeight + 'px',
            minWidth: userCentricButton.offsetWidth + userCentricButton.getBoundingClientRect().x + 'px'
        }"
        class="bg-primary bottom-50 left-0 flex h-[64px] cursor-pointer items-center justify-end rounded-r-full px-4 pr-[4px] drop-shadow-lg md:justify-between"
    >
        <span class="mr-2 hidden flex-grow text-center text-xl text-white md:inline-block">Fragen?</span>
        <img
            alt="support"
            src="../../../assets/images/support-avatar.png"
            [ngClass]="{
                'h-[38px] w-[38px]': userCentricButton.offsetHeight < 60,
                'h-[58px] w-[58px]': userCentricButton.offsetHeight >= 60
            }"
            class="rounded-full object-cover"
        />
    </div>
</div>
}
