import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { MinimalProfessionActions, ProfessionActions } from './profession.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ProfessionService } from '../../services/profession.service';
import { selectConfiguredBundleId } from '../configuration/configuration.selectors';
import { Store } from '@ngrx/store';
import { ConfigurationState } from '../configuration/configuration.state';
import { ProfessionState } from './profession.state';
import { selectObligatoryProducts } from './profession.selectors';
import { Product } from '../../shared/interfaces';
import { selectProfession } from '../configuration/configuration.actions';

@Injectable()
export class ProfessionEffects {
    loadMinimalProfessions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinimalProfessionActions.load),
            mergeMap(() =>
                this.professionService.getMinimalProfessions().pipe(
                    map((minimalProfessions) => MinimalProfessionActions.loadSuccess({ minimalProfessions })),
                    catchError((error) => of(MinimalProfessionActions.loadFailure({ error })))
                )
            )
        )
    );

    loadProfession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfessionActions.load),
            mergeMap((action) =>
                this.professionService.getProfession(action.id).pipe(
                    map((profession) => ProfessionActions.loadSuccess({ profession })),
                    catchError((error) => of(ProfessionActions.loadFailure({ error })))
                )
            )
        )
    );

    setIdProfessionInConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProfessionActions.loadSuccess),
            map((action) => selectProfession({ _id: action.profession._id }))
        )
    );

    addObligatoryProducts$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProfessionActions.loadSuccess),
                concatLatestFrom(() => this.configStore.select(selectConfiguredBundleId)),
                concatLatestFrom(() => this.professionStore.select(selectObligatoryProducts)),
                mergeMap(([[action, bundleId], obligatoryProducts]) => {
                    const profession = action.profession;
                    let obligatoryProductsToAdd: Product[] = [];
                    if (bundleId && profession.promotedBundles?.length) {
                        const bundle = profession.promotedBundles[0].bundles.find((bun) => bun._id === bundleId);
                        if (bundle) {
                            for (const obligatoryProduct of obligatoryProducts) {
                                const excludedProduct = (bundle.excludedProducts || []).find((bundle) => bundle === obligatoryProduct._id);
                                if (!excludedProduct) {
                                    obligatoryProductsToAdd.push(obligatoryProduct);
                                }
                            }
                        } else {
                            obligatoryProductsToAdd = obligatoryProducts;
                        }
                    } else if (bundleId == undefined && !profession.promotedBundles?.length) {
                        obligatoryProductsToAdd = obligatoryProducts;
                    }
                    this.professionService.addObligatoryProducts(obligatoryProductsToAdd);
                    return of(null);
                })
            ),
        {
            dispatch: false,
        }
    );

    constructor(
        private actions$: Actions,
        private professionService: ProfessionService,
        private configStore: Store<ConfigurationState>,
        private professionStore: Store<ProfessionState>
    ) {}
}
