<div class="grid w-full grid-cols-1 justify-items-stretch gap-8 lg:sticky lg:grid-cols-3">
    <!--region PROFESSION-->
    <div class="flex flex-col">
        <h3 class="text-primary mb-3 text-lg font-bold">Berufsgruppe:</h3>
        <rza-mean-card
            class="flex-grow"
            cardClasses="bg-primary text-white flex items-center justify-center"
            [headerText]="(profession$ | async)?.name?.replace('/', '/&#8203;')"
            headerClasses="text-center text-xl 2xl:text-2xl"
        ></rza-mean-card>
    </div>
    <!--endregion-->

    <!--region BUNDLE-->
    <div *ngIf="bundle$ | async as bundle" class="flex flex-col">
        <h3 class="text-primary mb-3 text-lg font-bold">Gewähltes Paket:</h3>
        <rza-mean-card class="flex-grow" cardClasses="flex justify-center items-center" headerClasses="text-center" alignment="center">
            <ng-template #header>
                <h3 class="p-4 text-xl font-semibold text-white 2xl:text-2xl" [innerHTML]="bundle.promotion?.title"></h3>
            </ng-template>
        </rza-mean-card>
    </div>
    <!--endregion-->

    <!--region SETTLEMENT-->
    <!--    <ng-container *ngIf="settlementProducts$ | async as settlements">-->
    <!--        <div *ngIf="settlements.length" class="flex flex-col">-->
    <!--            <h3 class="text-primary pb-3 text-lg font-bold">Abrechnungsprodukte:</h3>-->
    <!--            <rza-mean-card class="flex-grow" cardClasses="bg-primary text-white" headerClasses="text-xl text-white" alignment="center">-->
    <!--                <ng-template #body>-->
    <!--                    <div *ngFor="let settlement of settlementProducts$ | async">-->
    <!--                        <div class="font-semibold" *ngIf="getPrice$(settlement) | async as price">-->
    <!--                            <span class="text-primary text-lg">{{ settlement.name }}: </span>-->
    <!--                            <span>{{ price | price }}</span>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </ng-template>-->

    <!--                &lt;!&ndash;                <ng-container *ngIf='bundle.promotion?.modalContent != null'>&ndash;&gt;-->
    <!--                &lt;!&ndash;                    <ng-template #info>&ndash;&gt;-->
    <!--                &lt;!&ndash;                        <div&ndash;&gt;-->
    <!--                &lt;!&ndash;                            class='font-medium'&ndash;&gt;-->
    <!--                &lt;!&ndash;                            *ngIf='bundle.promotion?.modalContent?.title != null'&ndash;&gt;-->
    <!--                &lt;!&ndash;                            [innerHTML]='bundle.promotion?.modalContent?.title'&ndash;&gt;-->
    <!--                &lt;!&ndash;                        ></div>&ndash;&gt;-->
    <!--                &lt;!&ndash;                        <div rzaMeanListBullet [innerHTML]='bundle.promotion?.modalContent?.content'></div>&ndash;&gt;-->
    <!--                &lt;!&ndash;                    </ng-template>&ndash;&gt;-->
    <!--                &lt;!&ndash;                </ng-container>&ndash;&gt;-->
    <!--            </rza-mean-card>-->
    <!--        </div>-->
    <!--    </ng-container>-->
    <!--endregion-->

    <div *ngIf="prefinancing$ | async as prefinancing" class="flex flex-col">
        <h3 class="text-primary pb-3 text-lg font-bold">Auszahlungs&shy;geschwindigkeit:</h3>
        <rza-mean-card class="flex-grow" cardClasses="bg-primary" bodyClasses="flex flex-col justify-center h-full" alignment="center">
            <!--            <ng-container *ngIf="prefinancing.modalContent != null">-->
            <!--                <ng-template #info>-->
            <!--                    <div class="text-primary font-medium">Information:</div>-->
            <!--                    <div rzaMeanListBullet [innerHTML]="prefinancing.modalContent.content"></div>-->
            <!--                </ng-template>-->
            <!--            </ng-container>-->

            <ng-template #body
                ><p class="text-xl font-medium text-white 2xl:text-2xl" *ngIf="prefinancing.paymentSpeed">
                    {{ prefinancing.paymentSpeed | paymentSpeed }}
                </p>

                <div *ngFor="let price of getPrices$(prefinancing) | async">
                    <div class="font-semibold text-white">
                        {{ price | price }}
                    </div>
                    <div class="text-white">{{ price.name }}</div>
                </div>
            </ng-template>
        </rza-mean-card>
    </div>
</div>
