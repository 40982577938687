import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, of, take, tap } from 'rxjs';
import {
    addObligatoryProduct,
    BundleActions,
    ConfigCodeActions,
    DiscountActions,
    LoadConfigCodeActions,
    resetConfig,
    RevenueActions,
    selectProfession,
    ServiceProductActions,
    setConfiguredProductPrices,
    setConfiguredProductPricesOnStartUpChanges,
    setIsStartUpConfirmed,
    setPrefinancing,
    setPrefinancingPriceDependentOnRevenueChange,
    SettlementProductActions,
    SoftwareProductActions,
} from './configuration.actions';
import * as DiscountStateActions from '../discount/discount.actions';
import { ConfigStoreService } from '../../services/config-store.service';
import { Configuration, Discount, Price, Product, Requirement } from '../../shared/interfaces';
import { Store } from '@ngrx/store';
import { selectConfig, selectConfiguredPrefinancing, selectGKVProduct } from './configuration.selectors';
import { SessionService } from '../../services/session.service';
import {
    OperatorEnum,
    PriceDependencyTypeEnum,
    ProductCategoryEnum,
    ProductTypeEnum,
    StaggeredPriceReferenceEnum,
} from '@rza-mean/api-interfaces';
import { DiscountState } from '../discount/discount-state';
import { ConfigurationState } from './configuration.state';
import { selectDiscounts } from '../discount/discount.selectors';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { CalculationService } from '../../services/calculation.service';
import {
    selectAllBundles,
    selectDefaultActiveServices,
    selectFreeServices,
    selectPrefinancing,
    selectSelectedProfession,
    selectSettlementProducts,
    selectStartUpTypedProducts,
} from '../profession/profession.selectors';
import { ProfessionState } from '../profession/profession.state';
import { ProductService } from '../../services/product.service';
import * as ConfigurationActions from './configuration.actions';

@Injectable()
export class ConfigurationEffects {
    loadConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadConfigCodeActions.load),
            mergeMap((action) =>
                this.configStoreService.loadConfigCode(action.code).pipe(
                    map((config: Configuration) => LoadConfigCodeActions.loadSuccess({ config })),
                    catchError((error) => of(LoadConfigCodeActions.loadFailure({ error })))
                )
            )
        )
    );

    setBundleConfigOnBundleSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BundleActions.setBundle),
            map((action) => BundleActions.setBundleConfig({ bundle: action.bundle }))
        )
    );

    addDiscountsToPoolOnBundleSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BundleActions.setBundleConfig),
            map((action) => {
                return DiscountStateActions.addDiscounts({ discounts: action.bundle.discounts });
            })
        )
    );

    // check if current prefinancing is not excluded in bundle, if so remove it and add default prefinancing from profession
    checkPrefinancingOnBundleSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BundleActions.setBundleConfig),
            mergeMap((action) => {
                return this.store.select(selectConfiguredPrefinancing).pipe(
                    take(1),
                    map((prefinancing) => {
                        if (prefinancing != null) {
                            if (action.bundle.excludedProducts != null) {
                                if (action.bundle.excludedProducts.includes(prefinancing._id)) {
                                    return true;
                                }
                            }
                            return false;
                        }
                        return true;
                    }),
                    filter((bool) => bool)
                );
            }),
            concatLatestFrom(() => this.professionStore.select(selectPrefinancing)),
            map(([, prefinancings]) => prefinancings.find((pf) => pf.defaultActive) || prefinancings[0]),
            filter((prefinancing): prefinancing is Product => prefinancing != null),
            map((prefinancing) => setPrefinancing({ product: prefinancing }))
        )
    );

    checkDiscountRequirements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DiscountStateActions.addDiscounts,
                DiscountStateActions.addDiscount,
                addObligatoryProduct,
                setPrefinancing,
                SettlementProductActions.addSettlementProduct,
                SettlementProductActions.removeSettlementProduct,
                ServiceProductActions.addServiceProduct,
                ServiceProductActions.removeServiceProduct,
                SoftwareProductActions.addSoftwareProduct,
                SoftwareProductActions.removeSoftwareProduct,
                BundleActions.setBundleConfig
            ),
            concatLatestFrom(() => this.store.select(selectConfig)),
            concatLatestFrom(() => this.discountStore.select(selectDiscounts)),
            concatLatestFrom(() => this.professionStore.select(selectAllBundles)),
            filter(([[, discounts]]) => {
                return discounts.length > 0;
            }),
            map(([[[, config], discounts], bundles]) => {
                // TODO: pass only discounts for selected bundle on first select
                return DiscountActions.activateDiscounts({
                    discounts: discounts?.filter((discount) => this.checkRequirement(discount.requirement, config)),
                    bundles,
                });
            })
        )
    );

    selectDefaultPrefinancingOnProfessionSelect = createEffect(() =>
        this.actions$.pipe(
            ofType(selectProfession),
            concatLatestFrom(() => this.professionStore.select(selectPrefinancing)),
            concatLatestFrom(() => this.store.select(selectConfiguredPrefinancing)),
            map(([[, prefinancings], selectedPrefinancing]) => {
                if (selectedPrefinancing) {
                    return null;
                }
                return prefinancings.find((pf) => pf.defaultActive) || prefinancings[0];
            }),
            filter((prefinancing): prefinancing is Product => prefinancing != null),
            map((prefinancing) => setPrefinancing({ product: prefinancing }))
        )
    );

    selectFreeServicesOnProfessionSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectProfession),
            concatLatestFrom(() => this.professionStore.select(selectFreeServices)),
            concatLatestFrom(() => this.store.select(selectConfig)),
            map(([[, products], config]) => {
                const freeServices = products.map((product) => {
                    const productPrices: Price[] = [];
                    if (product.prices) {
                        productPrices.push(...product.prices);
                    }
                    if ((product.staggeredPrices?.length || product.dependentPrices?.length) && product.prices?.length === 0) {
                        const prices = this.findAppliedPrices(product, config);
                        productPrices.push(...prices);
                    }
                    return { ...product, prices: productPrices };
                });
                return ServiceProductActions.addMultipleServiceProducts({ products: freeServices });
            })
        )
    );

    selectDefaultActiveServicesOnProfessionSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectProfession),
            concatLatestFrom(() => this.professionStore.select(selectDefaultActiveServices)),
            map(([, products]) => ServiceProductActions.addMultipleServiceProducts({ products }))
        )
    );

    gtmProfession$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(selectProfession),
                concatLatestFrom(() => this.professionStore.select(selectSelectedProfession)),
                tap(([, profession]) => this.gtmService.professionSelected(profession))
            ),
        { dispatch: false }
    );

    gtmBundle$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BundleActions.setBundle),
                tap((action) => this.gtmService.bundleSelected(action.bundle))
            ),
        { dispatch: false }
    );

    //TODO: triggered too often, maybe only manual selection needed?
    // gtmPrefinacing$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(setPrefinancing),
    //             tap((action) => this.gtmService.prefinancingSelected(action.product))
    //         ),
    //     { dispatch: false }
    // );

    gtmAddSettlementProduct$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettlementProductActions.addSettlementProduct),
                tap((action) => this.gtmService.settlementProductChanged('hinzugefügt', action.product))
            ),
        { dispatch: false }
    );

    gtmRemoveSettlementProduct$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettlementProductActions.removeSettlementProduct),
                concatLatestFrom(() => this.professionStore.select(selectSettlementProducts)),
                map(([action, products]) => products.find((product) => product._id === action._id)),
                tap((settlementProduct) => this.gtmService.settlementProductChanged('entfernt', settlementProduct))
            ),
        { dispatch: false }
    );

    updateApiConfig$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    addObligatoryProduct,
                    SettlementProductActions.removeSettlementProduct,
                    ServiceProductActions.addMultipleServiceProducts,
                    ServiceProductActions.removeServiceProduct,
                    SoftwareProductActions.removeSoftwareProduct,
                    SoftwareProductActions.removeSoftwareAddonProduct,
                    ConfigCodeActions.setConfigCode,
                    DiscountActions.activateDiscounts,
                    setConfiguredProductPrices,
                    setIsStartUpConfirmed,
                    BundleActions.setBundleConfig
                ),
                debounceTime(1000),
                concatLatestFrom(() => this.store.select(selectConfig)),
                mergeMap(([, config]) => this.sessionService.updateApiConfig(config))
            ),
        { dispatch: false }
    );

    resetSessionConfig$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(resetConfig),
                tap(() => this.sessionService.resetSessionConfig().subscribe())
            ),
        { dispatch: false }
    );

    mapPricesOfConfiguredProductsOnProductSelection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SettlementProductActions.addSettlementProduct,
                ServiceProductActions.addServiceProduct,
                addObligatoryProduct,
                // ServiceProductActions.addMultipleServiceProducts,
                SoftwareProductActions.addSoftwareProduct,
                SoftwareProductActions.addSoftwareAddonProduct,
                setPrefinancing,
                RevenueActions.updateRevenue,
                RevenueActions.updatePatientRevenue,
                RevenueActions.updatePatientCount,
                RevenueActions.updateReceiptCount,
                RevenueActions.updateReceiptValue
            ),
            concatLatestFrom(() => this.store.select(selectConfig)),
            map(([action, config]) => {
                const product = action.product;
                let prices;
                if (product.products?.length) {
                    if ('subProduct' in action && action.subProduct) {
                        prices = this.findAppliedPrices(action.subProduct, config);
                        return setConfiguredProductPrices({
                            idProduct: product._id,
                            idSubProduct: action.subProduct._id,
                            prices,
                        });
                    }
                }

                prices = this.findAppliedPrices(product, config);
                return setConfiguredProductPrices({
                    idProduct: product._id,
                    prices: prices.map((price: Price) =>
                        this.productService.mapFunctionalPrices(
                            price,
                            config.configuredProfession?.products.find((prod) => prod._id === product._id)
                        )
                    ),
                });
            })
        )
    );

    mapPriceOfConfiguredDiscounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SettlementProductActions.addSettlementProduct,
                RevenueActions.updateRevenue,
                RevenueActions.updatePatientRevenue,
                RevenueActions.updatePatientCount,
                RevenueActions.updateReceiptCount,
                RevenueActions.updateReceiptValue,
                DiscountActions.activateDiscounts
            ),
            concatLatestFrom(() => this.store.select(selectConfig)),
            concatLatestFrom(() => this.store.select(selectDiscounts)),
            map(([[action, config], discounts]) => {
                const discountWithRightPrices: { idDiscount: string; price: Price | undefined }[] = discounts.map((discount) => {
                    const price = this.findAppliedPriceForDiscount(discount, config);
                    return { idDiscount: discount._id, price };
                });
                return DiscountActions.setConfiguredDiscountsPrices({ discountWithRightPrices });
            })
        )
    );

    mapPrefinancingPriceDependentOnRevenueChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                RevenueActions.updateRevenue,
                RevenueActions.updatePatientRevenue,
                RevenueActions.updatePatientCount,
                RevenueActions.updateReceiptCount,
                RevenueActions.updateReceiptValue
            ),
            filter((action) => action.product.category === ProductCategoryEnum.GKV),
            concatLatestFrom(() =>
                this.store.select(selectGKVProduct).pipe(
                    map((gkv) => {
                        if (gkv) {
                            return this.calculationService.calculateRevenueForSettlementProduct(gkv);
                        } else {
                            return 0;
                        }
                    }),
                    distinctUntilChanged()
                )
            ),
            map(([, gkvRevenue]) => setPrefinancingPriceDependentOnRevenueChange({ revenue: gkvRevenue }))
        )
    );

    onConfirmedStartupChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setIsStartUpConfirmed),
            concatLatestFrom((action) => this.professionStore.select(selectStartUpTypedProducts(action.isStartUpConfirmed))),
            map(([, products]) => products),
            filter((products) => products.length > 0),
            concatLatestFrom(() => this.store.select(selectConfig)),
            map(([products, config]) => {
                const mappedProducts = products.map((product) => {
                    const prices = this.findAppliedPrices(product, config);
                    return {
                        idProduct: product._id,
                        prices: prices.map((price) => this.productService.mapFunctionalPrices(price, product)),
                    };
                });
                return setConfiguredProductPricesOnStartUpChanges({ products: mappedProducts });
            })
        )
    );

    //TODO: Turn into library function since a similiar implementation also exists in backend
    private findAppliedPrices(product: Product, config: Configuration): Price[] {
        const prices = [];
        const configuredBundleId = config.configuredProfession?.bundle;
        const isStartUpConfirmed = config.isStartUpConfirmed;
        const gkv = config.configuredProfession?.products.find(
            (prod) => prod.type === ProductTypeEnum.SETTLEMENT && prod.category === ProductCategoryEnum.GKV
        );
        if (product.dependentPrices?.length) {
            if (configuredBundleId && product.dependentPrices[0].type === PriceDependencyTypeEnum.BUNDLE) {
                prices.push(
                    product.dependentPrices.find(
                        (dependentPrice) =>
                            dependentPrice.type === PriceDependencyTypeEnum.BUNDLE && dependentPrice.referenceId === configuredBundleId
                    )?.price
                );
            }
            if (product.dependentPrices[0].type === PriceDependencyTypeEnum.STARTUP) {
                prices.push(
                    product.dependentPrices.find(
                        (price) =>
                            price.type === PriceDependencyTypeEnum.STARTUP && String(isStartUpConfirmed || false) === price.referenceId
                    )?.price
                );
            }
        }
        if (product.staggeredPrices?.length && gkv) {
            if (product.staggeredPrices[0].reference === StaggeredPriceReferenceEnum.GKV_REVENUE) {
                const gkvRevenue = gkv ? this.calculationService.calculateRevenueForSettlementProduct(gkv) : 0;
                prices.push(
                    product.staggeredPrices.find(
                        (staggeredPrice) =>
                            staggeredPrice.lowerThreshold <= gkvRevenue && (staggeredPrice.upperThreshold ?? Infinity) >= gkvRevenue
                    )?.price
                );
            }
            if (product.staggeredPrices[0].reference === StaggeredPriceReferenceEnum.GKV_RECEIPT_COUNT && gkv.revenue?.count) {
                const count = gkv.revenue.count;
                prices.push(
                    product.staggeredPrices.find(
                        (staggeredPrice) => staggeredPrice.lowerThreshold <= count && (staggeredPrice.upperThreshold ?? Infinity) >= count
                    )?.price
                );
            }
        }
        if (product.prices?.length) {
            prices.push(product.prices[0]);
        }
        return prices.filter((price): price is Price => price != null);
    }

    private findAppliedPriceForDiscount(discount: Discount, config: Configuration) {
        const gkv = config.configuredProfession?.products.find(
            (prod) => prod.type === ProductTypeEnum.SETTLEMENT && prod.category === ProductCategoryEnum.GKV
        );
        const gkvRevenue = gkv ? this.calculationService.calculateRevenueForSettlementProduct(gkv) : 0;
        if (discount.staggeredPrices?.length) {
            return discount.staggeredPrices.find(
                (staggeredPrice) =>
                    staggeredPrice.reference === StaggeredPriceReferenceEnum.GKV_REVENUE &&
                    staggeredPrice.lowerThreshold <= gkvRevenue &&
                    (staggeredPrice.upperThreshold ?? Infinity) >= gkvRevenue
            )?.price;
        }
        if (discount.price) {
            return discount.price;
        }
        return undefined;
    }

    public checkRequirement(requirement: Requirement | undefined, config: Configuration): boolean {
        if (requirement) {
            if (requirement.requirements.length) {
                let validation = false;
                if (requirement.operator === OperatorEnum.OR) {
                    validation = requirement.requirements.some((requirement) => this.checkRequirement(requirement, config));
                }
                if (requirement.operator === OperatorEnum.AND) {
                    validation = requirement.requirements.every((requirement) => this.checkRequirement(requirement, config));
                }
                return validation;
            }
            if (requirement.products.length) {
                const selectedProductIds = config.configuredProfession?.products.map((product) => product._id) ?? [];
                const matchedProducts = requirement.products.map((id) => selectedProductIds.includes(id));
                let validation = false;
                if (requirement.operator === OperatorEnum.OR) {
                    validation = matchedProducts.some((bool) => bool);
                }
                if (requirement.operator === OperatorEnum.AND) {
                    validation = matchedProducts.every((bool) => bool);
                }
                return validation;
            }
            if (requirement.professions.length) {
                return requirement.professions.includes(config.configuredProfession?.profession ?? '');
            }
            if (requirement.bundles.length) {
                return requirement.bundles.includes(config.configuredProfession?.bundle ?? '');
            }
            return false;
        }
        return true;
    }

    constructor(
        private actions$: Actions,
        private configStoreService: ConfigStoreService,
        private store: Store<ConfigurationState>,
        private discountStore: Store<DiscountState>,
        private professionStore: Store<ProfessionState>,
        private sessionService: SessionService,
        private gtmService: GoogleTagManagerService,
        private calculationService: CalculationService,
        private productService: ProductService
    ) {}
}
