import { createReducer, on } from '@ngrx/store';
import { initialDiscountState } from './discount-state';
import * as DiscountActions from './discount.actions';
import produce from 'immer';

export const discountReducer = createReducer(
    initialDiscountState,
    on(DiscountActions.addDiscounts, (state, { discounts }) =>
        produce(state, (newState) => {
            if (discounts) {
                for (const discount of discounts) {
                    if (!state.discounts.has(discount._id)) {
                        newState.discounts.set(discount._id, discount);
                    }
                }
            }
        })
    ),
    on(DiscountActions.addDiscount, (state, { discount }) =>
        produce(state, (newState) => {
            if (discount != null) {
                newState.discounts.set(discount._id, discount);
            }
        })
    )
);
