<div hidden>
    <div class="xl:grid-cols-4 2xl:grid-cols-4"></div>
    <div class="lg:grid-cols-4"></div>
    <div class="bg-product302 !text-product302 text-product302 border-secondary !text-secondary"></div>
    <div class="bg-productPrivate !text-productPrivate text-productPrivate border-warning"></div>
    <div class="bg-productAdditionalPayment !text-productAdditionalPayment text-productAdditionalPayment !text-paragraph"></div>
    <div class="text-muted bg-gray-50 italic"></div>
    <div class="px-5 py-4 !text-xl text-lg"></div>
    <div class="checked:bg-warning checked:border-warning"></div>
    <div class="grid-cols-2"></div>
    <div class="!bg-warning !bg-success !bg-danger !bg-info"></div>
    <div class="border-3"></div>
    <div class="!w-80"></div>
    <div class="!text-[5.5rem]"></div>
    <div class="md:w-2/3"></div>
    <div class="lg:py-16"></div>
    <div class="hover:underline"></div>
    <div class="bg-paragraph border-paragraph"></div>
    <div class="bg-secondary-light bg-secondary-lighter"></div>
    <div class="shiny"></div>
</div>
<rza-mean-test-mode *ngIf="navigationService.isTestMode"></rza-mean-test-mode>
<router-outlet></router-outlet>

<rza-mean-contact-request></rza-mean-contact-request>
