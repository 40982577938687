<rza-mean-card
    *ngIf="subProducts == null"
    rzaMeanSelectable
    selectionType="checkbox"
    [checkedClasses]="'text-white'"
    [isSelected]="isSelected"
    (selected)="toggleSelection($event)"
    [isObligatory]="product.isObligatory || false"
    [tooltipWidth]="modalContentIsIframe ? 550 : 0"
    [checkboxHasBorder]="!product.isObligatory"
    cardClasses="flex flex-col justify-between"
    selectionStyleClasses="primary-selected"
    class="h-full"
>
    <ng-template #header>
        <div class="p-4 px-12 text-center">
            <h3 class="text-xl font-bold leading-tight text-white" [innerHTML]="product.name"></h3>
        </div>
    </ng-template>
    <ng-template #body>
        <div class="flex h-full flex-col">
            <div class="mb-auto px-6 text-white" [innerHtml]="product.description"></div>
            <hr class="my-4" />
            <div *ngFor="let price of prices$ | async" class="mb-2 flex items-center justify-between">
                <div class="bg-secondary rounded-xl px-2 font-medium text-white">
                    {{ price?.billingType?.displayName }}
                </div>
                <div class="mr-3 text-lg font-semibold text-white">
                    {{ price | price }}
                </div>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="product.modalContent != null">
        <ng-template #info>
            <div *ngIf="modalContentIsIframe; else normalModalContent">
                <rza-mean-toggle
                    (change)="content.hidden = false"
                    label="Ich stimme der Verwendung von Drittanbieter-Cookies zu, um YouTube-Videos anzuzeigen:"
                ></rza-mean-toggle>
                <div #content [hidden]="true" [innerHTML]="getModalIframeContent()"></div>
            </div>
            <ng-template #normalModalContent>
                <div rzaMeanListBullet [innerHTML]="product.modalContent.content"></div>
            </ng-template>
        </ng-template>
    </ng-container>
</rza-mean-card>

<div
    *ngIf="subProducts != null && subProducts.length"
    class="mb-3 grid h-full grid-cols-1 gap-12"
    [ngClass]="{
        'lg:grid-cols-2 xl:grid-cols-3': subProducts.length > 2,
        'lg:grid-cols-2 xl:grid-cols-2': subProducts.length === 2,
     }"
>
    <div class="flex h-full flex-col" *ngFor="let subProduct of subProducts">
        <rza-mean-card
            rzaMeanSelectable
            [selectionType]="product.isObligatory || hasInclusiveSubProduct ? 'radio' : 'mixed'"
            [isSelected]="(isSubProductSelected$(subProduct) | async) || false"
            (selected)="selectSubProduct($event, subProduct)"
            [isObligatory]="subProduct.isObligatory || false"
            [tooltipWidth]="modalContentIsIframe ? 550 : 0"
            [checkboxHasBorder]="!product.isObligatory"
            absoluteHeaderClasses="!h-auto"
            footerClasses="bg-secondary"
            cardClasses="flex flex-col justify-between"
            class="h-full"
            selectionStyleClasses="primary-selected"
            checkedClasses="text-white"
        >
            <ng-template #header>
                <div class="p-4 px-12 text-center">
                    <h3 class="text-xl font-bold leading-tight text-white" [innerHTML]="subProduct.name"></h3>
                </div>
            </ng-template>

            <ng-template #body>
                <div class="mb-auto px-6 text-white" [rzaMeanInnerHtmlLink]="subProduct.description"></div>
            </ng-template>

            <ng-template #footer>
                <hr class="my-4" />

                <div *ngIf="getSubProductPrice(subProduct) as price" class="mb-2 flex items-center justify-between">
                    <div class="bg-secondary rounded-xl px-2 font-medium text-white">
                        {{ price.billingType.displayName }}
                    </div>
                    <div class="mr-3 text-lg font-semibold text-white">
                        {{ price | price }}
                    </div>
                </div>
            </ng-template>
        </rza-mean-card>
    </div>
</div>
