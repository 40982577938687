import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { AccordionGroupComponent } from './accordion-group/accordion-group.component';
import { race, repeat, Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'rza-mean-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterViewInit, OnDestroy {
    @Input()
    closeOthers = false;

    @Output() isOpen = new EventEmitter<boolean>();
    @ContentChildren(AccordionGroupComponent) groups!: QueryList<AccordionGroupComponent>;
    private unsubscribe$ = new Subject<void>();

    ngAfterViewInit(): void {
        this.initGroups();
        this.groups.changes.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.initGroups();
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initGroups(): void {
        if (this.groups.length > 0) {
            this.groups.first.isFirst = true;
            this.groups.last.isLast = true;
            this.groups.forEach((_, index) => (_.index = index));
            race(this.groups.map((group) => group.toggle))
                .pipe(takeUntil(this.unsubscribe$), take(1), repeat())
                .subscribe((indexNotToClose) => {
                    if (this.closeOthers && indexNotToClose > -1) {
                        this.groups.forEach((groupToClose, index) => {
                            if (index !== indexNotToClose) {
                                groupToClose.close();
                            }
                        });
                    }
                    this.isOpen.emit(this.groups.some((group) => group.isOpen));
                });
        }
    }
}
