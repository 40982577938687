import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GraphicsCacheService {

    private cache = new Map<string, string>();

    set(image: string | undefined, svgText: string) {
        if (image) {
            this.cache.set(image, svgText);
        }
    }

    get(image: string) {
        return this.cache.get(image);
    }

    has(image:string) {
        return this.cache.has(image);
    }
}
