import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastService } from '@rza-mean/ui';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
    constructor(private toastService: ToastService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            // Handle success response
            tap((event: any) => {
                if (event instanceof HttpResponse) {
                    const message = event.headers.get('message');
                    if (message) this.toastService.success(undefined, message);
                }
            }),
            catchError((response: any) => {
                let errorMessage = response;

                if (response.error instanceof ErrorEvent) {
                    // Client-side response
                    errorMessage = response.error.message;
                } else if (response.status) {
                    // Server-side response
                    if ('exception' in response.error) errorMessage = response.error.exception.message;
                    if ('message' in response.error) errorMessage = response.error.message;
                }

                const invokeConfigReset = response.headers.get('Reset-Configuration') === 'true';
                if (invokeConfigReset) window.location.reload();

                this.toastService.error('Fehler!', errorMessage);
                return throwError(errorMessage);
            })
        );
    }
}
