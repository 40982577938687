import { Component } from '@angular/core';
import { combineLatestWith, map, Observable } from 'rxjs';
import { Bundle, Product, Profession, Promotion } from '../../shared/interfaces';
import { Store } from '@ngrx/store';
import {
    selectAllConfiguredSettlementProducts,
    selectConfiguredBundleId,
    selectConfiguredPrefinancing,
} from '../../store/configuration/configuration.selectors';
import { ProductService } from '../../services/product.service';
import { selectPromotedBundles, selectSelectedProfession } from '../../store/profession/profession.selectors';
import { ProfessionService } from '../../services/profession.service';

@Component({
    selector: 'rza-mean-summary-sidebar-cards',
    templateUrl: './summary-sidebar-cards.component.html',
    styleUrls: ['./summary-sidebar-cards.component.scss'],
})
export class SummarySidebarCardsComponent {
    profession$: Observable<Profession>;
    prefinancing$: Observable<Product | undefined>;
    bundle$: Observable<Bundle | undefined>;
    settlementProducts$: Observable<Product[] | undefined>;

    constructor(private store: Store, private productService: ProductService, private professionService: ProfessionService) {
        this.profession$ = this.store.select(selectSelectedProfession);
        this.prefinancing$ = this.store.select(selectConfiguredPrefinancing);
        this.bundle$ = this.store.select(selectConfiguredBundleId).pipe(
            combineLatestWith(this.store.select(selectPromotedBundles)),
            map(([bundleId, promotedBundles]) => ({ bundleId, promotedBundles })),
            map(({ bundleId, promotedBundles }) => promotedBundles?.bundles.find((bundle) => bundle._id === bundleId))
        );
        this.settlementProducts$ = this.store
            .select(selectAllConfiguredSettlementProducts)
            .pipe(map((products) => products.filter((product) => !product.forRevenueOnly)));
    }

    getStyledTitle(promotion?: Promotion) {
        return this.professionService.getStyledPromotionTitle(promotion);
    }

    getPrices$(product: Product) {
        return this.productService.findPrices$(product);
    }
}
