import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectAllConfiguredSettlementProducts,
    selectAllProductsByCategory,
    selectConfig,
    selectConfiguredBundleId,
} from '../../store/configuration/configuration.selectors';
import { combineLatestWith, filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { Bundle, ConfiguredProfession, Discount, Price, Product, Promotion } from '../../shared/interfaces';
import { ProductCategoryEnum, ProductTypeEnum } from '@rza-mean/api-interfaces';
import { CalculationService, TotalPricePerPriceType } from '../../services/calculation.service';
import { BillingTypeStoreService } from '../../services/billing-type-store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../../../environments/environment';
import { PricePipe } from '../../pipes/price.pipe';
import { PaymentSpeedPipe } from '../../pipes/payment-speed.pipe';
import { selectPromotedBundles, selectSelectedBundle } from '../../store/profession/profession.selectors';
import { ProfessionService } from '../../services/profession.service';
import { DiscountService } from '../../services/discount.service';
import { SmallPrintService } from '@rza-mean/ui';

interface CategoryList {
    billingTypeName: string;
    idBillingType: string;
    subCategory: {
        header: string;
        categoryIndex: number;
        flag?: string;
        items: CategoryListItem[];
    }[];
}

interface CategoryListItem {
    name: string;
    price: string;
    icon?: IconProp;
    pricePrefix?: string;
    smallprint?: string[];
    idPrice?: number;
    orderPriority?: number;
}

@Component({
    selector: 'rza-mean-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    providers: [PricePipe],
})
export class SummaryComponent implements OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    config$: Observable<ConfiguredProfession | undefined>;
    bundle$: Observable<Bundle | undefined>;
    productsByCategory!: Record<string, Product[]>;
    additionalProductsByCategory: Record<string, Product[]> | undefined;
    summaryList$: Observable<CategoryList[]>;
    totalPrice$: Observable<{ price: number; discount: number }>;
    totalPricePerPriceType$: Observable<TotalPricePerPriceType>;
    isOverviewOpen = false;
    openOnPrint = false;
    discountCodeForm: FormGroup;
    showSmallprintForTotalPercentPrice = false;

    settlementRevenueSummaryText$: Observable<string>;

    totalPriceSmallprint = `Der monatliche Preis kann je nach Abrechnungsvolumen variieren. Rückläufer, Korrekturen und Einreichungsverhalten können sich auf den Preis auswirken. Alle genannten Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer.`;

    totalPriceSmallprintRevenueOnly = `Der monatliche Preis kann je nach Abrechnungsvolumen variieren. Rückläufer, Korrekturen und Einreichungsverhalten können sich auf den Preis auswirken. Bei diesem Tarif handelt es sich um eine Finanzdienstleistung nach §4 Nr. 8c UStG, weshalb sich die prozentuale Vorfinanzierungsgebühr ohne gesetzliche Mehrwertsteuer versteht.`;

    totalPricePerPriceTypeSmallprint = `In Prozent ausgewiesene Preise berechnen sich immer auf Basis des jeweiligen Bruttobelegwerts. Alle genannten Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer.`;
    totalPricePerPriceTypeSmallprintRevenueOnly = `In Prozent ausgewiesene Preise berechnen sich immer auf Basis des jeweiligen Bruttobelegwerts. Bei diesem Tarif handelt es sich um eine Finanzdienstleistung nach §4 Nr. 8c UStG, weshalb sich die prozentuale Vorfinanzierungsgebühr ohne gesetzliche Mehrwertsteuer versteht.`;
    totalPercentPriceSmallprint = 'Der Preis für die Privatabrechnung unterscheidet sich von dem hier angegebenen Preis.';

    private unsubscribe$ = new Subject();
    readonly pdfUrl = environment.apiUrl + '/sessions/print/pdf';

    private paymentSpeedPipe = new PaymentSpeedPipe();

    constructor(
        private store: Store,
        private pricePipe: PricePipe,
        private calculationService: CalculationService,
        private billingTypeStore: BillingTypeStoreService,
        private fb: FormBuilder,
        private professionService: ProfessionService,
        private discountService: DiscountService,
        public smallPrintService: SmallPrintService
    ) {
        this.config$ = this.store.select(selectConfig).pipe(map((config) => config.configuredProfession));
        this.bundle$ = this.store.select(selectConfiguredBundleId).pipe(
            combineLatestWith(this.store.select(selectPromotedBundles)),
            map(([bundleId, promotedBundles]) => ({
                bundleId,
                promotedBundles,
            })),
            map(({ bundleId, promotedBundles }) => promotedBundles?.bundles.find((bundle) => bundle._id === bundleId))
        );
        // missing order in Price
        // this.basicFees$ = this.professionStore.getBasicFees();
        this.summaryList$ = this.getSummaryList();
        // TODO: refactor me!!
        this.getProductsByCategory('included');
        this.getProductsByCategory('excluded');
        this.totalPrice$ = this.calculationService.calculatePrice$();
        this.totalPricePerPriceType$ = this.calculationService.getTotalPricePerPriceType();
        this.discountCodeForm = this.fb.group({
            code: ['', [Validators.required, Validators.minLength(4)]],
        });
        this.store
            .select(selectAllConfiguredSettlementProducts)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((products) => {
                if (products.length > 1) {
                    const settlements: Product[] = [];
                    products.forEach((product) => {
                        if (settlements.length) {
                            const samePriceUnitAlreadyInProducts = settlements.find((prod) => {
                                if (prod.prices && product.prices) {
                                    return (
                                        prod.prices[0].value !== product.prices[0].value && prod.prices[0].unit === product.prices[0].unit
                                    );
                                }
                                return false;
                            });
                            if (samePriceUnitAlreadyInProducts) {
                                settlements.push(product);
                            }
                        } else {
                            settlements.push(product);
                        }
                    });
                    this.showSmallprintForTotalPercentPrice = settlements.length > 1;
                }
            });
        this.settlementRevenueSummaryText$ = this.store
            .select(selectAllConfiguredSettlementProducts)
            .pipe(map((products) => `(${products.map((product) => `${product.identifier}: ${product.revenue?.count}`).join(', ')})`));
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
        this.smallPrintService.reset();
    }

    getProductPriceAsString(product: Product): string {
        if (product.prices && product.prices?.length > 0) {
            return this.pricePipe.transform(product.prices[0]);
        }
        return '';
    }

    getStyledTitle(promotion?: Promotion) {
        return this.professionService.getStyledPromotionTitle(promotion);
    }

    getSummaryList(): Observable<CategoryList[]> {
        const billingTypes$ = this.billingTypeStore.state$.pipe(
            takeUntil(this.unsubscribe$),
            filter((types) => types.length > 0)
        );

        return billingTypes$.pipe(
            map((types) => {
                const summaryList: CategoryList[] = [];
                types.forEach((type) => {
                    summaryList.push({
                        billingTypeName: type.displayName,
                        idBillingType: type._id,
                        subCategory: [],
                    });
                });
                summaryList.sort((a, b) => {
                    let compare = 0;
                    if (a.billingTypeName > b.billingTypeName) {
                        compare = 1;
                    } else if (a.billingTypeName < b.billingTypeName) {
                        compare = -1;
                    }
                    return compare;
                });
                return summaryList;
            }),
            combineLatestWith(this.config$.pipe(takeUntil(this.unsubscribe$))),
            map(([summaryList, config]) => ({
                summaryList,
                config,
            })),
            map(({ summaryList, config }) => {
                summaryList.map((listItem) => {
                    listItem.subCategory = [];
                    return listItem;
                });
                config?.products.forEach((product) => {
                    product.prices?.forEach((price) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const listIndex = summaryList.findIndex((item) => item.idBillingType === price.billingType._id);
                        const header = this.getSubCategoryHeader(product);
                        const item: CategoryListItem = {
                            name: this.getListItemName(product, price),
                            price: this.pricePipe.transform(price),
                            orderPriority: product.orderPriority,
                        };
                        const smallprints: string[] = [];

                        if (price.smallPrint?.length) {
                            smallprints.push(...price.smallPrint);
                        }

                        if (price.minPrice && Object.keys(price.minPrice).length) {
                            const minPrice = price.minPrice;
                            smallprints.push(`${minPrice.name}: ${this.pricePipe.transform(minPrice)}`);
                        }
                        if (price.minPricePerUnit && Object.keys(price.minPricePerUnit).length) {
                            const minPricePerUnit = price.minPricePerUnit;
                            smallprints.push(`${minPricePerUnit.name}: ${this.pricePipe.transform(minPricePerUnit)}`);
                        }
                        if (smallprints.length) {
                            item.smallprint = smallprints;
                        }
                        this.addItemToList(listIndex, summaryList, header, item, product.category as string);
                    });
                    product.products?.forEach((subProduct) => {
                        subProduct.prices?.forEach((price) => {
                            const listIndex = summaryList.findIndex(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (item) => item.idBillingType === price.billingType._id
                            );
                            const header = this.getSubCategoryHeader(product);
                            const item: CategoryListItem = {
                                name: this.getListItemName(subProduct, price),
                                price: this.pricePipe.transform(price),
                            };
                            if (price.smallPrint?.length) {
                                item.smallprint = price.smallPrint;
                            }
                            this.addItemToList(listIndex, summaryList, header, item, product.category as string);
                        });
                    });
                });
                return {
                    summaryList,
                    config,
                };
            }),
            combineLatestWith(this.store.select(selectPromotedBundles).pipe(takeUntil(this.unsubscribe$))),
            map(([{ summaryList, config }, promotedBundles]) => {
                config?.discounts
                    // ?.filter((discount) => discount.isVisible)
                    ?.reduce((map, discount, index) => {
                        if (discount.isInBundle) {
                            const correspondingBundleName =
                                promotedBundles?.bundles.find((bundle) => bundle.discounts?.find((d) => d._id === discount._id))?.name ||
                                `bundle${index}`;
                            map.set(correspondingBundleName, discount);
                        } else {
                            map.set(discount.name, discount);
                        }
                        return map;
                    }, new Map<string, Discount>())
                    .forEach((discount) => {
                        const listIndex = summaryList.findIndex((item) => item.idBillingType === discount.price?.billingType._id);
                        const header = '';
                        let correspondingBundle;
                        if (discount.isInBundle) {
                            correspondingBundle = promotedBundles?.bundles.find((bundle) =>
                                bundle.discounts?.find((d) => d._id === discount._id)
                            );
                        }
                        const item: CategoryListItem = {
                            name: correspondingBundle ? this.getStyledTitle(correspondingBundle.promotion) : discount.name,
                            price: correspondingBundle ? '' : this.pricePipe.transform(discount.price),
                            icon: correspondingBundle ? 'check' : undefined,
                        };
                        if (!discount.isInBundle && discount.price?.smallPrint && discount.price?.smallPrint.length) {
                            item.smallprint = discount.price.smallPrint;
                        }
                        if (listIndex > -1) {
                            const subCategory = summaryList[listIndex]?.subCategory.find(
                                (subCategoryItem) => subCategoryItem.header === header
                            );
                            if (subCategory) {
                                subCategory.items.push(item);
                            } else {
                                summaryList[listIndex].subCategory.push({
                                    header,
                                    categoryIndex: 111,
                                    items: [item],
                                });
                            }
                        }
                    });
                return {
                    summaryList,
                    config,
                };
            }),
            map(({ summaryList }) => {
                const list = summaryList
                    .filter((list) => list.subCategory.some((subCategory) => subCategory.items.length > 0))
                    .map((list) => {
                        list.subCategory.sort((a, b) => {
                            return a.categoryIndex - b.categoryIndex;
                        });
                        return list;
                    })
                    .map((list) => {
                        list.subCategory.map((subCategory) =>
                            subCategory.items.sort((a, b) => {
                                if (a.orderPriority != null && b.orderPriority != null) {
                                    return a.orderPriority - b.orderPriority;
                                }
                                return 0;
                            })
                        );
                        return list;
                    });
                return list;
            })
        );
    }

    private addItemToList(listIndex: number, summaryList: CategoryList[], header: string, item: CategoryListItem, category: string) {
        if (listIndex > -1) {
            const subCategory = summaryList[listIndex]?.subCategory.find((subCategoryItem) => subCategoryItem.header === header);
            if (subCategory) {
                subCategory.items.push(item);
            } else {
                summaryList[listIndex].subCategory.push({
                    header,
                    categoryIndex: (Object.values(ProductCategoryEnum) as string[]).indexOf(category),
                    items: [item],
                });
            }
        }
    }

    private getListItemName(product: Product, price: Price): string {
        if (product.type === ProductTypeEnum.PREFINANCING) {
            return `Vorfinanzierungsgebühr: ${this.paymentSpeedPipe.transform(product.paymentSpeed)}`;
        }
        if (product.prices && product.prices.length > 1 && price.name) {
            return `${product.name} - ${price.name}`;
        }
        return product.name;
    }

    private getSubCategoryHeader(product: Product): string {
        if (product.category) {
            return (
                [
                    ProductCategoryEnum.SETTLEMENT,
                    ProductCategoryEnum.GKV,
                    ProductCategoryEnum.PRIVATE,
                    ProductCategoryEnum.ADDITIONAL_PAYMENT,
                ] as string[]
            ).includes(product.category as string)
                ? 'Abrechnungs-Leistungen'
                : `${product.category}-Leistungen`;
        }
        return 'Sonstige Leistungen';
    }

    // TODO: refactor me!!
    getProductsByCategory(filterType: 'included' | 'excluded') {
        this.store
            .select(
                selectAllProductsByCategory([
                    ProductCategoryEnum.ACTIVESERVICE,
                    ProductCategoryEnum.ACTIVEPROTECTION,
                    ProductCategoryEnum.ACTIVEREPORT,
                    ProductCategoryEnum.SOFTWARE,
                ])
            )
            .pipe(
                combineLatestWith(this.store.select(selectSelectedBundle)),
                map(([products, bundle]) => {
                    if (!products || !bundle) {
                        return [];
                    }
                    const bundleConfiguredProductIds = bundle.config?.configuredProfession?.products.map((product) => product._id) || [];
                    const configuredProductsInProductsIds =
                        bundle.config?.configuredProfession?.products
                            .filter((product) => product.products?.length)
                            .map((products) => products.products?.map((product) => product._id))
                            .flat() || [];

                    return products.filter((product) => {
                        const isConfiguredProductInBundle = bundleConfiguredProductIds.includes(product._id);
                        const isInConfiguredSubProductInBundle =
                            product.products?.length && configuredProductsInProductsIds.includes(product?.products[0]._id);

                        if (filterType === 'included') {
                            return isConfiguredProductInBundle && (!product.products?.length || isInConfiguredSubProductInBundle);
                        } else {
                            return !isConfiguredProductInBundle || (product.products?.length && !isInConfiguredSubProductInBundle);
                        }
                    });
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((products) => {
                if (products.length) {
                    const targetProperty = filterType === 'included' ? 'productsByCategory' : 'additionalProductsByCategory';
                    this[targetProperty] = products.reduce((acc, curr) => {
                        const cat = curr.category as string;
                        acc[cat] = acc[cat] || [];
                        acc[cat].push(curr);
                        return acc;
                    }, {} as Record<string, Product[]>);
                }
            });
    }

    categoryHasProducts(products: Product[] | null | undefined): boolean {
        return Boolean(products?.length);
    }

    scrollTo($event: MouseEvent, query: string) {
        $event.preventDefault();
        const anchor: HTMLElement = document.querySelector(query) as HTMLElement;
        if (anchor) {
            window.scrollTo({
                top: anchor.offsetTop,
            });
        }
    }

    print() {
        this.openOnPrint = true;
        this.isOverviewOpen = true;
        setTimeout(() => {
            window.print();
        }, 0);
    }

    loadDiscountCode() {
        if (this.discountCodeForm.valid) {
            this.discountService.loadDiscountCode(this.discountCodeForm.value.code).subscribe(() => {
                this.discountCodeForm.controls['code'].setValue('');
            });
        }
    }

    isBottomColumnItem(index: number, column: number, items: any[]): boolean {
        const itemsInColumn = items.filter((_, i) => i % 3 === column);
        const lastIndexInColumn = items.findIndex((_, i) => i === (itemsInColumn.length - 1) * 3 + column);
        return index === lastIndexInColumn;
    }
}
