import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
@Component({
    selector: 'rza-mean-info-btn',
    templateUrl: './info-btn.component.html',
    styleUrls: ['./info-btn.component.scss'],
})
export class InfoBtnComponent implements OnInit {
    @Input() infoTitle = 'Mehr erfahren';
    @Input() bgClass: string | string[] | object | undefined;
    @Input() iconClass: string | string[] | object | undefined;
    @Input() borderClass: string | undefined;
    @HostBinding('class') classes = 'shrink-0';

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        if (this.borderClass) {
            this.elementRef.nativeElement.children[0].classList.add('border');
            this.elementRef.nativeElement.children[0].classList.add(this.borderClass);
        }
    }
}
