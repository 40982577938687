<form [formGroup]="companyIdentificationForm" class="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
    <div class="">
        <rza-mean-input formControlName="institutionCode" label="IK-Nummer"></rza-mean-input>
    </div>
    <div class="flex gap-6">
        <div class="basis-1/4">
            <rza-mean-select
                formControlName="taxCountryCode"
                label="Ländercode"
                [options]="taxCountryCode"
                selectId="taxCountryCode"
            ></rza-mean-select>
        </div>
        <div class="basis-3/4">
            <rza-mean-input formControlName="taxId" label="Umsatzsteuer-ID"></rza-mean-input>
        </div>
    </div>
</form>
