import { Product } from './interfaces';

type Path<TObj extends object> = {
    [TKey in keyof TObj & (string | number)]: TObj[TKey] extends any[]
        ? `${TKey}`
        : TObj[TKey] extends object
        ? `${TKey}` | `${TKey}.${Path<TObj[TKey]>}`
        : `${TKey}`;
}[keyof TObj & (string | number)];

export const by =
    <T extends object>(keyString: Path<T>, direction: 'ASC' | 'DESC' = 'ASC') =>
    (a: T, b: T) => {
        const resolve = (path: string, obj: Record<string, any>) => {
            return path.split('.').reduce((prev, curr) => {
                return prev && prev[curr] !== undefined ? prev[curr] : null;
            }, obj);
        };
        if (resolve(keyString, a) < resolve(keyString, b)) {
            return direction === 'ASC' ? -1 : 1;
        }
        if (resolve(keyString, a) > resolve(keyString, b)) {
            return direction === 'ASC' ? 1 : -1;
        }
        return 0;
    };

export function getSelectionClass(product: Product) {
    if (product.color === 'product302') {
        return 'secondary-selected';
    }
    if (product.color === 'productAdditionalPayment') {
        return 'paragraph-selected';
    }
    if (product.color === 'productPrivate') {
        return 'primary-selected';
    }
    return '';
}

export function getProductColor(product: Product) {
    if (product.color === 'product302') {
        return 'secondary';
    }
    if (product.color === 'productAdditionalPayment') {
        return 'paragraph';
    }
    if (product.color === 'productPrivate') {
        return 'primary';
    }
    return '';
}
