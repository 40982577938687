import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BusinessFormsStoreService } from '../../services/business-forms-store.service';
import { Store } from '@ngrx/store';
import { CustomFile, Document, DocumentType, PreviousSettlementCenter } from '../../shared/interfaces';
import { selectIdBusinessForm, selectPreviousSettlementCenter } from '../../store/company/company.selectors';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { DocumentCategoryEnum, SettlementCenterEnum } from '@rza-mean/api-interfaces';
import { updatePreviousSettlementCenter } from '../../store/company/company.actions';
import { ModalService } from '@rza-mean/ui';
import { selectSelectedProfession } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
    @ViewChild('terminationInfo') terminationInfoTemplate: TemplateRef<any> | undefined;

    private unsubscribe$ = new Subject();
    obligatoryDocuments: Document[] = [];
    optionalDocuments: Document[] = [];
    documentCategory = DocumentCategoryEnum;
    handInLaterAllObligatoryDocuments = false;
    previousSettlementCenterList = Object.values(SettlementCenterEnum);
    previousSettlementCenterEnum = SettlementCenterEnum;
    selectedSettlementCenter: PreviousSettlementCenter = {
        name: '',
    };
    noTerminationSettlementCenterList: string[];
    uploadTermination: File | CustomFile | undefined;
    terminationConfirmation = 'Nein';
    terminationHandInLater = false;

    constructor(private businessFormsStore: BusinessFormsStoreService, private store: Store, private modalService: ModalService) {
        this.getRequestedDocumentsFromSelectedBusinessForm();
        this.getRequestedDocumentsFromSelectedProfession();
        this.noTerminationSettlementCenterList = [
            this.previousSettlementCenterEnum.SELBSTABRECHNER,
            this.previousSettlementCenterEnum.EXISTENZGRUENDER,
        ];
    }

    ngOnInit() {
        this.store
            .select(selectPreviousSettlementCenter)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((settlementCenter) => {
                if (settlementCenter != null) {
                    this.selectedSettlementCenter = { ...settlementCenter };
                    if (settlementCenter.document) {
                        this.uploadTermination = settlementCenter.document.uploadedFile;
                    }
                    if (settlementCenter.termination != null) {
                        this.selectedSettlementCenter.termination = new Date(settlementCenter.termination);
                        this.terminationConfirmation = 'Ja';
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    getRequestedDocumentsFromSelectedBusinessForm() {
        combineLatest([this.store.select(selectIdBusinessForm), this.businessFormsStore.state$])
            .pipe(
                takeUntil(this.unsubscribe$),
                map(([idBusinessForm, businessForms]) => ({
                    idBusinessForm,
                    businessForms,
                }))
            )
            .subscribe(({ idBusinessForm, businessForms }) => {
                const requestedDocuments =
                    businessForms.find((businessForm) => businessForm._id === idBusinessForm)?.requestedDocuments || [];
                this.splitDocuments(requestedDocuments);
            });
    }

    getRequestedDocumentsFromSelectedProfession() {
        this.store
            .select(selectSelectedProfession)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profession) => {
                const requestedDocuments = profession?.requestedDocuments || [];
                this.splitDocuments(requestedDocuments);
            });
    }

    splitDocuments(requestedDocuments: Document[]) {
        let obligatory: Document[] = [];
        let optional: Document[] = [];
        if (requestedDocuments.length > 0) {
            obligatory = requestedDocuments.filter((document) => document.isObligatory);
            optional = requestedDocuments.filter((document) => !document.isObligatory);
        }
        this.obligatoryDocuments = [...this.obligatoryDocuments, ...obligatory];
        this.optionalDocuments = [...this.optionalDocuments, ...optional];
    }

    getDocumentsFilteredByCategory(documents: Document[], filter: DocumentCategoryEnum) {
        return documents.filter((document) => document.documentType.category === filter);
    }

    toggleHandInLater() {
        this.obligatoryDocuments.map((document) => {
            document.handInLater = this.handInLaterAllObligatoryDocuments;
            return document;
        });
    }

    onSelectPreviousSettlementCenter(settlementCenter: string) {
        this.store.dispatch(
            updatePreviousSettlementCenter.updatePreviousSettlementCenter({
                previousSettlementCenter: {
                    ...this.selectedSettlementCenter,
                    name: settlementCenter,
                },
            })
        );
    }

    editCustomSettlementCenter($event: Event) {
        if (this.selectedSettlementCenter) {
            this.store.dispatch(
                updatePreviousSettlementCenter.updatePreviousSettlementCenter({
                    previousSettlementCenter: {
                        ...this.selectedSettlementCenter,
                        customName: ($event.target as HTMLInputElement).value,
                    },
                })
            );
        }
    }

    showInfoDialog($event: MouseEvent) {
        $event.stopPropagation();
        this.modalService.open('Kündigung des bisherigen Abrechnungszentrums', this.terminationInfoTemplate);
    }

    settlementCenterNeedsTermination(): boolean {
        if (this.selectedSettlementCenter != null) {
            if (this.selectedSettlementCenter.name !== '') {
                return !this.noTerminationSettlementCenterList.includes(this.selectedSettlementCenter.name as string);
            }
            return false;
        }
        return true;
    }

    getTerminationMinDate() {
        const today = new Date();
        if (today.getMonth() == 11) {
            return new Date(today.getFullYear() + 1, 0, 1);
        } else {
            return new Date(today.getFullYear(), today.getMonth() + 1, 1);
        }
    }

    terminationChange() {
        if (this.selectedSettlementCenter.termination == null) {
            this.selectedSettlementCenter.termination = this.getTerminationMinDate();
        }
        if (this.selectedSettlementCenter) {
            this.store.dispatch(
                updatePreviousSettlementCenter.updatePreviousSettlementCenter({
                    previousSettlementCenter: {
                        ...this.selectedSettlementCenter,
                    },
                })
            );
        }
    }

    uploadTerminationFile() {
        if (this.uploadTermination) {
            this.store.dispatch(
                updatePreviousSettlementCenter.uploadTerminationFile({
                    file: this.uploadTermination as File,
                })
            );
        }
    }

    terminationFileHandInLater() {
        if (this.selectedSettlementCenter.document) {
            this.selectedSettlementCenter.document.handInLater = this.terminationHandInLater;
        } else {
            this.selectedSettlementCenter.document = {
                _id: '',
                handInLater: this.terminationHandInLater,
                documentType: {} as DocumentType,
            };
        }
    }
}
