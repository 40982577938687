import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Configuration } from '../shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfigStoreService {
    readonly baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.apiUrl;
    }

    loadConfigCode(code: string): Observable<Configuration> {
        return this.http.get<Configuration>(`${this.baseUrl}/configurations/${code}`);
    }
}
