import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'rza-mean-accordion-group',
    templateUrl: './accordion-group.component.html',
    styleUrls: ['./accordion-group.component.scss'],
    animations: [
        //prettier-ignore
        trigger('slideDown', [
            transition(':enter', [
                style({ height: 0 }),
                animate('150ms', style({ height: '*' }))
            ]),
            transition(':leave', [
                style({ height: '*' }),
                animate('150ms', style({ height: 0 }))
            ])
        ]),
    ],
})
export class AccordionGroupComponent {
    @HostBinding('class') classes = 'block';
    @Output() toggle = new EventEmitter<number>();
    @Input() isOpen = false;
    @Input() contentClasses = '';
    isFirst: boolean | undefined;
    isLast: boolean | undefined;
    index!: number;

    toggleOpen() {
        this.isOpen = !this.isOpen;
        this.toggle.emit(this.isOpen ? this.index : -1);
    }
    close() {
        this.isOpen = false;
    }
}
