import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@rza-mean/ui';
import { SharedModule } from '../../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BundleComponent } from './bundle.component';

const routes: Routes = [
    {
        path: '',
        component: BundleComponent,
    },
];

@NgModule({
    declarations: [BundleComponent],
    imports: [CommonModule, RouterModule.forChild(routes), UiModule, SharedModule, FontAwesomeModule],
})
export class BundleModule {}
