import { Discount } from '../../shared/interfaces';

export interface DiscountState {
    discounts: Map<string, Discount>;
    loading: boolean;
}

export const initialDiscountState: DiscountState = {
    discounts: new Map(),
    loading: false,
};
