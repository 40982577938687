import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'rza-mean-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor {
    @Input() label = '';
    @Input() readOnly = false;
    @Input() uncheckedClasses: string | undefined;
    @Input() checkedClasses: string | undefined;
    @Input() classes = 'item-center';
    @Input() selected = false;
    @Input() withBorder = true;

    disabled = false;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: any = (val: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouch: any): void {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    change($event: Event) {
        this.onChange(($event.target as HTMLInputElement).checked);
    }

    writeValue(value: boolean) {
        this.selected = value;
    }
}
