import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './components/section/section.component';
import { CardComponent } from './components/card/card.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DropdownDirectiveModule } from './directives/dropdown/dropdown.directive';
import { InputComponent } from './components/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SliderComponent } from './components/slider/slider.component';
import { TooltipDirectiveModule } from './directives/tooltip/tooltip.directive';
import { ToggleComponent } from './components/toggle/toggle.component';
import { RadioComponent } from './components/radio/radio.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { InfoBtnComponent } from './components/info-btn/info-btn.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionGroupComponent } from './components/accordion/accordion-group/accordion-group.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SelectComponent } from './components/select/select.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LabelComponent } from './components/label/label.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GraphicComponent } from './components/graphic/graphic.component';
import { TitleComponent } from './components/title/title.component';
import { SelectableDirective } from './directives/selectable/selectable.directive';
import { StopPropagationDirective } from './directives/stop-propagation/stop-propagation.directive';
import { ListBulletDirective } from './directives/list-bullet/list-bullet.directive';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { SmallPrintComponent } from './components/small-print/small-print.component';
import { InnerHtmlLinkDirective } from './directives/inner-html-link/inner-html-link.directive';
import { ShinyDirective } from './directives/shiny/shiny.directive';
import { TypeaheadDirective } from './directives/typeahead/typeahead.directive';
import { HighlightPipe } from './misc/highlight.pipe';

@NgModule({
    imports: [CommonModule, DropdownDirectiveModule, ReactiveFormsModule, TooltipDirectiveModule, FormsModule, FontAwesomeModule],
    declarations: [
        SectionComponent,
        CardComponent,
        InputComponent,
        SidebarComponent,
        SliderComponent,
        ToggleComponent,
        CheckboxComponent,
        RadioComponent,
        InfoBtnComponent,
        AccordionComponent,
        AccordionGroupComponent,
        DatepickerComponent,
        SelectComponent,
        FileUploadComponent,
        LabelComponent,
        GraphicComponent,
        TitleComponent,
        SelectableDirective,
        StopPropagationDirective,
        ListBulletDirective,
        InfoBoxComponent,
        TextAreaComponent,
        SmallPrintComponent,
        InnerHtmlLinkDirective,
        ShinyDirective,
        TypeaheadDirective,
        HighlightPipe,
    ],
    exports: [
        SectionComponent,
        CardComponent,
        InputComponent,
        SidebarComponent,
        DropdownDirectiveModule,
        SliderComponent,
        TooltipDirectiveModule,
        ToggleComponent,
        CheckboxComponent,
        RadioComponent,
        InfoBtnComponent,
        AccordionComponent,
        AccordionGroupComponent,
        DatepickerComponent,
        SelectComponent,
        FileUploadComponent,
        TitleComponent,
        LabelComponent,
        SelectableDirective,
        StopPropagationDirective,
        ListBulletDirective,
        InnerHtmlLinkDirective,
        InfoBoxComponent,
        GraphicComponent,
        TextAreaComponent,
        SmallPrintComponent,
        ShinyDirective,
        TypeaheadDirective,
        HighlightPipe,
    ],
})
export class UiModule {}
