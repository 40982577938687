import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoftwareComponent } from './software.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '@rza-mean/ui';

const routes: Routes = [
    {
        path: '',
        component: SoftwareComponent,
    },
];

@NgModule({
    declarations: [SoftwareComponent],
    imports: [CommonModule, RouterModule.forChild(routes), SharedModule, UiModule],
})
export class SoftwareModule {}
