import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[rzaMeanShiny]',
})
export class ShinyDirective {
    @HostBinding('class') classes = 'shiny';

    constructor(private elementRef: ElementRef) {}

    @HostListener('mousemove', ['$event'])
    public onClick(event: MouseEvent): void {
        const nativeElement = this.elementRef.nativeElement;
        const { x, y } = nativeElement.getBoundingClientRect();
        nativeElement.style.setProperty('--x', (event.clientX - x).toString());
        nativeElement.style.setProperty('--y', (event.clientY - y).toString());
    }
}
