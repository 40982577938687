<rza-mean-page>
    <rza-mean-section>
        <rza-mean-title
            headline="Bestimmen Sie Ihre <br> Auszahlungs&shy;geschwindigkeit"
            subHeadline="Sie können die Auszahlungsgeschwindigkeit und somit auch Ihre Vorfinanzierungsgebühr noch nachträglich <b>flexibel anpassen.</b>"
        ></rza-mean-title>
    </rza-mean-section>

    <div class="flex flex-grow flex-col justify-center">
        <rza-mean-section>
            <div class="grid grid-cols-1 gap-12 md:grid-cols-2" [ngClass]="'2xl:grid-cols-' + (prefinancings$ | async)?.length">
                <rza-mean-card
                    rzaMeanSelectable
                    selectionStyleClasses="primary-selected"
                    selectionType="radio"
                    *ngFor="let prefinancing of prefinancings$ | async"
                    (selected)="selectPrefinancing($event, prefinancing)"
                    [isSelected]="selectedPrefinancingId === prefinancing._id"
                    absoluteHeaderClasses="!h-auto"
                    alignment="center"
                >
                    <ng-container *ngIf="prefinancing.modalContent?.content">
                        <ng-template #info>
                            <div class="text-primary font-medium">Information:</div>
                            <div rzaMeanListBullet [innerHTML]="prefinancing.modalContent?.content"></div>
                        </ng-template>
                    </ng-container>

                    <ng-container *ngIf="prefinancing.paymentSpeed">
                        <ng-template #header>
                            <div
                                class="relative flex flex-grow justify-center pt-2 text-center text-[5rem] font-semibold leading-none text-white xl:text-[10rem]"
                            >
                                <span>
                                    {{ prefinancing.paymentSpeed | paymentSpeed : true }}
                                    <span *ngIf="prefinancing.paymentSpeed.value === '0'" class="absolute top-2 text-sm text-white">{{
                                        getSmallPrints(prefinancing.paymentSpeed.smallPrint)
                                    }}</span>
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-template #body>
                        <div *ngIf="prefinancing.paymentSpeed as paymentSpeed" class="relative pb-2 font-semibold text-white">
                            <div *ngIf="paymentSpeed.value !== '0'">
                                <span class="text-2xl">
                                    {{ prefinancing.paymentSpeed.unit }}
                                    <sup class="absolute top-5 text-sm">{{ getSmallPrints(prefinancing.paymentSpeed.smallPrint) }}</sup>
                                </span>
                            </div>
                        </div>

                        <div *ngFor="let price of getPrices$(prefinancing) | async">
                            <p class="font-semibold text-white">
                                {{ price | price }}
                                <sup *ngIf="price.smallPrint?.length" class="text-xs">{{ getSmallPrints(price.smallPrint) }}</sup>
                            </p>
                            <p class="text-white">{{ price.name }}</p>
                        </div>
                    </ng-template>
                </rza-mean-card>
            </div>
        </rza-mean-section>

        <rza-mean-section>
            <div class="grid grid-cols-1 gap-x-12 gap-y-4 pt-8 md:grid-cols-2">
                <rza-mean-small-print class="max-md:order-1"></rza-mean-small-print>
                <rza-mean-info-box class="basis-80">
                    <div *ngIf="(professionBranch$ | async)?.identifier !== 'HEIMI'">
                        Die opta data zahlt Ihnen stets den Bruttowert des Forderungsbetrages aus. Abgezogen werden Eigenanteile,
                        Korrekturen vorausgehender Abrechnungen sowie das Honorar für eingereichte Belege.
                    </div>
                    <div *ngIf="(professionBranch$ | async)?.identifier === 'HEIMI'">
                        Die opta data zahlt dem Kunden jeweils den fälligen Forderungsbetrag, gleich Bruttoverordnungswert, abzüglich
                        Eigenanteilen, Korrekturen vorangegangener Abrechnungen und des Honorars durch Überweisung aus.
                    </div>
                </rza-mean-info-box>
            </div>
        </rza-mean-section>
    </div>

    <rza-mean-page-navigation [disabled]="!selectedPrefinancingId"></rza-mean-page-navigation>
</rza-mean-page>
