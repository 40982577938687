import { Component, HostBinding, OnDestroy } from '@angular/core';
import { combineLatestWith, filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Price, Product, ProfessionBranch } from '../../shared/interfaces';
import { SmallPrintService } from '@rza-mean/ui';
import { PriceDependencyTypeEnum, RevenueTypeEnum } from '@rza-mean/api-interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { selectIsStartUpConfirmed } from '../../store/configuration/configuration.selectors';
import { Store } from '@ngrx/store';
import { setIsStartUpConfirmed } from '../../store/configuration/configuration.actions';
import { selectAvailableSettlementProducts, selectSelectedProfession } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-configurator',
    templateUrl: './configurator.component.html',
    styleUrls: ['./configurator.component.scss'],
})
export class ConfiguratorComponent implements OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    settlementProducts$!: Observable<Product[]>;
    professionBranch$!: Observable<ProfessionBranch>;
    minPrices$!: Observable<{ productName: string; minPrices: Price[] }[]>;
    subTitle!: string;
    startupFormKey = 'startUpLessThanSixMonth';
    private unsubscribe$ = new Subject();
    confirmStartUpForm: FormGroup;

    constructor(private store: Store, private smallPrintService: SmallPrintService, private fb: FormBuilder) {
        this.professionBranch$ = this.store.select(selectSelectedProfession).pipe(
            takeUntil(this.unsubscribe$),
            map((profession) => profession.professionBranch)
        );

        this.settlementProducts$ = this.store.select(selectAvailableSettlementProducts).pipe(
            filter((products) => products.length > 0),
            tap((products) => {
                this.subTitle =
                    products[0].revenue?.type === RevenueTypeEnum.PATIENT
                        ? `Bitte geben Sie die <b>monatliche Anzahl Ihrer Patient:innen</b> sowie Ihren <b>durchschnittlichen Monatsumsatz</b> an. Als Existenzgründer:in können Sie die voreingestellten Werte übernehmen oder sich an den Werten Ihres Businessplans orientieren.`
                        : products[0].revenue?.type === RevenueTypeEnum.RECEIPT
                        ? `Bitte geben Sie die <b>monatliche Anzahl Verordnungen</b> sowie den <b>durchschnittlichen Verordnungswert</b> an. Als Existenzgründer:in können Sie die voreingestellten Werte übernehmen oder sich an den Werten Ihres Businessplans orientieren.`
                        : '';
            }),
            takeUntil(this.unsubscribe$)
        );

        this.confirmStartUpForm = this.fb.group({
            [this.startupFormKey]: [false],
        });

        this.minPrices$ = this.settlementProducts$.pipe(
            combineLatestWith(this.store.select(selectIsStartUpConfirmed)),
            map(([products, isStartupConfirmed]) => {
                return products
                    .filter((product) => !product.forRevenueOnly)
                    .reduce((acc, { prices, dependentPrices, name }) => {
                        const settlementPrice =
                            dependentPrices?.find(
                                (price) =>
                                    price.type === PriceDependencyTypeEnum.STARTUP && String(isStartupConfirmed) === price.referenceId
                            )?.price ?? prices?.find((price) => price.billingType.name === 'settlement');
                        if (settlementPrice && (settlementPrice.minPrice || settlementPrice.minPricePerUnit)) {
                            acc.push({
                                productName: name,
                                minPrices: [settlementPrice?.minPrice, settlementPrice?.minPricePerUnit].filter(
                                    (_) => _ != null
                                ) as Price[],
                            });
                        }
                        return acc;
                    }, [] as { productName: string; minPrices: Price[] }[]);
            })
        );

        this.store
            .select(selectIsStartUpConfirmed)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((confirmed) => {
                this.confirmStartUpForm.patchValue({ startUpLessThanSixMonth: confirmed });
            });

        this.confirmStartUpForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            this.store.dispatch(setIsStartUpConfirmed({ isStartUpConfirmed: value.startUpLessThanSixMonth }));
        });
    }

    ngOnDestroy(): void {
        this.smallPrintService.reset();
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
