import { createReducer, on } from '@ngrx/store';
import { initialProfessionState } from './profession.state';
import * as ProfessionActions from './profession.actions';
import { setSelectedProfessionBranch } from './profession.actions';
import produce from 'immer';
import { MinimalProfessionBranch } from '../../shared/interfaces';

export const professionReducer = createReducer(
    initialProfessionState,
    on(ProfessionActions.MinimalProfessionActions.loadSuccess, (state, { minimalProfessions }) =>
        produce(state, (newState) => {
            newState.professionBranches = minimalProfessions.reduce((acc, curr) => {
                const foundBranch = acc.find((_) => _.name === curr.professionBranch.name);
                if (!foundBranch) {
                    acc.push({
                        name: curr.professionBranch.name,
                        iconFile: curr.professionBranch.iconFile,
                    });
                }
                return acc;
            }, [] as MinimalProfessionBranch[]);
            newState.minimalProfessions = minimalProfessions;
        })
    ),
    on(ProfessionActions.ProfessionActions.loadSuccess, (state, { profession }) =>
        produce(state, (newState) => {
            newState.selectedProfession = profession;
            newState.selectedProfessionBranch = profession.professionBranch.name;
        })
    ),
    on(setSelectedProfessionBranch, (state, { branch }) =>
        produce(state, (newState) => {
            newState.selectedProfessionBranch = branch;
        })
    )
);
