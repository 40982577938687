import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
    transform(value: string, search: string, splitSpaces = false, highlightClass: string = 'bg-secondary-light'): unknown {
        if (!search) {
            return value;
        }

        let regex;
        if (splitSpaces) {
            regex = new RegExp(search.trim().split(' ').join('.*'), 'i');
        } else {
            regex = new RegExp(search, 'i');
        }
        const match = regex.exec(value);

        if (!match) {
            return value;
        }

        return value.replace(regex, `<span class="${highlightClass}">${match[0]}</span>`);
    }
}
