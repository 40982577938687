<div
    class="gap-6"
    [ngClass]="{ 'bg-white': !bgColor, 'hidden lg:flex': hiddeOnSmallDevices }"
    [ngStyle]="{ 'background-color': bgColor ?? '' }"
>
    <div class="bg-primary-lighter relative w-[5px]">
        <div #progress class="bg-primary progress relative z-10 h-full"></div>
        <div #totalProgress class="bg-primary-light progress absolute left-0 top-0 z-0 h-full"></div>
    </div>
    <ol class="relative flex max-w-[180px] list-none flex-col justify-around">
        <li
            *ngFor="let breadcrumb of breadcrumbs$ | async; let index = index; let last = last; let first = first"
            #breadcrumbElement
            class="text-lg"
        >
            <a
                *ngIf="index < currentIndex || breadcrumb.visited; else noLink"
                (click)="setPage(breadcrumb)"
                class="hover:text-secondary cursor-pointer underline"
                [innerHTML]="breadcrumb.title.replace('/', '/&#8203;')"
            ></a>

            <ng-template #noLink>
                <span [innerHTML]="breadcrumb.title.replace('/', '/&#8203;')"></span>
            </ng-template>
        </li>
    </ol>
</div>

<div hidden>
    <div class="hidden lg:flex"></div>
</div>
