<form [formGroup]="companyForm" class="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
    <div>
        <rza-mean-input formControlName="name" label="Firmenname/Name*"></rza-mean-input>
    </div>
    <div>
        <rza-mean-select
            formControlName="businessForm"
            label="Unternehmensform*"
            [options]="businessForms"
            keyToDisplay="name"
            selectId="businessForms"
        ></rza-mean-select>
    </div>
</form>
