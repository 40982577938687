<div class="flex flex-col">
    <div class="order-1">
        <div class="mb-2">
            <ng-container *ngIf="isReceipt || isReceiptRevenue">
                <span *ngIf="!isAdditionalPayment">Durchschnittliche mtl. Anzahl Verordnungen:</span>
                <span *ngIf="isAdditionalPayment"
                    >Durchschnittliche mtl. Anzahl Verordnungen, für die opta data eine Zuzahlungsabrechnung erstellen soll:</span
                >
            </ng-container>
            <ng-container *ngIf="isPatient">Durchschnittliche mtl. Anzahl an Patient:innen:</ng-container>
        </div>
        <rza-mean-input
            class="mb-3"
            size="sm"
            [(ngModel)]="disableInputs ? fakeCount : count"
            (input)="onCountChange()"
            (click)="$event.stopPropagation()"
            ngDefaultControl
            [indicateValidity]="false"
            inputClasses="!border-white"
            type="number"
            [max]="product.revenue?.maxCount || 100"
            [min]="minCount"
            [disabled]="disableInputs"
            data-cy="countInput"
        ></rza-mean-input>
        <rza-mean-slider
            [(ngModel)]="disableInputs ? fakeCount : count"
            (mouseup)="onCountChange()"
            (click)="!disableInputs ? $event.stopPropagation() : null"
            ngDefaultControl
            [max]="product.revenue?.maxCount || 100"
            [min]="minCount"
            [disabled]="disableInputs"
            data-cy="countSlider"
        ></rza-mean-slider>
    </div>

    <div
        *ngIf="((isReceipt && product.revenue?.itemValue != null) || isReceiptRevenue) && !isAdditionalPayment"
        [ngClass]="{ 'order-2 mb-5': !isReceiptRevenue, 'order-3': isReceiptRevenue }"
    >
        <div class="mb-2">Durchschnittlicher Verordnungswert:</div>
        <div *ngIf="isReceiptRevenue">{{ receiptValue | number : '1.2-2' : 'de' }} €</div>
        <div *ngIf="isReceipt" class="">
            <rza-mean-input
                [(ngModel)]="disableInputs ? fakeItemValue : itemValue"
                (input)="onReceiptValueChange()"
                (click)="$event.stopPropagation()"
                ngDefaultControl
                [indicateValidity]="false"
                inputClasses="!border-white"
                type="number"
                textAppend="€"
                [textAppendClasses]="'bg-' + getProductColor(product) + '-lighter'"
                size="sm"
                [disabled]="disableInputs"
                data-cy="receiptValueInput"
            ></rza-mean-input>
        </div>
    </div>

    <div *ngIf="!isAdditionalPayment" [ngClass]="{ 'order-2 mb-5': isReceiptRevenue, 'order-3': !isReceiptRevenue }">
        <div class="mb-2">Durchschnittlicher mtl. Umsatz:</div>
        <div *ngIf="isReceipt">{{ revenue | number : '1.2-2' : 'de' }} €</div>
        <div *ngIf="isPatient || isReceiptRevenue">
            <rza-mean-input
                [(ngModel)]="disableInputs ? fakeValue : value"
                (input)="onRevenueValueChange()"
                (click)="$event.stopPropagation()"
                ngDefaultControl
                [indicateValidity]="false"
                inputClasses="!border-white"
                type="number"
                textAppend="€"
                [textAppendClasses]="'bg-' + getProductColor(product) + '-light'"
                size="sm"
                [disabled]="disableInputs"
                data-cy="totalRevenueInput"
            ></rza-mean-input>
        </div>
    </div>
</div>
