import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationComponent } from './verification.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '@rza-mean/ui';

const routes: Routes = [
    {
        path: '',
        component: VerificationComponent,
    },
];

@NgModule({
    declarations: [VerificationComponent],
    imports: [CommonModule, RouterModule.forChild(routes), SharedModule, UiModule],
})
export class VerificationModule {}
