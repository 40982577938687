import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectAllConfiguredSettlementProducts,
    selectAllProductsByCategory,
    selectConfig,
    selectConfiguredBundleId,
} from '../../store/configuration/configuration.selectors';
import { combineLatestWith, filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { Bundle, ConfiguredProfession, Discount, Price, Product, Promotion } from '../../shared/interfaces';
import { ProductCategoryEnum, ProductTypeEnum } from '@rza-mean/api-interfaces';
import { CalculationService, TotalPricePerPriceType } from '../../services/calculation.service';
import { BillingTypeStoreService } from '../../services/billing-type-store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../../../environments/environment';
import { PricePipe } from '../../pipes/price.pipe';
import { PaymentSpeedPipe } from '../../pipes/payment-speed.pipe';
import { selectPromotedBundles } from '../../store/profession/profession.selectors';
import { ProfessionService } from '../../services/profession.service';
import { DiscountService } from '../../services/discount.service';

interface CategoryList {
    billingTypeName: string;
    idBillingType: string;
    subCategory: {
        header: string;
        categoryIndex: number;
        flag?: string;
        items: CategoryListItem[];
    }[];
}

interface CategoryListItem {
    name: string;
    price: string;
    icon?: IconProp;
    pricePrefix?: string;
    tooltip?: string;
    idPrice?: number;
    orderPriority?: number;
}

@Component({
    selector: 'rza-mean-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    providers: [PricePipe],
})
export class SummaryComponent implements OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    config$: Observable<ConfiguredProfession | undefined>;
    bundle$: Observable<Bundle | undefined>;
    productsByCategory!: Record<string, Product[]>;
    summaryList$: Observable<CategoryList[]>;
    totalPrice$: Observable<{ price: number; discount: number }>;
    totalPricePerPriceType$: Observable<TotalPricePerPriceType>;
    isOverviewOpen = false;
    openOnPrint = false;
    discountCodeForm: FormGroup;
    showToolTipForTotalPercentPrice = false;

    totalPriceTooltip = `<ul class="mt-0">
        <li>Der monatliche Preis kann je nach Abrechnungsvolumen variieren. Rückläufer, Korrekturen und Einreichungsverhalten können sich auf den Preis auswirken.</li>
        <li>Alle genannten Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer.</li>
    </ul>`;

    totalPriceTooltipRevenueOnly = `<ul class="mt-0">
        <li>Der monatliche Preis kann je nach Abrechnungsvolumen variieren. Rückläufer, Korrekturen und Einreichungsverhalten können sich auf den Preis auswirken.</li>
        <li>Bei diesem Tarif handelt es sich um eine Finanzdienstleistung nach §4 Nr. 8c UStG, weshalb sich die prozentuale Vorfinanzierungsgebühr ohne gesetzliche Mehrwertsteuer versteht.</li>
    </ul>`;

    totalPricePerPriceTypeTooltip = `<ul class="mt-0">
        <li>In Prozent ausgewiesene Preise berechnen sich immer auf Basis des jeweiligen Bruttobelegwerts.</li>
        <li>Alle genannten Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer.</li>
    </ul>`;
    totalPricePerPriceTypeTooltipRevenueOnly = `<ul class="mt-0">
        <li>In Prozent ausgewiesene Preise berechnen sich immer auf Basis des jeweiligen Bruttobelegwerts.</li>
        <li><i>Bei diesem Tarif handelt es sich um eine Finanzdienstleistung nach §4 Nr. 8c UStG, weshalb sich die prozentuale Vorfinanzierungsgebühr ohne gesetzliche Mehrwertsteuer versteht.</i></li>
    </ul>`;
    totalPercentPriceToolTip = 'Der Preis für die Privatabrechnung unterscheidet sich von dem hier angegebenen Preis.';

    private unsubscribe$ = new Subject();
    readonly pdfUrl = environment.apiUrl + '/sessions/print/pdf';

    private paymentSpeedPipe = new PaymentSpeedPipe();

    constructor(
        private store: Store,
        private pricePipe: PricePipe,
        private calculationService: CalculationService,
        private billingTypeStore: BillingTypeStoreService,
        private fb: FormBuilder,
        private professionService: ProfessionService,
        private discountService: DiscountService
    ) {
        this.config$ = this.store.select(selectConfig).pipe(map((config) => config.configuredProfession));
        this.bundle$ = this.store.select(selectConfiguredBundleId).pipe(
            combineLatestWith(this.store.select(selectPromotedBundles)),
            map(([bundleId, promotedBundles]) => ({
                bundleId,
                promotedBundles,
            })),
            map(({ bundleId, promotedBundles }) => promotedBundles?.bundles.find((bundle) => bundle._id === bundleId))
        );
        // missing order in Price
        // this.basicFees$ = this.professionStore.getBasicFees();
        this.summaryList$ = this.getSummaryList();
        this.getProductsByCategory();
        this.totalPrice$ = this.calculationService.calculatePrice$();
        this.totalPricePerPriceType$ = this.calculationService.getTotalPricePerPriceType();
        this.discountCodeForm = this.fb.group({
            code: ['', [Validators.required, Validators.minLength(4)]],
        });
        this.store
            .select(selectAllConfiguredSettlementProducts)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((products) => {
                if (products.length > 1) {
                    const settlements: Product[] = [];
                    products.forEach((product) => {
                        if (settlements.length) {
                            const samePriceUnitAlreadyInProducts = settlements.find((prod) => {
                                if (prod.prices && product.prices) {
                                    return (
                                        prod.prices[0].value !== product.prices[0].value && prod.prices[0].unit === product.prices[0].unit
                                    );
                                }
                                return false;
                            });
                            if (samePriceUnitAlreadyInProducts) {
                                settlements.push(product);
                            }
                        } else {
                            settlements.push(product);
                        }
                    });
                    this.showToolTipForTotalPercentPrice = settlements.length > 1;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    getProductPriceAsString(product: Product): string {
        if (product.prices && product.prices?.length > 0) {
            return this.pricePipe.transform(product.prices[0]);
        }
        return '';
    }

    getStyledTitle(promotion?: Promotion) {
        return this.professionService.getStyledPromotionTitle(promotion);
    }

    getSummaryList(): Observable<CategoryList[]> {
        const billingTypes$ = this.billingTypeStore.state$.pipe(
            takeUntil(this.unsubscribe$),
            filter((types) => types.length > 0)
        );

        return billingTypes$.pipe(
            map((types) => {
                const summaryList: CategoryList[] = [];
                types.forEach((type) => {
                    summaryList.push({
                        billingTypeName: type.altName,
                        idBillingType: type._id,
                        subCategory: [],
                    });
                });
                summaryList.sort((a, b) => {
                    let compare = 0;
                    if (a.billingTypeName > b.billingTypeName) {
                        compare = 1;
                    } else if (a.billingTypeName < b.billingTypeName) {
                        compare = -1;
                    }
                    return compare;
                });
                return summaryList;
            }),
            combineLatestWith(this.config$.pipe(takeUntil(this.unsubscribe$))),
            map(([summaryList, config]) => ({
                summaryList,
                config,
            })),
            map(({ summaryList, config }) => {
                summaryList.map((listItem) => {
                    listItem.subCategory = [];
                    return listItem;
                });
                config?.products.forEach((product) => {
                    product.prices?.forEach((price) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const listIndex = summaryList.findIndex((item) => item.idBillingType === price.billingType._id);
                        const header = this.getSubCategoryHeader(product);
                        const item: CategoryListItem = {
                            name: this.getListItemName(product, price),
                            price: this.pricePipe.transform(price),
                            orderPriority: product.orderPriority,
                        };
                        const tooltips: string[] = [];
                        if (price.smallPrint?.length) {
                            tooltips.push(...price.smallPrint);
                        }
                        if (price.minPrice && Object.keys(price.minPrice).length) {
                            const minPrice = price.minPrice;
                            tooltips.push(`${minPrice.name}: ${this.pricePipe.transform(minPrice)}`);
                        }
                        if (price.minPricePerUnit && Object.keys(price.minPricePerUnit).length) {
                            const minPricePerUnit = price.minPricePerUnit;
                            tooltips.push(`${minPricePerUnit.name}: ${this.pricePipe.transform(minPricePerUnit)}`);
                        }
                        if (tooltips.length) {
                            if (tooltips.length > 1) {
                                item.tooltip = '<ul class="mt-0"><li>';
                                item.tooltip += tooltips.join('</li><li>');
                                item.tooltip += '</li></ul>';
                            } else {
                                item.tooltip = tooltips[0];
                            }
                        }
                        this.addItemToList(listIndex, summaryList, header, item, product.category as string);
                    });
                    product.products?.forEach((subProduct) => {
                        subProduct.prices?.forEach((price) => {
                            const listIndex = summaryList.findIndex(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (item) => item.idBillingType === price.billingType._id
                            );
                            const header = this.getSubCategoryHeader(product);
                            const item: CategoryListItem = {
                                name: this.getListItemName(subProduct, price),
                                price: this.pricePipe.transform(price),
                            };
                            if (price.smallPrint?.length) {
                                if (price.smallPrint?.length > 1) {
                                    item.tooltip = '<ul class="mt-0"><li>';
                                    item.tooltip += price.smallPrint.join('</li><li>');
                                    item.tooltip += '</li></ul>';
                                } else {
                                    item.tooltip = price.smallPrint[0];
                                }
                            }
                            this.addItemToList(listIndex, summaryList, header, item, product.category as string);
                        });
                    });
                });
                return {
                    summaryList,
                    config,
                };
            }),
            combineLatestWith(this.store.select(selectPromotedBundles).pipe(takeUntil(this.unsubscribe$))),
            map(([{ summaryList, config }, promotedBundles]) => {
                config?.discounts
                    // ?.filter((discount) => discount.isVisible)
                    ?.reduce((map, discount, index) => {
                        if (discount.isInBundle) {
                            const correspondingBundleName =
                                promotedBundles?.bundles.find((bundle) => bundle.discounts?.find((d) => d._id === discount._id))?.name ||
                                `bundle${index}`;
                            map.set(correspondingBundleName, discount);
                        } else {
                            map.set(discount.name, discount);
                        }
                        return map;
                    }, new Map<string, Discount>())
                    .forEach((discount) => {
                        const listIndex = summaryList.findIndex((item) => item.idBillingType === discount.price?.billingType._id);
                        const header = '';
                        let correspondingBundle;
                        if (discount.isInBundle) {
                            correspondingBundle = promotedBundles?.bundles.find((bundle) =>
                                bundle.discounts?.find((d) => d._id === discount._id)
                            );
                        }
                        const item: CategoryListItem = {
                            name: correspondingBundle ? this.getStyledTitle(correspondingBundle.promotion) : discount.name,
                            price: correspondingBundle ? '' : this.pricePipe.transform(discount.price),
                            icon: correspondingBundle ? 'check' : undefined,
                        };
                        if (!discount.isInBundle && discount.price?.smallPrint && discount.price?.smallPrint.length) {
                            item.tooltip = discount.price.smallPrint.join('<br>');
                        }
                        if (listIndex > -1) {
                            const subCategory = summaryList[listIndex]?.subCategory.find(
                                (subCategoryItem) => subCategoryItem.header === header
                            );
                            if (subCategory) {
                                subCategory.items.push(item);
                            } else {
                                summaryList[listIndex].subCategory.push({
                                    header,
                                    categoryIndex: 111,
                                    items: [item],
                                });
                            }
                        }
                    });
                return {
                    summaryList,
                    config,
                };
            }),
            map(({ summaryList }) => {
                const list = summaryList
                    .filter((list) => list.subCategory.some((subCategory) => subCategory.items.length > 0))
                    .map((list) => {
                        list.subCategory.sort((a, b) => {
                            return a.categoryIndex - b.categoryIndex;
                        });
                        return list;
                    })
                    .map((list) => {
                        list.subCategory.map((subCategory) =>
                            subCategory.items.sort((a, b) => {
                                if (a.orderPriority != null && b.orderPriority != null) {
                                    return a.orderPriority - b.orderPriority;
                                }
                                return 0;
                            })
                        );
                        return list;
                    });
                return list;
            })
        );
    }

    private addItemToList(listIndex: number, summaryList: CategoryList[], header: string, item: CategoryListItem, category: string) {
        if (listIndex > -1) {
            const subCategory = summaryList[listIndex]?.subCategory.find((subCategoryItem) => subCategoryItem.header === header);
            if (subCategory) {
                subCategory.items.push(item);
            } else {
                summaryList[listIndex].subCategory.push({
                    header,
                    categoryIndex: (Object.values(ProductCategoryEnum) as string[]).indexOf(category),
                    items: [item],
                });
            }
        }
    }

    private getListItemName(product: Product, price: Price): string {
        if (product.type === ProductTypeEnum.PREFINANCING) {
            return `Vorfinanzierungsgebühr: ${this.paymentSpeedPipe.transform(product.paymentSpeed)}`;
        }
        if (product.prices && product.prices.length > 1 && price.name) {
            return `${product.name} - ${price.name}`;
        }
        return product.name;
    }

    private getSubCategoryHeader(product: Product): string {
        if (product.category) {
            return (
                [
                    ProductCategoryEnum.SETTLEMENT,
                    ProductCategoryEnum.GKV,
                    ProductCategoryEnum.PRIVATE,
                    ProductCategoryEnum.ADDITIONAL_PAYMENT,
                ] as string[]
            ).includes(product.category as string)
                ? 'Abrechnungs-Leistungen'
                : `${product.category}-Leistungen`;
        }
        return 'Sonstige Leistungen';
    }

    getProductsByCategory() {
        this.store
            .select(
                selectAllProductsByCategory([
                    ProductCategoryEnum.ACTIVESERVICE,
                    ProductCategoryEnum.ACTIVEPROTECTION,
                    ProductCategoryEnum.ACTIVEREPORT,
                    ProductCategoryEnum.SOFTWARE,
                ])
            )
            .pipe(
                filter((products) => products != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((products) => {
                this.productsByCategory =
                    products?.reduce((acc, curr) => {
                        const cat = curr.category as string;
                        acc[cat] = acc[cat] || [];
                        acc[cat].push(curr);
                        return acc;
                    }, {} as Record<string, Product[]>) || {};
            });
    }

    categoryHasProducts(products: Product[] | null | undefined): boolean {
        return Boolean(products?.length);
    }

    scrollTo($event: MouseEvent, query: string) {
        $event.preventDefault();
        const anchor: HTMLElement = document.querySelector(query) as HTMLElement;
        if (anchor) {
            window.scrollTo({
                top: anchor.offsetTop,
            });
        }
    }

    print() {
        this.openOnPrint = true;
        this.isOverviewOpen = true;
        setTimeout(() => {
            window.print();
        }, 0);
    }

    loadDiscountCode() {
        if (this.discountCodeForm.valid) {
            this.discountService.loadDiscountCode(this.discountCodeForm.value.code).subscribe(() => {
                this.discountCodeForm.controls['code'].setValue('');
            });
        }
    }

    isBottomColumnItem(index: number, column: number, items: any[]): boolean {
        const itemsInColumn = items.filter((_, i) => i % 3 === column);
        const lastIndexInColumn = items.findIndex((_, i) => i === (itemsInColumn.length - 1) * 3 + column);
        return index === lastIndexInColumn;
    }

    protected readonly Object = Object;
}
