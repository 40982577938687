<rza-mean-page>
    <rza-mean-section>
        <rza-mean-title
            headline="Volle Preis&shy;transparenz: <br> Lassen Sie Ihre monatlichen Kosten berechnen"
            [subHeadline]="subTitle"
        ></rza-mean-title>
    </rza-mean-section>

    <div class="flex flex-grow flex-col justify-center">
        <rza-mean-section>
            <div class="grid grid-cols-1 gap-x-12 gap-y-20 md:grid-cols-2 2xl:grid-cols-3">
                <rza-mean-product-card *ngFor="let product of settlementProducts$ | async" [product]="product"></rza-mean-product-card>

                <rza-mean-info-box
                    class="place-self-center transition-all"
                    *ngIf="(minPrices$ | async)?.length && (minPrices$ | async) as minPrices"
                >
                    <div *ngFor="let minPrice of minPrices" class="mb-3 last:mb-0">
                        <div class="font-medium">{{ minPrice.productName }}:</div>
                        <div *ngFor="let price of minPrice.minPrices">
                            {{ price.name }}: {{ price | price }}
                            <ul *ngIf="price.smallPrint?.length">
                                <li *ngFor="let print of price.smallPrint">{{ print }}</li>
                            </ul>
                        </div>
                    </div>
                </rza-mean-info-box>

                <rza-mean-card
                    *ngIf="(professionBranch$ | async)?.identifier === 'TRARETT'"
                    class="row-start-2"
                    headerText="Existenzgründer?"
                    headerClasses="text-white !text-lg"
                >
                    <ng-template #body>
                        <form [formGroup]="confirmStartUpForm" class="mb-3">
                            <rza-mean-checkbox
                                [formControlName]="startupFormKey"
                                checkedClasses="text-white"
                                classes="text-white"
                                label="Ich bin Existenzgründer und führe mein Unternehmen seit weniger als sechs Monaten."
                            >
                            </rza-mean-checkbox>
                        </form>
                    </ng-template>
                </rza-mean-card>
            </div>
        </rza-mean-section>

        <rza-mean-section>
            <rza-mean-small-print></rza-mean-small-print>
        </rza-mean-section>
    </div>

    <rza-mean-page-navigation></rza-mean-page-navigation>
</rza-mean-page>
