import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GraphicsCacheService } from '../../services/graphics-cache.service';

@Component({
    selector: 'rza-mean-graphic',
    templateUrl: './graphic.component.html',
    styleUrls: ['./graphic.component.scss'],
})
export class GraphicComponent implements OnInit, AfterViewInit {
    @Input() image: string | undefined;
    @Input() width: string = '100%';
    @Input() height: string = 'auto';
    @Input() alt: string | undefined;
    @Input() base64Image: string | undefined;
    @Input() classes: string = '';
    @Output() loaded = new EventEmitter();

    @ViewChild('svg') svgWrapper: ElementRef | undefined;

    public isSVG = false;

    constructor(
        private http: HttpClient,
        private elementRef: ElementRef,
        private cache: GraphicsCacheService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.height = this.height + (this.height.toString().match(/^\d+$/) ? 'px' : '');
        this.width = this.width + (this.width.toString().match(/^\d+$/) ? 'px' : '');
    }

    ngAfterViewInit(): void {
        if (this.height) {
            this.elementRef.nativeElement.style.minHeight = this.height; // + (!this.height.toString().match(/\D+/) ? 'px' : '');
        }
        if (this.checkSVG()) this.getSVG();
        this.changeDetector.detectChanges();
    }

    private checkSVG() {
        return (this.isSVG = this.image?.toLowerCase().split('.').pop() === 'svg');
    }

    private getSVG() {
        if (this.image) {
            if (this.cache.has(this.image)) {
                setTimeout(() => this.placeSVG(this.cache.get(this.image as string) as string), 0);
            } else {
                this.http.get(this.image, { responseType: 'text' }).subscribe((svgText) => {
                    // Set the content of the SVG element with the SVG file's content
                    this.cache.set(this.image, svgText);
                    this.placeSVG(svgText);
                });
            }
        }
    }

    private placeSVG(svgText: string) {
        if (this.svgWrapper) {
            const svgWrapper = this.svgWrapper.nativeElement;
            svgWrapper.innerHTML = svgText;
            svgWrapper.children[0].setAttribute('width', this.width);
            svgWrapper.children[0].setAttribute('alt', this.alt);
            if (this.classes != null) {
                for (const classString of this.classes.split(' ')) {
                    svgWrapper.children[0].classList.add(classString);
                }
            }
            svgWrapper.replaceWith(svgWrapper.querySelector('svg'));
            setTimeout(() => this.loaded.emit(), 0);
        }
    }
}
