import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
    state('out', style({ opacity: 0 })),
    state('in', style({ opacity: 1 })),
    transition('in <=> out', animate('.25s ease-in-out')),
    transition('void => *', [style({ opacity: 0 }), animate('.25s ease-in-out', style({ opacity: 1 }))]),
    transition('* => void', [animate('.25s ease-in-out', style({ opacity: 0 }))]),
]);

export const slideInOut = trigger('slideInOut', [
    state('out', style({ transform: '{{translate}}' }), { params: { translate: 'translate(-100%, 0)' } }),
    state('in', style({ transform: 'translate(0, 0)' })),
    transition('in <=> out', animate('.25s ease-in-out')),
    transition('void => *', [style({ transform: '{{translate}}' }), animate('.25s ease-in-out')]),
    transition('* => void', [animate('.25s ease-in-out', style({ transform: '{{translate}}' }))]),
]);
