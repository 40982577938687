export enum TitleEnum {
    DIPLBETRIEBSWFH = 'Dipl.-Betriebsw.(FH)',
    DIPLING = 'Dipl. Ing.',
    DIPLKFFR = 'Dipl.-Kffr.',
    DIPLKFM = 'Dipl.-Kfm',
    DIPLLOG = 'Dipl.-Log.',
    DIPLMED = 'Dipl. Med.',
    DIPLMEDPAD = 'Dipl.-Med. Päd.',
    DIPLOEC = 'Dipl. -Oec.',
    DIPLPAD = 'Dipl.-Päd.',
    DIPLPHYSIO = 'Dipl.-Physio.',
    DIPLPSYCH = 'Dipl. Psych.',
    DIPLSOZ_ARB = 'Dipl. Soz.Arb.',
    DIPLSOZ_PAD = 'Dipl. Soz.Päd.',
    DIPLVERWW = 'Dipl.-Verww.',
    DR = 'Dr.',
    DRAGR = 'Dr. agr.',
    DRDES = 'Dr. Des.',
    DRDISCPOL = 'Dr. disc. pol.',
    DRDR = 'Dr. Dr.',
    DRFOREST = 'Dr. forest.',
    DRHC = 'Dr. h. c.',
    DRING = 'Dr.-Ing.',
    DRJUR = 'Dr. jur.',
    DRMED = 'Dr. med.',
    DRMEDDENT = 'Dr. med. dent.',
    DRMEDUNIV = 'Dr. med. univ.',
    DRMEDVET = 'Dr. med. vet.',
    DRNATTECHN = 'Dr. nat. techn.',
    DROCTROPH = 'Dr. öc. troph.',
    DROEC = 'Dr. oec.',
    DROEC_PUBL = 'Dr. oec publ.',
    DRPAED = 'Dr. paed.',
    DRPHARM = 'Dr. pharm.',
    DRPHIL = 'Dr. phil.',
    DRPHILNAT = 'Dr. phil. nat',
    DRRERCAMER = 'Dr. rer. camer.',
    DRRERCOMM = 'Dr. rer. comm',
    DRRERHORT = 'Dr. rer. hort.',
    DRRERMONT = 'Dr. rer. mont.',
    DRREROEC = 'Dr. rer. oec.',
    DRRERPOL = 'Dr. rer. pol.',
    DRRERPUBL = 'Dr. rer. publ.',
    DRRERSOCOEC = 'Dr. rer. soc. oec.',
    DRRERTECHN = 'Dr. rer. techn',
    DRSCMATH = 'Dr. sc. math.',
    DRTHEOL = 'Dr. theol.',
    MAG = 'Mag.',
    PROF = 'Prof.',
    PROFDR = 'Prof. Dr.',
    PROFDRMED = 'Prof. Dr. med.',
}
