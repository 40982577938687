import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rza-mean-promoted-service',
    templateUrl: './promoted-service.component.html',
    styleUrls: ['./promoted-service.component.scss'],
})
export class PromotedServiceComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
