import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
    selector: '[rzaMeanInnerHtmlLink]',
})
export class InnerHtmlLinkDirective implements AfterViewInit, OnDestroy {
    @Input()
    rzaMeanInnerHtmlLink: string | undefined;
    @Input()
    linkClasses = 'text-primary rounded-xl px-2 font-extrabold bg-white hover:underline';

    private unlistenCallbacks: Array<() => void> = [];
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        if (this.rzaMeanInnerHtmlLink) {
            const parser = new DOMParser();
            const html = parser.parseFromString(this.rzaMeanInnerHtmlLink, 'text/html');
            Array.from(html.getElementsByTagName('a')).forEach((link) => {
                if (this.linkClasses) {
                    this.linkClasses.split(' ').forEach((linkClass) => this.renderer.addClass(link, linkClass));
                }
                this.unlistenCallbacks.push(
                    this.renderer.listen(link, 'click', (event) => {
                        event.stopPropagation();
                    })
                );
            });
            Array.from(html.getElementsByTagName('body')[0].childNodes).forEach((node) =>
                this.renderer.appendChild(this.elementRef.nativeElement, node)
            );
        }
    }

    ngOnDestroy(): void {
        this.unlistenCallbacks.forEach((callback) => callback());
    }
}
