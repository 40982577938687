import { createReducer, on } from '@ngrx/store';
import * as ConfigurationActions from './configuration.actions';
import { BundleActions, DiscountActions } from './configuration.actions';
import produce from 'immer';
import { initialConfigState } from './configuration.state';
import { ProductTypeEnum } from '@rza-mean/api-interfaces';

export const configurationReducer = createReducer(
    initialConfigState,
    on(ConfigurationActions.selectProfession, (state, { _id }) =>
        produce(state, (newState) => {
            if (_id !== state.config.configuredProfession?.profession) {
                newState.config.configuredProfession = {
                    profession: _id,
                    products: [],
                };
            }
        })
    ),
    on(ConfigurationActions.addObligatoryProduct, (state, { product }) =>
        produce(state, (newState) => {
            let clone;
            if (product.products?.length) {
                clone = { ...product };
                clone.products?.filter((prod) => prod.isObligatory);
            }
            if (
                newState.config.configuredProfession != null &&
                !newState.config.configuredProfession.products.find((prod) => prod._id === product._id)
            ) {
                newState.config.configuredProfession.products = [
                    ...(newState.config.configuredProfession?.products || []),
                    clone ?? product,
                ];
            }
        })
    ),
    on(ConfigurationActions.SettlementProductActions.addSettlementProduct, (state, { product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [product],
                };
            } else {
                newState.config.configuredProfession.products = [
                    ...newState.config.configuredProfession.products.filter((prod) => prod._id !== product._id),
                    product,
                ];
            }
        })
    ),
    on(ConfigurationActions.SettlementProductActions.removeSettlementProduct, (state, { _id }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [],
                };
            } else {
                newState.config.configuredProfession.products = newState.config.configuredProfession.products.filter(
                    (prod) => prod._id !== _id
                );
            }
        })
    ),
    on(ConfigurationActions.ServiceProductActions.addServiceProduct, (state, { product, subProduct }) =>
        produce(state, (newState) => {
            let clone;
            if (subProduct) {
                clone = { ...product };
                clone.products = product.products?.filter((prod) => prod._id === subProduct._id);
            }
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [clone ?? product],
                };
            } else {
                newState.config.configuredProfession.products = [
                    ...newState.config.configuredProfession.products.filter((prod) => prod._id !== product._id),
                    clone ?? product,
                ];
            }
        })
    ),
    on(ConfigurationActions.ServiceProductActions.addMultipleServiceProducts, (state, { products }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: products,
                };
            } else {
                const productIds = products.map((p) => p._id);
                newState.config.configuredProfession.products = [
                    ...newState.config.configuredProfession.products.filter((prod) => !productIds.includes(prod._id)),
                    ...products,
                ];
            }
        })
    ),
    on(ConfigurationActions.ServiceProductActions.removeServiceProduct, (state, { _id }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [],
                };
            } else {
                newState.config.configuredProfession.products = newState.config.configuredProfession.products.filter(
                    (prod) => prod._id !== _id
                );
            }
        })
    ),
    on(ConfigurationActions.SoftwareProductActions.addSoftwareProduct, (state, { product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [product],
                };
            } else {
                newState.config.configuredProfession.products = [
                    ...newState.config.configuredProfession.products.filter((prod) => prod._id !== product._id),
                    product,
                ];
            }
        })
    ),
    on(ConfigurationActions.SoftwareProductActions.removeSoftwareProduct, (state, { _id }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [],
                };
            } else {
                newState.config.configuredProfession.products = newState.config.configuredProfession.products.filter(
                    (prod) => prod._id !== _id
                );
            }
        })
    ),
    on(ConfigurationActions.SoftwareProductActions.addSoftwareAddonProduct, (state, { product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [product],
                };
            } else {
                newState.config.configuredProfession.products = [
                    ...newState.config.configuredProfession.products.filter((prod) => prod._id !== product._id),
                    product,
                ];
            }
        })
    ),
    on(ConfigurationActions.SoftwareProductActions.removeSoftwareAddonProduct, (state, { _id }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [],
                };
            } else {
                newState.config.configuredProfession.products = newState.config.configuredProfession.products.filter(
                    (prod) => prod._id !== _id
                );
            }
        })
    ),
    on(ConfigurationActions.setPrefinancing, (state, { product }) =>
        produce(state, (newState) => {
            if (product != null) {
                if (newState.config.configuredProfession == null) {
                    newState.config.configuredProfession = {
                        profession: '',
                        products: [product],
                    };
                } else {
                    newState.config.configuredProfession.products = [
                        ...newState.config.configuredProfession.products.filter((prod) => prod.type !== product.type),
                        product,
                    ];
                }
            }
        })
    ),
    on(ConfigurationActions.ConfigCodeActions.setConfigCode, (state, { code }) =>
        produce(state, (newState) => {
            newState.config.code = code;
        })
    ),
    on(ConfigurationActions.setConfigVersion, (state, { version }) =>
        produce(state, (newState) => {
            newState.config.version = version;
        })
    ),
    on(ConfigurationActions.DiscountActions.activateDiscounts, (state, { discounts, bundles }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [],
                    discounts,
                };
            } else {
                const selectedBundle = bundles?.find((bundle) => bundle._id === newState.config.configuredProfession?.bundle);
                const bundleDiscountIds = selectedBundle?.discounts?.map((disc) => disc._id);
                newState.config.configuredProfession.discounts = discounts.filter((discount) => {
                    if (discount.isInBundle == null || !discount.isInBundle) {
                        return true;
                    } else {
                        return bundleDiscountIds?.includes(discount._id);
                    }
                });
            }
        })
    ),
    on(ConfigurationActions.BundleActions.setBundle, (state, { bundle }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [],
                    bundle: bundle._id,
                };
            } else {
                newState.config.configuredProfession.bundle = bundle._id;
            }
        })
    ),
    on(BundleActions.setBundleConfig, (state, { bundle }) =>
        produce(state, (newState) => {
            if (bundle.config && bundle.config.configuredProfession) {
                if (!newState.config.configuredProfession) {
                    newState.config.configuredProfession = {
                        profession: bundle.config.configuredProfession.profession,
                        products: [],
                    };
                }

                // TODO: reconsider solution
                const bundleConfigHasPrefinancing =
                    bundle.config.configuredProfession.products.findIndex((product) => product.type === ProductTypeEnum.PREFINANCING) > -1;
                if (bundleConfigHasPrefinancing) {
                    newState.config.configuredProfession.products = [...bundle.config.configuredProfession.products];
                } else {
                    newState.config.configuredProfession.products = [
                        ...newState.config.configuredProfession.products.filter((product) => product.type === ProductTypeEnum.PREFINANCING),
                        ...bundle.config.configuredProfession.products,
                    ];
                }
            }
        })
    ),
    on(ConfigurationActions.BundleActions.removeBundle, (state) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession == null) {
                newState.config.configuredProfession = {
                    profession: '',
                    products: [],
                };
            } else {
                delete newState.config.configuredProfession.bundle;
            }
        })
    ),
    on(ConfigurationActions.RevenueActions.updateReceiptCount, (state, { receiptCount, product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession != null) {
                if (newState.config.configuredProfession.products != null && newState.config.configuredProfession.products.length > 0) {
                    const productInConfig = newState.config.configuredProfession.products.find((prod) => prod._id === product._id);
                    if (productInConfig != null) {
                        if (productInConfig.revenue != null) {
                            productInConfig.revenue.count = receiptCount;
                        }
                    }
                }
            }
        })
    ),
    on(ConfigurationActions.RevenueActions.updateReceiptValue, (state, { receiptValue, product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession != null) {
                if (newState.config.configuredProfession.products != null && newState.config.configuredProfession.products.length > 0) {
                    const productInConfig = newState.config.configuredProfession.products.find((prod) => prod._id === product._id);
                    if (productInConfig != null) {
                        if (productInConfig.revenue != null) {
                            productInConfig.revenue.itemValue = receiptValue;
                        }
                    }
                }
            }
        })
    ),
    on(ConfigurationActions.RevenueActions.updatePatientCount, (state, { patientCount, product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession != null) {
                if (newState.config.configuredProfession.products != null && newState.config.configuredProfession.products.length > 0) {
                    const productInConfig = newState.config.configuredProfession.products.find((prod) => prod._id === product._id);
                    if (productInConfig != null) {
                        if (productInConfig.revenue != null) {
                            productInConfig.revenue.count = patientCount;
                        }
                    }
                }
            }
        })
    ),
    on(ConfigurationActions.RevenueActions.updatePatientRevenue, (state, { patientRevenue, product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession != null) {
                if (newState.config.configuredProfession.products != null && newState.config.configuredProfession.products.length > 0) {
                    const productInConfig = newState.config.configuredProfession.products.find((prod) => prod._id === product._id);
                    if (productInConfig != null) {
                        if (productInConfig.revenue != null) {
                            productInConfig.revenue.value = patientRevenue;
                        }
                    }
                }
            }
        })
    ),
    on(ConfigurationActions.RevenueActions.updateRevenue, (state, { revenue, product }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession != null) {
                if (newState.config.configuredProfession.products != null && newState.config.configuredProfession.products.length > 0) {
                    const productInConfig = newState.config.configuredProfession.products.find((prod) => prod._id === product._id);
                    if (productInConfig != null) {
                        if (productInConfig.revenue != null) {
                            productInConfig.revenue.value = revenue;
                        }
                    }
                }
            }
        })
    ),
    on(ConfigurationActions.LoadConfigCodeActions.load, (state, { code }) =>
        produce(state, (newState) => {
            newState.loading = true;
            newState.config.code = code;
        })
    ),
    on(ConfigurationActions.LoadConfigCodeActions.loadSuccess, ConfigurationActions.setConfig, (state, { config }) =>
        produce(state, (newState) => {
            newState.config = config;
            newState.loading = false;
        })
    ),
    on(ConfigurationActions.LoadConfigCodeActions.loadFailure, (state, { error }) =>
        produce(state, (newState) => {
            newState.error = error;
        })
    ),
    on(ConfigurationActions.setConfiguredProductPrices, (state, { idProduct, idSubProduct, prices }) =>
        produce(state, (newState) => {
            if (prices.length && newState.config.configuredProfession) {
                if (idSubProduct) {
                    const product = state.config.configuredProfession?.products.find((product) => product._id === idProduct);
                    const subProduct = product?.products?.find((_) => _._id === idSubProduct);
                    if (product && subProduct) {
                        const updatedProduct = {
                            ...product,
                            products: [
                                {
                                    ...subProduct,
                                    prices,
                                },
                            ],
                        };
                        newState.config.configuredProfession.products = [
                            ...newState.config.configuredProfession.products.filter((_) => _._id !== idProduct),
                            updatedProduct,
                        ];
                    }
                } else {
                    const product = state.config.configuredProfession?.products.find((product) => product._id === idProduct);
                    if (product) {
                        const updatedProduct = {
                            ...product,
                            prices,
                        };
                        newState.config.configuredProfession.products = [
                            ...newState.config.configuredProfession.products.filter((_) => _._id !== idProduct),
                            updatedProduct,
                        ];
                    }
                }
            }
        })
    ),
    on(ConfigurationActions.setConfiguredProductPricesOnStartUpChanges, (state, { products }) =>
        produce(state, (newState) => {
            if (products && newState.config.configuredProfession) {
                const productsInConfigWithPrice = products.filter((product) => {
                    const updatedProduct = state.config.configuredProfession?.products.find((prod) => prod._id === product.idProduct);
                    return updatedProduct != null && product.prices.length;
                });
                const updatedProducts = productsInConfigWithPrice.map((product) => {
                    let updatedProduct = state.config.configuredProfession?.products.find((prod) => prod._id === product.idProduct);
                    if (updatedProduct) {
                        updatedProduct = {
                            ...updatedProduct,
                            prices: product.prices,
                        };
                    }
                    return updatedProduct;
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newState.config.configuredProfession.products = [
                    ...newState.config.configuredProfession.products.filter(
                        (_) => !productsInConfigWithPrice.map((prod) => prod.idProduct).includes(_._id)
                    ),
                    ...updatedProducts,
                ];
            }
        })
    ),
    on(ConfigurationActions.setPrefinancingPriceDependentOnRevenueChange, (state, { revenue }) =>
        produce(state, (newState) => {
            if (revenue && newState.config.configuredProfession) {
                const product = state.config.configuredProfession?.products.find((prod) => prod.type === ProductTypeEnum.PREFINANCING);
                if (product && product.staggeredPrices && product.staggeredPrices.length) {
                    const price = product.staggeredPrices.find(
                        (staggeredPrice) =>
                            staggeredPrice.lowerThreshold <= revenue && (staggeredPrice.upperThreshold ?? Infinity) >= revenue
                    )?.price;
                    if (price) {
                        const updatedProduct = {
                            ...product,
                            prices: [price],
                        };
                        newState.config.configuredProfession.products = [
                            ...newState.config.configuredProfession.products.filter((_) => _._id !== product._id),
                            updatedProduct,
                        ];
                    }
                }
            }
        })
    ),
    on(ConfigurationActions.setIsStartUpConfirmed, (state, { isStartUpConfirmed }) =>
        produce(state, (newState) => {
            newState.config.isStartUpConfirmed = isStartUpConfirmed;
        })
    ),
    on(DiscountActions.setConfiguredDiscountsPrices, (state, { discountWithRightPrices }) =>
        produce(state, (newState) => {
            if (newState.config.configuredProfession) {
                let discounts = newState.config.configuredProfession.discounts;
                if (discounts) {
                    discounts = discounts.map((discount) => {
                        const newPrice = discountWithRightPrices.find((dwrp) => dwrp.idDiscount === discount._id)?.price;
                        if (newPrice) {
                            discount.price = newPrice;
                        }
                        return discount;
                    });
                    newState.config.configuredProfession.discounts = [...discounts];
                }
            }
        })
    ),
    on(ConfigurationActions.resetConfig, (state) =>
        produce(state, (newState) => {
            newState.config = {
                version: '',
            };
        })
    )
);
