import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Bundle, ContactRequest, Product, Profession } from '../shared/interfaces';
import { PaymentSpeedPipe } from '../pipes/payment-speed.pipe';

@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerService {
    private isLoaded = false;
    private readonly id: string;
    private paymentSpeedPipe = new PaymentSpeedPipe();
    private browserGlobals = {
        windowRef: (): any => {
            return window;
        },
        documentRef: (): Document => {
            return document;
        },
    };

    constructor(private router: Router) {
        this.id = environment.production ? 'GTM-NQFZG95G' : 'GTM-NQFZG95G'; //'GTM-T6CQTJ98';
    }

    public init(): void {
        (window as any).dataLayer = (window as any).dataLayer || [];

        (window as any).gtag = function () {
            (window as any).dataLayer.push(arguments);
        };
        this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
            // TODO: What tag to use?
            (window as any).gtag('config', this.id);
            (window as any).gtag('js', new Date());
            const gtmTag = {
                event: 'page',
                pageName: e.url,
            };
            this.pushTag(gtmTag);
        });
    }

    public professionSelected(profession: Profession) {
        const gtmTag = {
            event: 'professionSelected',
            profession: profession.name,
        };
        this.pushTag(gtmTag);
    }

    public bundleSelected(bundle: Bundle) {
        const gtmTag = {
            event: 'bundleSelected',
            bundle: bundle.name,
        };
        this.pushTag(gtmTag);
    }

    public prefinancingSelected(product: Product) {
        const gtmTag = {
            event: 'prefinancingSelected',
            prefinancing: `${product.name}: ${this.paymentSpeedPipe.transform(product.paymentSpeed)}`,
        };
        this.pushTag(gtmTag);
    }

    public settlementProductChanged(action: 'hinzugefügt' | 'entfernt', product?: Product) {
        if (product) {
            const gtmTag = {
                event: 'settlementProductSelected',
                settlement: `${product.name}: ${action}`,
            };
            this.pushTag(gtmTag);
        }
    }

    public transactionCompleted() {
        const gtmTag = {
            event: 'transactionCompleted',
        };
        this.pushTag(gtmTag);
    }

    public contactRequestSent(request: ContactRequest) {
        const gtmTag = {
            event: 'contactRequestSubmitted',
            contactRequest: `${request.companyName}, ${request.firstname} ${request.lastname} (${request.email}, ${request.phone}): ${request.subject}, ${request.message}`,
        };
        this.pushTag(gtmTag);
    }

    // google tag manager / analytics
    private getDataLayer(): any[] {
        const window = this.browserGlobals.windowRef();
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer;
    }

    private pushOnDataLayer(obj: object): void {
        const dataLayer = this.getDataLayer();
        dataLayer.push(obj);
    }

    private addGtmToDom(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.isLoaded) {
                return resolve(this.isLoaded);
            }
            const doc = this.browserGlobals.documentRef();
            this.pushOnDataLayer({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });

            this.addGtmIframe(doc);
            this.addGtmScript(doc, resolve, reject);
        });
    }

    private addGtmScript(doc: Document, resolve: (value: PromiseLike<boolean> | boolean) => void, reject: (reason?: any) => void) {
        const gtmScript = doc.createElement('script');
        gtmScript.id = 'GTMscript';
        gtmScript.async = true;
        gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + this.id;
        // gtmScript.src = this.applyGtmQueryParams(
        //     'https://www.googletagmanager.com/gtm.js'
        // );
        gtmScript.addEventListener('load', () => {
            return resolve((this.isLoaded = true));
        });
        gtmScript.addEventListener('error', () => {
            return reject(false);
        });
        // if (this.googleTagManagerCSPNonce) {
        //     gtmScript.setAttribute('nonce', this.googleTagManagerCSPNonce);
        // }
        doc.head.insertBefore(gtmScript, doc.head.firstChild);
    }

    // private applyGtmQueryParams(url: string): string {
    //     if (url.indexOf('?') === -1) {
    //         url += '?';
    //     }
    //
    //     return (
    //         url +
    //         Object.keys(this.config)
    //             .filter((k) => this.config[k])
    //             .map((k) => `${k}=${this.config[k]}`)
    //             .join('&')
    //     );
    // }

    private pushTag(item: object): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!this.isLoaded) {
                this.addGtmToDom()
                    .then(() => {
                        this.pushOnDataLayer(item);
                        return resolve();
                    })
                    .catch(() => reject());
            } else {
                this.pushOnDataLayer(item);
                return resolve();
            }
        });
    }

    private addGtmIframe(doc: Document) {
        const gtmNoscript = doc.createElement('noscript');
        const gtmIframe = doc.createElement('iframe');
        gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=' + this.id;
        gtmIframe.height = '0';
        gtmIframe.width = '0';
        gtmIframe.style.display = 'none';
        gtmIframe.style.visibility = 'hidden';
        gtmNoscript.appendChild(gtmIframe);
        doc.body.insertBefore(gtmNoscript, doc.body.firstChild);
    }
}
