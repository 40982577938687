import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';

@Directive({
    selector: '[rzaMeanSelectable]',
})
export class SelectableDirective implements AfterViewInit, OnChanges {
    @Input() public isSelected = false;
    @Input() selectionStyleClasses = '';
    @Output() selected: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() selectionType: 'radio' | 'checkbox' | 'mixed' = 'checkbox';
    @Input() isObligatory = false;
    private selectionClass = 'secondary-selected';

    radio = false;
    mixed: boolean | undefined;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.radio = this.selectionType === 'radio';
        this.mixed = this.selectionType === 'mixed';
        if (!this.isObligatory) {
            this.el.nativeElement.classList.add('cursor-pointer');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isSelected']) {
            if (changes['isSelected'].previousValue != null) {
                this.toggleSelection(this.isSelected);
            } else {
                this.toggleSelectionClasses(this.isSelected);
            }
        }
    }

    @HostListener('click')
    onClick() {
        if (!this.isObligatory) {
            if (this.radio && !this.isSelected) {
                this.isSelected = true;
            } else if (!this.radio) {
                this.isSelected = !this.isSelected;
            }
            this.toggleSelection(this.isSelected);
        }
    }

    toggleSelection(selected: boolean) {
        this.toggleSelectionClasses(selected);
        this.selected.emit(selected);
    }

    private toggleSelectionClasses(selected: boolean) {
        const element = this.el.nativeElement.children[0];
        if (selected) {
            this.renderer.addClass(element, this.selectionClass);
            if (this.isObligatory && !this.selectionStyleClasses) {
                this.renderer.addClass(element, 'secondary-selected');
            }
        } else {
            this.renderer.removeClass(element, this.selectionClass);
        }
        if (this.selectionStyleClasses.length) {
            selected
                ? this.renderer.addClass(element, this.selectionStyleClasses)
                : this.renderer.removeClass(element, this.selectionStyleClasses);
        }
    }
}
