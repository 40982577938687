<div *ngIf="!labels.length" class="cursor-pointer">
    <span class="relative block">
        <fa-icon class="text-white text-[24px] {{ classes }}" icon="circle"></fa-icon>
        <fa-icon
            *ngIf="selected"
            class="absolute text-[12px] left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 text-primary {{ checkedClasses }}"
            icon="circle"
        ></fa-icon>
    </span>
</div>
<div class="my-2 flex items-center" *ngFor="let label of labels; let i = index">
    <input
        hidden=""
        [id]="label"
        type="radio"
        [value]="label"
        (input)="change($event)"
        [checked]="label === checkedLabel"
        name="radio"
        class="focus:ring-secondary checked:bg-primary checked:border-primary relative h-4 w-4 appearance-none rounded-full border border-gray-300 focus:ring-2"
    />
    <label [for]="label" class="text ml-2">{{ label }}</label>
</div>
