<div
    [title]="infoTitle"
    class="bg-primary relative h-[28px] w-[28px] cursor-pointer rounded-full p-0"
    style="line-height: 0"
    [ngClass]="bgClass"
>
    <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-semibold text-white" [ngClass]="iconClass">
        <fa-icon class="text-white" icon="info"></fa-icon>
    </span>
</div>
