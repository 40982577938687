<rza-mean-page class="print:text-[12px]">
    <rza-mean-section class="print:hidden">
        <rza-mean-title headline="Unsere Leistungen für Sie" subHeadline="Bitte prüfen und bestätigen Sie Ihre Auswahl:"></rza-mean-title>
    </rza-mean-section>

    <div class="flex flex-grow flex-col justify-center">
        <rza-mean-section>
            <div>
                <!--region UPPER SECTION-->
                <div class="mb-6">
                    <rza-mean-summary-sidebar-cards></rza-mean-summary-sidebar-cards>
                </div>
                <!--endregion-->

                <!--region MIDDLE SECTION-->
                <div class="grid-col-1 grid gap-8">
                    <div *ngFor="let bundleFeatures of (bundle$ | async)?.promotion?.features">
                        <!--TODO: add function to determine "Genetiv" case when needed-->
                        <h3 class="text-primary mb-2 text-lg font-bold">Leistungen des {{ (bundle$ | async)?.name }}s:</h3>
                        <rza-mean-card>
                            <ng-template #body>
                                <div class="grid grid-cols-1 md:grid-cols-3">
                                    <ng-container *ngFor="let column of [0, 1, 2]; let lastOuter = last">
                                        <ul rzaMeanListBullet class="m-0 lg:col-span-1 lg:my-2">
                                            <ng-container *ngFor="let item of bundleFeatures.items; let i = index; let lastInner = last">
                                                <ng-container *ngIf="i % 3 === column">
                                                    <div
                                                        class="mb-2"
                                                        [ngClass]="{
                                                            'md:mb-0': isBottomColumnItem(i, column, bundleFeatures.items),
                                                            'max-md:mb-0':
                                                                bundleFeatures.items.length - (bundleFeatures.items.length % 3) - 1 === i
                                                        }"
                                                    >
                                                        <li [innerHtml]="item"></li>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </rza-mean-card>
                    </div>

                    <div class="grid grid-cols-1 gap-8 xl:grid-cols-3">
                        <div *ngFor="let category of productsByCategory | keyvalue">
                            <rza-mean-accordion *ngIf="categoryHasProducts(category.value)">
                                <rza-mean-accordion-group [isOpen]="false">
                                    <ng-container accordion-header>
                                        <h3 class="p-0 text-xl font-medium 2xl:text-2xl">
                                            {{ category?.key ?? '' }}
                                        </h3>
                                    </ng-container>
                                    <div class="card bg-secondary rounded-t-none text-white">
                                        <div
                                            class="card-body pt-0"
                                            *ngFor="let product of category.value; let last = last"
                                            [ngClass]="{ 'border-b-4 border-white': !last }"
                                        >
                                            <rza-mean-card
                                                [headerText]="product?.name !== category?.value?.[0]?.category ? product?.name : ''"
                                                cardClasses="-mx-4 bg-secondary"
                                                headerClasses="my-0 !text-xl font-medium text-white pb-0"
                                            >
                                                <ng-template #body>
                                                    <span>{{ product.description }}</span>
                                                    <ng-container *ngIf="product.products?.length">
                                                        <div *ngFor="let subProduct of product.products">
                                                            <rza-mean-card
                                                                [headerText]="subProduct?.name ?? ''"
                                                                footerClasses="!text-white !bg-secondary"
                                                                cardClasses="-mx-4 bg-secondary text-white"
                                                                headerClasses="text-white p4 pb-0 font-medium !text-xl my-0 text-xl"
                                                            >
                                                                <ng-template #body>
                                                                    <div [rzaMeanInnerHtmlLink]="subProduct.description"></div>
                                                                </ng-template>
                                                            </rza-mean-card>
                                                        </div>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #side>
                                                    <ng-container *ngIf="product.prices">
                                                        <div *ngFor="let price of product.prices" class="mb-2 flex items-center">
                                                            <div
                                                                *ngIf="(price | price) !== 'inklusive'"
                                                                class="bg-secondary rounded-xl px-2 font-medium text-white"
                                                            >
                                                                {{ price.billingType.displayName }}
                                                            </div>
                                                            <div class="mr-3 text-lg font-semibold text-white">
                                                                {{ price | price }}
                                                            </div>
                                                        </div>
                                                        <!--IF NO PRICE AND NO SUBPRODUCTS-->
                                                        <div *ngIf="!product.prices.length && !product.products?.length">
                                                            <div class="mr-3 text-lg font-semibold text-white">inklusive</div>
                                                        </div>

                                                        <!--IF PRODUCT HAS SUBPRODUCTS-->
                                                        <div
                                                            class="mb-2 flex items-center"
                                                            *ngIf="product.products?.length && product.products  && product.products?.[0]?.prices"
                                                        >
                                                            <div
                                                                *ngFor="let price of product?.products?.[0]?.prices || []"
                                                                class="bg-secondary rounded-xl px-2 font-medium text-white"
                                                            >
                                                                {{ product?.products?.[0]?.prices?.[0]?.billingType?.displayName }}
                                                            </div>
                                                            <div class="mr-3 text-lg font-semibold text-white">
                                                                {{ product?.products?.[0]?.prices?.[0] | price }}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-template>
                                            </rza-mean-card>
                                        </div>
                                    </div>
                                </rza-mean-accordion-group>
                            </rza-mean-accordion>
                        </div>
                    </div>

                    <div *ngIf="additionalProductsByCategory" class="grid grid-cols-1 gap-x-8 xl:grid-cols-3">
                        <h3 class="text-primary col-span-3 mb-2 text-lg font-bold">Zusätzlich gewählte Leistungen:</h3>

                        <div *ngFor="let category of additionalProductsByCategory | keyvalue">
                            <rza-mean-accordion *ngIf="categoryHasProducts(category.value)">
                                <rza-mean-accordion-group [isOpen]="false">
                                    <ng-container accordion-header>
                                        <h3 class="p-0 text-xl font-medium 2xl:text-2xl">
                                            {{ category?.key ?? '' }}
                                        </h3>
                                    </ng-container>
                                    <div class="card bg-secondary rounded-t-none text-white">
                                        <div
                                            class="card-body pt-0"
                                            *ngFor="let product of category.value; let last = last"
                                            [ngClass]="{ 'border-b-4 border-white': !last }"
                                        >
                                            <rza-mean-card
                                                [headerText]="product?.name !== category?.value?.[0]?.category ? product?.name : ''"
                                                cardClasses="-mx-4 bg-secondary"
                                                headerClasses="my-0 !text-xl font-medium text-white pb-0"
                                            >
                                                <ng-template #body>
                                                    <span>{{ product.description }}</span>
                                                    <ng-container *ngIf="product.products?.length">
                                                        <div *ngFor="let subProduct of product.products">
                                                            <rza-mean-card
                                                                [headerText]="subProduct?.name ?? ''"
                                                                footerClasses="!text-white !bg-secondary"
                                                                cardClasses="-mx-4 bg-secondary text-white"
                                                                headerClasses="text-white p4 pb-0 font-medium !text-xl my-0 text-xl"
                                                            >
                                                                <ng-template #body>
                                                                    <div [rzaMeanInnerHtmlLink]="subProduct.description"></div>
                                                                </ng-template>
                                                            </rza-mean-card>
                                                        </div>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #side>
                                                    <ng-container *ngIf="product.prices">
                                                        <div *ngFor="let price of product.prices" class="mb-2 flex items-center">
                                                            <div
                                                                *ngIf="(price | price) !== 'inklusive'"
                                                                class="bg-secondary rounded-xl px-2 font-medium text-white"
                                                            >
                                                                {{ price.billingType.displayName }}
                                                            </div>
                                                            <div class="mr-3 text-lg font-semibold text-white">
                                                                {{ price | price }}
                                                            </div>
                                                        </div>
                                                        <!--IF NO PRICE AND NO SUBPRODUCTS-->
                                                        <div *ngIf="!product.prices.length && !product.products?.length">
                                                            <div class="mr-3 text-lg font-semibold text-white">inklusive</div>
                                                        </div>

                                                        <!--IF PRODUCT HAS SUBPRODUCTS-->
                                                        <div
                                                            class="mb-2 flex items-center"
                                                            *ngIf="product.products?.length && product.products  && product.products?.[0]?.prices"
                                                        >
                                                            <div
                                                                *ngFor="let price of product?.products?.[0]?.prices || []"
                                                                class="bg-secondary rounded-xl px-2 font-medium text-white"
                                                            >
                                                                {{ product?.products?.[0]?.prices?.[0]?.billingType?.displayName }}
                                                            </div>
                                                            <div class="mr-3 text-lg font-semibold text-white">
                                                                {{ product?.products?.[0]?.prices?.[0] | price }}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-template>
                                            </rza-mean-card>
                                        </div>
                                    </div>
                                </rza-mean-accordion-group>
                            </rza-mean-accordion>
                        </div>
                    </div>
                </div>
                <!--endregion-->
            </div>
        </rza-mean-section>

        <rza-mean-section>
            <hr />
        </rza-mean-section>

        <!--region SUMMARY LIST-->
        <rza-mean-section>
            <!--PLS LEAVE AT COLS-3-->
            <div class="grid grid-cols-3" id="cost-overview">
                <div class="col-span-4 xl:col-span-3 print:!col-span-4">
                    <rza-mean-accordion (isOpen)="isOverviewOpen = $event" class="mb-6">
                        <rza-mean-accordion-group [isOpen]="openOnPrint">
                            <ng-container accordion-header>
                                <div class="print:hidden" data-cy="summary-list-open">Ihre detaillierte Kostenübersicht</div>
                            </ng-container>
                            <div class="py-4">
                                <div *ngFor="let item of summaryList$ | async; let first = first">
                                    <div class="mb-2">
                                        <h5 class="text-primary text-2xl font-medium print:text-base">{{ item.billingTypeName }}</h5>
                                    </div>
                                    <div *ngFor="let subCategory of item?.subCategory; let i = index" class="mb-10">
                                        <h6
                                            *ngIf="subCategory?.header"
                                            class="text-primary border-primary border-b text-lg font-medium print:text-base"
                                        >
                                            {{ subCategory.header }}
                                        </h6>

                                        <div
                                            *ngFor="let subCategoryItem of subCategory.items; let last = last"
                                            [ngClass]="{
                                                total: subCategory.flag === 'total',
                                                'border-b border-dotted border-gray-300': !last
                                            }"
                                            class="flex justify-between py-1 text-lg print:text-base"
                                        >
                                            <div>
                                                <span [innerHTML]="subCategoryItem?.name"> </span>
                                                <sup class="pl-1" *ngIf="subCategoryItem?.smallprint">{{
                                                    smallPrintService.register(subCategoryItem?.smallprint || [])
                                                }}</sup>
                                            </div>

                                            <div>
                                                <ng-container *ngIf="subCategoryItem.price as price">
                                                    <strong *ngIf="subCategoryItem?.pricePrefix">{{ subCategoryItem.pricePrefix }}</strong>
                                                    <strong [innerHtml]="price"></strong>
                                                </ng-container>
                                                <ng-container *ngIf="subCategoryItem.icon as icon">
                                                    <strong>
                                                        <fa-icon [icon]="icon" class="text-secondary"></fa-icon>
                                                    </strong>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="totalPricePerPriceType$ | async as totalPricePerPriceType" class="mb-4">
                                <div class="inline-flex items-center">
                                    <div class="text-primary font-medium max-md:text-xl md:text-2xl print:text-base">
                                        <span>Gesamtkosten Dienstleistungs&shy;paket</span>
                                        <sup
                                            class="pl-1"
                                            *ngIf="
                                                (bundle$ | async)?.settlementForRevenueOnly
                                                    ? totalPricePerPriceTypeSmallprintRevenueOnly
                                                    : totalPricePerPriceTypeSmallprint
                                            "
                                            >{{
                                                smallPrintService.register(
                                                    totalPricePerPriceTypeSmallprintRevenueOnly || totalPricePerPriceTypeSmallprint || []
                                                )
                                            }}</sup
                                        >
                                    </div>
                                </div>
                                <div class="flex justify-between border-b border-dotted border-gray-300 py-1 text-lg print:text-base">
                                    <div *ngIf="!showSmallprintForTotalPercentPrice">Preis in %</div>
                                    <div *ngIf="showSmallprintForTotalPercentPrice" class="flex cursor-pointer items-center">
                                        <div>Preis in %</div>
                                        <sup class="pl-1">{{ smallPrintService.register(totalPercentPriceSmallprint || []) }}</sup>
                                    </div>
                                    <div>
                                        <strong data-cy="total-percentage"
                                            >{{ totalPricePerPriceType.percent | number : '1.2-2' : 'de' }}&nbsp;%</strong
                                        >
                                    </div>
                                </div>
                                <div class="flex justify-between py-1 text-lg print:text-base">
                                    <div>Preis in €</div>
                                    <div>
                                        <strong data-cy="total-absolute"
                                            >{{ totalPricePerPriceType.absolute | number : '1.2-2' : 'de' }}&nbsp;€</strong
                                        >
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="totalPrice$ | async as totalPrice" class="text-lg print:text-base">
                                <ng-container *ngIf="totalPrice.discount !== 0; else noDiscount">
                                    <div class="inline-flex cursor-pointer items-center">
                                        <div class="text-primary mb-2 text-xl font-medium md:text-2xl print:text-base">
                                            Beispielkosten je Abrechnung
                                        </div>
                                        <sup
                                            *ngIf="
                                                (bundle$ | async)?.settlementForRevenueOnly
                                                    ? totalPriceSmallprintRevenueOnly
                                                    : totalPriceSmallprint
                                            "
                                            >{{
                                                this.smallPrintService.register(
                                                    totalPriceSmallprintRevenueOnly || totalPriceSmallprint || []
                                                )
                                            }}</sup
                                        >
                                    </div>
                                    <div class="flex justify-between line-through">
                                        <div>Normalpreis:</div>
                                        <div class="ml-auto text-xl md:text-2xl" data-cy="total-price">
                                            {{ totalPrice.price | number : '1.2-2' : 'de' }}&nbsp;€
                                        </div>
                                    </div>
                                    <div class="text-secondary flex justify-between font-medium">
                                        <div>Ihr rabattierter Preis:</div>
                                        <div class="ml-auto text-2xl print:text-base" data-cy="total-price-discounted">
                                            {{ totalPrice.price + totalPrice.discount | number : '1.2-2' : 'de' }}&nbsp;€
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #noDiscount>
                                    <div
                                        class="text-primary flex flex-wrap items-center justify-between text-2xl font-medium print:text-base"
                                    >
                                        <div class="flex cursor-pointer items-center">
                                            <div class="text-xl md:text-2xl">Beispielkosten je Abrechnung</div>
                                            <sup
                                                class="pl-1"
                                                *ngIf="
                                                    (bundle$ | async)?.settlementForRevenueOnly
                                                        ? totalPriceSmallprintRevenueOnly
                                                        : totalPriceSmallprint
                                                "
                                                >{{
                                                    smallPrintService.register(
                                                        totalPriceSmallprintRevenueOnly || totalPriceSmallprint || []
                                                    )
                                                }}</sup
                                            >
                                        </div>
                                        <div class="ml-auto text-xl font-semibold md:text-2xl" data-cy="total-price">
                                            {{ totalPrice.price + totalPrice.discount | number : '1.2-2' : 'de' }}&nbsp;€
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </rza-mean-accordion-group>
                    </rza-mean-accordion>

                    <div *ngIf="!isOverviewOpen && (totalPricePerPriceType$ | async) as totalPricePerPriceType" class="mb-4">
                        <div class="inline-flex cursor-pointer items-center">
                            <div class="text-primary font-medium max-md:text-xl md:text-2xl print:text-base">
                                <span>Gesamtkosten Dienstleistungs&shy;paket</span>
                                <sup
                                    class="pl-1"
                                    *ngIf="
                                        (bundle$ | async)?.settlementForRevenueOnly
                                            ? totalPricePerPriceTypeSmallprintRevenueOnly
                                            : totalPricePerPriceTypeSmallprint
                                    "
                                    >{{
                                        smallPrintService.register(
                                            totalPricePerPriceTypeSmallprintRevenueOnly || totalPricePerPriceTypeSmallprint || []
                                        )
                                    }}</sup
                                >
                            </div>
                        </div>
                        <div class="flex justify-between border-b border-dotted border-gray-300 py-1 text-lg print:text-base">
                            <div *ngIf="!showSmallprintForTotalPercentPrice">Preis in %</div>
                            <div *ngIf="showSmallprintForTotalPercentPrice" class="flex cursor-pointer items-center">
                                <span>Preis in %</span>
                                <sup class="pl-1" *ngIf="showSmallprintForTotalPercentPrice">{{
                                    smallPrintService.register(totalPercentPriceSmallprint || [])
                                }}</sup>
                            </div>
                            <div>
                                <strong data-cy="total-percentage"
                                    >{{ totalPricePerPriceType.percent | number : '1.2-2' : 'de' }}&nbsp;%</strong
                                >
                            </div>
                        </div>
                        <div class="flex justify-between py-1 text-lg print:text-base">
                            <div>Preis in €</div>
                            <div>
                                <strong data-cy="total-absolute"
                                    >{{ totalPricePerPriceType.absolute | number : '1.2-2' : 'de' }}&nbsp;€</strong
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span class="text-muted">
                Der errechnete Preis basiert auf der angegebenen Anzahl an Belegen {{ settlementRevenueSummaryText$ | async }}. Dies dient
                lediglich als Beispiel für einen Monat und kann je nach tatsächlicher Anzahl der eingereichten Belege variieren.
            </span>
        </rza-mean-section>
        <!--endregion-->

        <!--region SUMMARY PRICE-->
        <rza-mean-section class="print:hidden">
            <div class="block items-center md:flex">
                <div class="flex">
                    <div class="border-1 mb-2 mr-4 border-r border-black pr-4">
                        <a class="text-primary cursor-pointer" [href]="pdfUrl" target="_blank">
                            <fa-icon class="text-2xl" icon="file-pdf"></fa-icon>
                        </a>
                    </div>
                    <div class="mb-2 mr-4">
                        <div class="font-medium">Sie haben einen Rabattcode?</div>
                        <div>Hier können Sie ihn eingeben.</div>
                    </div>
                </div>
                <div class="flex" [formGroup]="discountCodeForm">
                    <rza-mean-input
                        formControlName="code"
                        [indicateValidity]="false"
                        placeholder="Ihr Rabattcode"
                        class="mr-4"
                        (keyup.enter)="discountCodeBtn.click()"
                    ></rza-mean-input>
                    <button
                        rzaMeanShiny
                        #discountCodeBtn
                        (click)="loadDiscountCode()"
                        type="button"
                        class="btn btn-primary self-start py-4 max-sm:px-4"
                        [disabled]="discountCodeForm.invalid"
                    >
                        Einlösen
                    </button>
                </div>
            </div>
        </rza-mean-section>
        <!--endregion-->
    </div>

    <section>
        <rza-mean-small-print></rza-mean-small-print>
    </section>

    <div class="print:hidden">
        <rza-mean-page-navigation></rza-mean-page-navigation>
    </div>
</rza-mean-page>
