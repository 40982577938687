import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { combineLatestWith, filter, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Bundle, Price, Product, Profession, PromotedBundles } from '../../shared/interfaces';
import { PriceDependencyTypeEnum, ProductTypeEnum, PromotionCategoryEnum } from '@rza-mean/api-interfaces';
import { BundleActions } from '../../store/configuration/configuration.actions';
import { selectConfiguredBundleId } from '../../store/configuration/configuration.selectors';
import { Store } from '@ngrx/store';
import { SmallPrintService } from '@rza-mean/ui';
import { getSelectionClass } from '../../shared/utils';
import {
    selectPromotedBundles,
    selectSelectedProfession,
    selectSettlementProductsAvailableInBundle,
} from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-bundle',
    templateUrl: './bundle.component.html',
    styleUrls: ['./bundle.component.scss'],
})
export class BundleComponent implements OnDestroy, AfterViewInit {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    @ViewChildren('bundleCard', { read: ElementRef }) cards!: QueryList<ElementRef>;
    selectedProfession$: Observable<Profession> | undefined;
    promotedBundles$: Observable<PromotedBundles | null>;
    startBundles$: Observable<Bundle[]>;
    buttonBundles$: Observable<Bundle[]>;
    separatorIndex$: Observable<number>;
    selectedBundleId: string | undefined;
    Math = Math;
    private unsubscribe$ = new Subject();
    @HostListener('window:resize')
    onResize() {
        this.calcHeights();
    }

    constructor(private store: Store, public smallPrintService: SmallPrintService) {
        this.selectedProfession$ = this.store.select(selectSelectedProfession);
        this.promotedBundles$ = this.store.select(selectPromotedBundles);
        this.startBundles$ = this.promotedBundles$.pipe(
            map(
                (promotedBundle) =>
                    promotedBundle?.bundles.filter((bundle) => bundle.promotion?.category === PromotionCategoryEnum.START) || []
            )
        );
        this.buttonBundles$ = this.promotedBundles$.pipe(
            map(
                (promotedBundle) =>
                    promotedBundle?.bundles.filter((bundle) => bundle.promotion?.category === PromotionCategoryEnum.BUTTON) || []
            )
        );
        this.separatorIndex$ = this.selectedProfession$.pipe(
            combineLatestWith(this.promotedBundles$),
            map(([profession, promotedBundles]) => ({ profession, promotedBundles })),
            map(({ profession, promotedBundles }) => {
                if (profession.professionBranch.name === 'Pflege' && promotedBundles) {
                    return promotedBundles.bundles.findIndex(
                        (bundle) =>
                            bundle.config?.configuredProfession?.products.findIndex(
                                (product) => product.type === ProductTypeEnum.SETTLEMENT && !product.forRevenueOnly
                            ) === -1
                    );
                }
                return -1;
            })
        );

        this.store
            .select(selectConfiguredBundleId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bundleId) => (this.selectedBundleId = bundleId));

        this.promotedBundles$.pipe(takeUntil(this.unsubscribe$)).subscribe((promotedBundles) => {
            const smallPrints = promotedBundles?.promotion?.smallPrint;
            if (smallPrints?.length) {
                this.smallPrintService.register(smallPrints);
            }
        });
    }

    ngAfterViewInit() {
        this.calcHeights();
    }

    calcHeights() {
        if (this.cards.length && window.innerWidth > 960) {
            setTimeout(() => {
                this.cards.forEach((card) => (card.nativeElement.style.height = 'auto'));
                const smallestHeight = this.cards.first.nativeElement.getBoundingClientRect().height;
                const largestHeight = this.cards.last.nativeElement.getBoundingClientRect().height;
                let stepSize = (largestHeight - smallestHeight) / (this.cards.length - 1);
                const minStepSize = 0.1 * smallestHeight;
                stepSize = minStepSize > stepSize ? minStepSize : stepSize;
                const altStepSize = this.cards.reduce((acc, cur, index) => {
                    if (index + 1 < this.cards.length) {
                        const diff =
                            this.cards.get(index + 1)?.nativeElement.getBoundingClientRect().height -
                            cur.nativeElement.getBoundingClientRect().height;
                        if (diff > acc) {
                            return diff;
                        }
                    }
                    return acc;
                }, 0);

                this.cards.forEach((card, index) => {
                    if (index > 0) {
                        const newHeight = this.cards.get(index - 1)?.nativeElement.getBoundingClientRect().height + stepSize;
                        if (newHeight < card.nativeElement.getBoundingClientRect().height) {
                            this.cards.forEach((card, index) => {
                                if (index > 0) {
                                    card.nativeElement.style.height =
                                        this.cards.get(index - 1)?.nativeElement.getBoundingClientRect().height + altStepSize;
                                }
                            });
                        } else {
                            card.nativeElement.style.height = newHeight + 'px';
                        }
                    }
                });
            }, 0);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
        this.smallPrintService.reset();
    }

    selectBundle($event: boolean, bundle: Bundle) {
        if ($event && bundle._id !== this.selectedBundleId) {
            this.selectedBundleId = bundle._id;
            if (bundle.config) {
                this.store.dispatch(BundleActions.setBundle({ bundle }));
            }
        }
    }

    getVisibleDiscounts(bundle: Bundle): Price[] | null {
        const visibleDiscounts = bundle.promotion?.visibleDiscounts;
        return visibleDiscounts?.length ? visibleDiscounts : null;
    }

    getBannerItems$(bundle: Bundle): Observable<string[]> {
        return (
            this.selectedProfession$?.pipe(
                filter((profession) => profession.professionBranch.identifier === 'HEIMI'),
                switchMap(() =>
                    this.store.select(selectSettlementProductsAvailableInBundle(bundle)).pipe(
                        map((settlements) => {
                            const settlementsByPrice = settlements.reduce((acc, settlement) => {
                                if (settlement.dependentPrices?.length) {
                                    const price = settlement.dependentPrices.find((_) => {
                                        if (_.type === PriceDependencyTypeEnum.BUNDLE) {
                                            return bundle._id === _.referenceId;
                                        }
                                        return false;
                                    })?.price;
                                    if (price) {
                                        const key = price.value.toFixed(2).toString().replace('.', ',') + price.unit;
                                        acc[key] = acc[key] || [];
                                        acc[key].push(settlement);
                                    }
                                } else if (settlement.prices?.length === 1) {
                                    const key =
                                        settlement.prices[0].value.toFixed(2).toString().replace('.', ',') + settlement.prices[0].unit;
                                    acc[key] = acc[key] || [];
                                    acc[key].push(settlement);
                                } else if (settlement.staggeredPrices?.length) {
                                    const key =
                                        'ab ' +
                                        settlement.staggeredPrices[0].price.value.toFixed(2).toString().replace('.', ',') +
                                        settlement.staggeredPrices[0].price.unit;
                                    acc[key] = acc[key] || [];
                                    acc[key].push(settlement);
                                }
                                return acc;
                            }, {} as Record<string, Product[]>);
                            return Object.entries(settlementsByPrice).reduce((acc, [price, products]) => {
                                let name = '';
                                if (products.length > 2) {
                                    const names = products.map((settlement) => {
                                        return settlement.name.split(/-?abrechnung/i)[0];
                                    });
                                    name = names.slice(0, -1).join('-, ') + '- & ' + names.slice(-1) + '&shy;ab&shy;rech&shy;nung';
                                }
                                if (products.length === 2) {
                                    name =
                                        products
                                            .map((settlement) => {
                                                return settlement.name.split(/-?abrechnung/i)[0];
                                            })
                                            .join('- & ') + '&shy;ab&shy;rech&shy;nung';
                                }
                                if (products.length === 1) {
                                    name = products[0].name.replace(/((?<!-)abrechnung)/i, '&shy;$1');
                                }
                                if (price.includes('€')) {
                                    acc.push(`${name} ${price} pro Verordnung`);
                                } else {
                                    acc.push(`${name} zu ${price}`);
                                }
                                return acc;
                            }, [] as string[]);
                        })
                    )
                )
            ) || of([])
        );
    }

    protected readonly getSelectionClass = getSelectionClass;
}
