<div
    class="card bg-secondary h-full relative text-{{ alignment }} {{ hasBorder ? 'border-[3px] border-' + color : '' }} {{ cardClasses }}"
    #card
>
    <!--region HEADER-->
    <div class="relative">
        @if (headerText) {
        <h3 class="text-2xl font-medium card-header p-4 {{ headerClasses }} " [innerHTML]="headerText"></h3>
        } @if (header && !headerText) {
        <ng-container *ngTemplateOutlet="header"></ng-container>
        }

        <!--region ABSOLUTE POSITIONED ITEMS-->
        <div
            *ngIf="info || selectionType"
            class="absolute top-0 left-0 flex h-full w-full items-center justify-between p-4 text-left {{ absoluteHeaderClasses }}"
        >
            <rza-mean-checkbox
                *ngIf="checkbox || mixed"
                [selected]="isSelected"
                [withBorder]="checkboxHasBorder"
                [readOnly]="true"
                [checkedClasses]="checkedClasses || ''"
                [uncheckedClasses]="uncheckedClasses || ''"
            ></rza-mean-checkbox>
            <rza-mean-radio *ngIf="radio" [selected]="isSelected"></rza-mean-radio>

            <ng-container *ngIf="info || infoText">
                <rza-mean-info-btn
                    [borderClass]="'border-' + color + '-light'"
                    [bgClass]="'bg-' + color"
                    rzaMeanTooltip
                    *ngIf="infoText"
                    [tooltip]="infoText"
                    class="ml-auto"
                ></rza-mean-info-btn>
                <ng-container *ngIf="info">
                    <rza-mean-info-btn
                        [borderClass]="'border-' + color + '-light'"
                        [bgClass]="'bg-' + color"
                        rzaMeanTooltip
                        [forceWidth]="tooltipWidth"
                        [tooltip]="infoTemplate"
                        class="ml-auto"
                    ></rza-mean-info-btn>
                    <ng-template #infoTemplate>
                        <div rzaMeanListBullet>
                            <ng-container *ngTemplateOutlet="info"></ng-container>
                        </div>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
        <!--endregion-->
    </div>
    <!--endregion-->

    <div *ngIf="body || bodyText" class="card-body {{ bodyClasses }}">
        <span *ngIf="bodyText" [innerHTML]="bodyText"></span>
        <ng-container *ngTemplateOutlet="body"></ng-container>
    </div>

    <div *ngIf="footer || footerText" class="card-footer {{ footerClasses }}" [ngClass]="{ 'rounded-br-none': side || sideText }">
        <span class="text-2xl font-medium text-white" *ngIf="footerText" [innerHTML]="footerText"></span>
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>

    <div *ngIf="side || sideText">
        <div class="flex h-full flex-col items-end">
            <span *ngIf="sideText" class="whitespace-nowrap p-2 text-2xl font-medium text-inherit" [innerHTML]="sideText"></span>
            <ng-container *ngTemplateOutlet="side"></ng-container>
        </div>
    </div>
</div>
