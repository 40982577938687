<div
    *ngIf="visible"
    class="fixed top-0 left-0 z-[10001] overflow-hidden bg-white p-5"
    [@slideInOut]="{ value: visible ? 'in' : 'out', params: { translate: getTranslate() } }"
    [ngClass]="getClass()"
    [ngStyle]="{
        width: position === 'left' || position === 'right' ? getSize() : '100%',
        height: position === 'top' || position === 'bottom' ? getSize() : '100%'
    }"
>
    <div *ngIf="!hideClose" (click)="close()" class="text-muted mb-5 cursor-pointer text-right">X</div>
    <ng-content></ng-content>
</div>
