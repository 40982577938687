import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { VerticalPosition } from '../../directives/dropdown/dropdown.directive';

type CustomDate = { day: number; month: number; year: number };

@Component({
    selector: 'rza-mean-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
    ],
})
export class DatepickerComponent implements OnInit {
    @Input()
    label = '';

    @Input()
    position: VerticalPosition = 'auto';

    @Input()
    inputId: string | undefined;

    @Input()
    size: 'lg' | 'sm' | 'md' = 'md';

    @Input()
    max: Date | undefined;

    @Input()
    min: Date | undefined;

    monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

    dayNames = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
    today!: Date;
    selectedDate!: CustomDate;
    disabled = false;

    onChange: any = (val: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    get dateString(): string {
        const { day, month, year } = this.selectedDate;
        return `${day.toString().padStart(2, '0')}.${(month + 1).toString().padStart(2, '0')}.${year}`;
    }

    constructor() {
        this.today = new Date();
        this.setSelectedDate(this.today);
    }

    ngOnInit(): void {
        if (this.min) {
            this.min.setHours(0, 0, 0, 0);
            this.setSelectedDate(this.min);
        }
        this.max?.setHours(0, 0, 0, 0);
    }

    setSelectedDate(date: Date) {
        this.selectedDate = {
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
        };
    }

    updateSelectedDate({ day, month, year }: Partial<CustomDate>) {
        if (day != null) {
            this.selectedDate.day = day;
        }
        if (month != null) {
            this.selectedDate.month = month;
        }
        if (year != null) {
            this.selectedDate.year = year;
        }

        const daysInMonth = this.numberOfDaysInMonth(this.selectedDate.month, this.selectedDate.year);
        if (daysInMonth < this.selectedDate.day) {
            this.selectedDate.day = daysInMonth;
        }

        this.onChange(new Date(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day));
    }

    daysInMonth(month: number, year: number): number[] {
        return Array(this.numberOfDaysInMonth(month, year))
            .fill(0)
            .map((_, index) => index + 1);
    }

    private numberOfDaysInMonth(month: number, year: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    getDayOfWeek(date: Date): number {
        const day = date.getDay();
        if (day > 0) {
            return day - 1;
        }
        return 6;
    }

    daysBeforeFirst(month: number, year: number) {
        return Array(this.getDayOfWeek(new Date(year, month, 1))).fill(0);
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    writeValue(value: Date) {
        if (value) {
            this.setSelectedDate(value);
        }
    }

    registerOnTouched(onTouch: any) {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    setSelectedDay(day: number) {
        this.selectedDate.day = day;
    }

    isToday(day: number): boolean {
        return (
            this.today.getDate() === day &&
            this.today.getFullYear() === this.selectedDate.year &&
            this.today.getMonth() === this.selectedDate.month
        );
    }

    isSelected(day: number): boolean {
        return this.selectedDate.day === day;
    }

    onYearInput($event: any) {
        const value = parseInt($event.target.value, 10);
        if (isNaN(value) || value < 1000) {
            const fullYear = this.selectedDate.year;
            this.updateSelectedDate({ year: fullYear });
            $event.target.value = fullYear;
        } else {
            this.updateSelectedDate({ year: value });
        }
    }

    isMonthDisabled(month: number) {
        let minBool = false;
        if (this.min) {
            if (this.min.getFullYear() > this.selectedDate.year) {
                return true;
            }
            if (this.min.getFullYear() == this.selectedDate.year) {
                minBool = this.min.getMonth() > month;
            }
        }

        let maxBool = false;
        if (this.max) {
            if (this.max.getFullYear() < this.selectedDate.year) {
                return true;
            }
            if (this.max.getFullYear() == this.selectedDate.year) {
                maxBool = this.max.getMonth() < month;
            }
        }

        return minBool || maxBool;
    }

    isDateInRange(day: number, month: number, year: number) {
        const date = new Date(year, month, day);
        date.setHours(0, 0, 0, 0);
        let minBool = true;
        if (this.min) {
            minBool = date >= this.min;
        }

        let maxBool = true;
        if (this.max) {
            maxBool = date <= this.max;
        }
        return maxBool && minBool;
    }
}
