import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Bundle, Configuration, Discount, Price, Product } from '../../shared/interfaces';

export const setConfig = createAction('[App Init] Set Config', props<{ config: Configuration }>());
export const resetConfig = createAction('[Reset Config] Reset Config');
export const selectProfession = createAction('[Start Page] Select Profession', props<{ _id: string }>());

export const LoadConfigCodeActions = createActionGroup({
    source: 'Config Code/API',
    events: {
        Load: props<{ code: string }>(),
        'Load Success': props<{ config: Configuration }>(),
        'Load Failure': props<{ error: unknown }>(),
    },
});

export const addObligatoryProduct = createAction('[Select Profession] Add Obligatory Product', props<{ product: Product }>());

export const SettlementProductActions = createActionGroup({
    source: 'Settlement Products',
    events: {
        'Add Settlement Product': props<{ product: Product }>(),
        'Remove Settlement Product': props<{ _id: string }>(),
    },
});

export const ServiceProductActions = createActionGroup({
    source: 'Service Products',
    events: {
        'Add Service Product': props<{ product: Product; subProduct?: Product }>(),
        'Add Multiple Service Products': props<{ products: Product[] }>(),
        'Remove Service Product': props<{ _id: string }>(),
    },
});

export const SoftwareProductActions = createActionGroup({
    source: 'Software Products',
    events: {
        'Add Software Product': props<{ product: Product }>(),
        'Remove Software Product': props<{ _id: string }>(),
        'Add Software Addon Product': props<{ product: Product }>(),
        'Remove Software Addon Product': props<{ _id: string }>(),
    },
});

export const ConfigCodeActions = createActionGroup({
    source: 'Config Code',
    events: {
        'Generate Config Code': emptyProps(),
        'Set Config Code': props<{ code: string }>(),
    },
});

export const DiscountActions = createActionGroup({
    source: 'Discounts',
    events: {
        'Check Discounts Requirements': props<{ discounts: Discount[] | undefined }>(),
        'Activate Discounts': props<{ discounts: Discount[]; bundles?: Bundle[] }>(),
        'Set Configured Discounts Prices': props<{ discountWithRightPrices: { idDiscount: string; price: Price | undefined }[] }>(),
    },
});

export const BundleActions = createActionGroup({
    source: 'Bundle',
    events: {
        'Set Bundle': props<{ bundle: Bundle }>(),
        'Set Bundle Config': props<{ bundle: Bundle }>(),
        'Remove Bundle': emptyProps(),
    },
});

export const RevenueActions = createActionGroup({
    source: 'Revenue',
    events: {
        'Update Revenue': props<{ revenue: number; product: Product }>(),
        'Update Receipt Count': props<{ receiptCount: number; product: Product }>(),
        'Update Receipt Value': props<{ receiptValue: number; product: Product }>(),
        'Update Patient Count': props<{ patientCount: number; product: Product }>(),
        'Update Patient Revenue': props<{ patientRevenue: number; product: Product }>(),
    },
});

export const setConfiguredProductPrices = createAction(
    '[Product Selection] Set Price of configured Product on Select',
    props<{ idProduct: string; idSubProduct?: string; prices: Price[] }>()
);

export const setConfiguredProductPricesOnStartUpChanges = createAction(
    '[Start Up] Set Price of configured Products on Start Up Change',
    props<{ products: { idProduct: string; prices: Price[] }[] }>()
);

export const setPrefinancing = createAction('[Prefinancing Page] Set Prefinancing', props<{ product: Product }>());

export const setPrefinancingPriceDependentOnRevenueChange = createAction(
    '[Configurator Page] Revenue change',
    props<{ revenue: number }>()
);

export const setConfigVersion = createAction('[Environment] Set Config Version', props<{ version: string }>());

export const setIsStartUpConfirmed = createAction('Set isStartupOnlyConfirmed', props<{ isStartUpConfirmed: boolean }>());
