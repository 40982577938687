import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SessionService } from '../../services/session.service';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import { updateCompanyComponentGroup, updatePreviousSettlementCenter } from './company.actions';
import { selectCompany, selectCustomer } from './company.selectors';
import { PreviousSettlementCenter } from '../../shared/interfaces';

@Injectable()
export class CompanyEffects {
    updateCompany$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    updateCompanyComponentGroup.updateCompany,
                    updateCompanyComponentGroup.addSubsidiary,
                    updateCompanyComponentGroup.updateSubsidiary,
                    updateCompanyComponentGroup.removeSubsidiary,
                    updateCompanyComponentGroup.addContactPerson,
                    updateCompanyComponentGroup.updateContactPerson,
                    updateCompanyComponentGroup.removeContactPerson,
                    updateCompanyComponentGroup.updateCompanyForm,
                    updateCompanyComponentGroup.updateCompanyPerson,
                    updateCompanyComponentGroup.updateCompanyIdentificationForm
                ),
                concatLatestFrom(() => this.store.select(selectCompany)),
                filter(([action, company]) => company.businessForm != null),
                mergeMap(([action, company]) => this.sessionService.updateApiCompany(company))
            ),
        { dispatch: false }
    );

    updateCustomer$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    updateCompanyComponentGroup.updateBankCredentials,
                    updateCompanyComponentGroup.updateReceiptSubmission,
                    updateCompanyComponentGroup.updateReceiptEntranceNotification,
                    updatePreviousSettlementCenter.updatePreviousSettlementCenter,
                    updateCompanyComponentGroup.updateRecommendedBy
                ),
                concatLatestFrom(() => this.store.select(selectCustomer)),
                mergeMap(([action, customer]) => {
                    return this.sessionService.updateApiCustomer(customer);
                })
            ),
        { dispatch: false }
    );

    uploadTerminationFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updatePreviousSettlementCenter.uploadTerminationFile),
            // concatLatestFrom(() => this.store.select(selectPreviousSettlementCenter)),
            mergeMap((action) => {
                if (action.file != null) {
                    return this.sessionService.uploadTerminationFile(action.file).pipe(
                        filter((prev) => prev != null),
                        map((previousSettlementCenter: PreviousSettlementCenter) =>
                            updatePreviousSettlementCenter.updatePreviousSettlementCenterSuccess({ previousSettlementCenter })
                        ),
                        catchError((error) => of(updatePreviousSettlementCenter.updatePreviousSettlementCenterFailure({ error })))
                    );
                }
                return of(updatePreviousSettlementCenter.doNothing());
            })
        );
    });

    constructor(private actions$: Actions, private store: Store, private sessionService: SessionService) {}
}
