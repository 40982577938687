import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';

@Component({
    selector: 'rza-mean-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderComponent),
            multi: true,
        },
    ],
})
export class SliderComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('input') slider!: ElementRef;

    @Input()
    max: string | number | undefined;

    @Input()
    min: string | number | undefined;

    @Input()
    inputId: string | undefined;

    value = '';
    disabled = false;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: any = (val: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    ngAfterViewInit(): void {
        fromEvent(this.slider.nativeElement, 'input')
            .pipe(debounceTime(20), distinctUntilChanged())
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .subscribe(($event: Event) => this.onChange($event.target.value));
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    writeValue(value: string) {
        if (value != null) {
            this.value = value;
        }
    }

    registerOnTouched(onTouch: any) {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
