import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Product } from '../../shared/interfaces';
import { Store } from '@ngrx/store';
import { RevenueActions } from '../../store/configuration/configuration.actions';
import { selectConfiguredProduct } from '../../store/configuration/configuration.selectors';
import { Subject, takeUntil } from 'rxjs';
import { ProductCategoryEnum, RevenueTypeEnum } from '@rza-mean/api-interfaces';
import { getProductColor } from '../../shared/utils';

@Component({
    selector: '' + 'rza-mean-revenue-form',
    templateUrl: './revenue-form.component.html',
    styleUrls: ['./revenue-form.component.scss'],
})
export class RevenueFormComponent implements OnInit, OnDestroy {
    @Input() product!: Product;
    @Input() disableInputs = false;
    public count: number;
    public itemValue: number;
    public value: number;
    public fakeCount = 0;
    public fakeItemValue = 0;
    public fakeValue = 0;
    private unsubscribe: Subject<unknown>;
    isReceipt = false;
    isPatient = false;
    isReceiptRevenue = false;
    isAdditionalPayment = false;
    minCount = 1;

    get revenue(): number {
        if (this.isReceipt) {
            this.value = this.count * this.itemValue;
            return this.value;
        }
        return 0;
    }

    get receiptValue(): number {
        if (this.isReceiptRevenue) {
            if (this.count > 0) {
                this.itemValue = this.value / this.count;
                return this.itemValue;
            }
        }
        return 0;
    }

    //TODO: other revenue type, sync with config state
    constructor(private store: Store) {
        this.count = 0;
        this.itemValue = 0;
        this.value = 0;
        this.unsubscribe = new Subject();
    }

    ngOnInit(): void {
        this.store
            .select(selectConfiguredProduct(this.product._id))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((configuredProduct) => {
                if (configuredProduct && configuredProduct.category === ProductCategoryEnum.ADDITIONAL_PAYMENT) {
                    this.minCount = 0;
                }
                this.count =
                    (configuredProduct?.revenue?.count != null && configuredProduct?.revenue?.count >= 0
                        ? configuredProduct?.revenue?.count
                        : this.product.revenue?.count) ?? 0;
                this.itemValue =
                    (configuredProduct?.revenue?.itemValue != null && configuredProduct?.revenue?.itemValue >= 0
                        ? configuredProduct?.revenue?.itemValue
                        : this.product.revenue?.itemValue) ?? 0;
                this.value =
                    (configuredProduct?.revenue?.value != null && configuredProduct?.revenue?.value >= 0
                        ? configuredProduct?.revenue?.value
                        : this.product.revenue?.value) ?? 0;
            });
        this.isReceipt = this.product.revenue?.type === RevenueTypeEnum.RECEIPT;
        this.isPatient = this.product.revenue?.type === RevenueTypeEnum.PATIENT;
        this.isReceiptRevenue = this.product.revenue?.type === RevenueTypeEnum.RECEIPT_REVENUE;
        this.isAdditionalPayment = this.product.category === ProductCategoryEnum.ADDITIONAL_PAYMENT;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    // TODO: values are casted to string - find out why and fix it whoever you are
    onCountChange(): void {
        this.store.dispatch(RevenueActions.updateReceiptCount({ receiptCount: Number(this.count), product: this.product }));
        if (this.isReceiptRevenue) {
            this.store.dispatch(
                RevenueActions.updateReceiptValue({ receiptValue: Number(this.receiptValue.toFixed(2)), product: this.product })
            );
        }
    }

    onReceiptValueChange(): void {
        this.store.dispatch(RevenueActions.updateReceiptValue({ receiptValue: Number(this.itemValue), product: this.product }));
    }

    onRevenueValueChange(): void {
        this.store.dispatch(RevenueActions.updateRevenue({ revenue: Number(this.value), product: this.product }));
        if (this.isReceiptRevenue) {
            this.store.dispatch(
                RevenueActions.updateReceiptValue({ receiptValue: Number(this.receiptValue.toFixed(2)), product: this.product })
            );
        }
    }

    protected readonly getProductColor = getProductColor;
}
