import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SmallPrintService } from '../../services/small-print.service';

@Component({
    selector: 'rza-mean-small-print',
    template: `
        <div *ngIf="smallPrints.length" class="max-w-prose">
            <div *ngFor="let smallPrint of smallPrints; let index = index" class="mb-5">
                <small class="text-muted">
                    <sup class="font-bold">{{ index + 1 }}</sup>
                    <span class="pl-1" [innerHTML]="smallPrint"></span>
                </small>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./small-print.component.scss'],
})
export class SmallPrintComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public smallPrints: string[];

    constructor(private smallPrintService: SmallPrintService) {
        this.smallPrints = [];
    }

    ngOnInit(): void {
        this.smallPrintService
            .getSmallPrints()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((smallPrints) => (this.smallPrints = smallPrints));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
