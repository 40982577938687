import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ContactRequest, Discount, Feedback, MinimalProfessionsGroupedByBranches, Profession } from '../shared/interfaces';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    readonly baseUrl: string;

    constructor(private store: Store, private http: HttpClient) {
        this.baseUrl = environment.apiUrl;
    }

    loadProfession(id: string): Observable<Profession> {
        return this.http.get<Profession>(`${this.baseUrl}/professions/${id}`);
    }

    loadListOfProfessions(): Observable<MinimalProfessionsGroupedByBranches> {
        return this.http.get<MinimalProfessionsGroupedByBranches>(`${this.baseUrl}/professions/branches`);
    }

    loadDiscountCode(code: string): Observable<Discount> {
        return this.http.get<Discount>(`${this.baseUrl}/discounts/${code}`);
    }

    sendFeedback(feedback: Feedback): Observable<any> {
        return this.http.post(`${this.baseUrl}/feedback`, feedback, { withCredentials: true });
    }

    sendContactRequest(request: ContactRequest, isTestMode = false): Observable<any> {
        return this.http.post(`${this.baseUrl}/contact-requests`, { ...request, isTestMode }, { withCredentials: true });
    }
}
