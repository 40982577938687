import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { PageNavigationComponent } from '../components/page-navigation/page-navigation.component';
import { PageComponent } from '../components/page/page.component';
import { BreadcrumbsComponent } from '../components/breadcrumbs/breadcrumbs.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { SidebarItemComponent } from '../components/sidebar/sidebar-item/sidebar-item.component';
import { RevenueFormComponent } from '../components/revenue-form/revenue-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RevenueCardComponent } from '../components/revenue-card/revenue-card.component';
import { ProductCardComponent } from '../components/product-card/product-card.component';
import { UiModule } from '@rza-mean/ui';
import { SummarySidebarCardsComponent } from '../components/summary-sidebar-cards/summary-sidebar-cards.component';
import { PricePipe } from '../pipes/price.pipe';
import { PaymentSpeedPipe } from '../pipes/payment-speed.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        PageComponent,
        BreadcrumbsComponent,
        PageNavigationComponent,
        SidebarComponent,
        SidebarItemComponent,
        RevenueFormComponent,
        RevenueCardComponent,
        ProductCardComponent,
        SummarySidebarCardsComponent,
        PricePipe,
        PaymentSpeedPipe,
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, UiModule, FontAwesomeModule],
    providers: [DecimalPipe],
    exports: [
        PageNavigationComponent,
        PageComponent,
        BreadcrumbsComponent,
        SidebarComponent,
        RevenueFormComponent,
        RevenueCardComponent,
        ProductCardComponent,
        SummarySidebarCardsComponent,
        PricePipe,
        PaymentSpeedPipe,
    ],
})
export class SharedModule {}
