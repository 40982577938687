<img
    *ngIf="image && !isSVG"
    [alt]="alt"
    [src]="image"
    (load)="loaded.emit()"
    [ngClass]="classes"
    [ngStyle]="{ width: width, height: height }"
/>

<div *ngIf="isSVG" #svg></div>
