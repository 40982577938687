import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyDataComponent } from './company-data.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '@rza-mean/ui';
import { CompanyBranchFormComponent } from './company-branch-form/company-branch-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoToggleComponent } from './info-toggle/info-toggle.component';
import { ContactPersonFormComponent } from './contact-person-form/contact-person-form.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { CompanyPersonFormComponent } from './company-person-form/company-person-form.component';
import { CompanyIdentificationFormComponent } from './company-identification-form/company-identification-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const routes: Routes = [
    {
        path: '',
        component: CompanyDataComponent,
    },
];

@NgModule({
    declarations: [
        CompanyDataComponent,
        CompanyBranchFormComponent,
        InfoToggleComponent,
        ContactPersonFormComponent,
        CompanyFormComponent,
        CompanyPersonFormComponent,
        CompanyIdentificationFormComponent,
    ],
    imports: [CommonModule, RouterModule.forChild(routes), SharedModule, UiModule, ReactiveFormsModule, FormsModule, FontAwesomeModule],
})
export class CompanyDataModule {}
