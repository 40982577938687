import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '@rza-mean/ui';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
    {
        path: '',
        component: DocumentsComponent,
    },
];

@NgModule({
    declarations: [DocumentsComponent],
    imports: [CommonModule, RouterModule.forChild(routes), SharedModule, UiModule, FormsModule],
})
export class DocumentsModule {}
