import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Price, Product } from '../shared/interfaces';
import { PriceDependencyTypeEnum } from '@rza-mean/api-interfaces';
import {
    selectConfiguredBundleId,
    selectConfiguredProduct,
    selectIsStartUpConfirmed,
} from '../store/configuration/configuration.selectors';
import { map, Observable, of, combineLatest, filter } from 'rxjs';
import { CalculationService } from './calculation.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private store: Store, private calculationService: CalculationService) {}

    findPrices$(product: Product): Observable<Price[]> {
        return combineLatest([this.findAppliedPricesArray$(product), this.store.select(selectConfiguredProduct(product._id))]).pipe(
            filter((product) => product != null),
            map(([prices, configuredProduct]) => {
                return prices
                    .filter((price): price is Price => price != null)
                    .map((price: Price) => this.mapFunctionalPrices(price, configuredProduct));
            })
        );
    }

    findAppliedPricesArray$(product: Product) {
        const prices: Observable<Price | undefined>[] = [];
        if (product.dependentPrices && product.dependentPrices.length > 0) {
            if (product.dependentPrices[0].type === PriceDependencyTypeEnum.BUNDLE) {
                prices.push(
                    this.store
                        .select(selectConfiguredBundleId)
                        .pipe(map((bundleId) => product.dependentPrices?.find((price) => price.referenceId === bundleId)?.price))
                );
            }
            if (product.dependentPrices[0].type === PriceDependencyTypeEnum.STARTUP) {
                prices.push(
                    this.store
                        .select(selectIsStartUpConfirmed)
                        .pipe(
                            map(
                                (isStartupConfirmed) =>
                                    product.dependentPrices?.find((price) => String(isStartupConfirmed) === price.referenceId)?.price
                            )
                        )
                );
            }
        }
        if (product.staggeredPrices && product.staggeredPrices.length > 0) {
            const staggeredPrice = this.calculationService.findStaggeredPrice$(product);
            prices.push(staggeredPrice);
        }
        if (product.prices && product.prices.length > 0) {
            prices.push(of(product.prices[0]));
        }
        return combineLatest(prices);
    }

    mapFunctionalPrices(price: Price, configuredProduct: Product | undefined): Price {
        if (price?.functionalPrice) {
            if (configuredProduct) {
                const valueFromFunctionalPrice = this.calculationService.getValueFromFunctionalPrice(configuredProduct, price);
                return {
                    ...price,
                    value: valueFromFunctionalPrice,
                };
            }
            return price;
        }
        return price;
    }
}
