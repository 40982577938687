<input
    #input
    type="range"
    [id]="inputId"
    [value]="value"
    (blur)="onTouched()"
    [min]="min"
    [max]="max"
    [disabled]="disabled"
    class="mb-6 h-[.25rem] w-full cursor-pointer appearance-none rounded-lg !bg-white"
/>
