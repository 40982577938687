import { Component, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'rza-mean-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
    @Input() headline?: string;
    @Input() subHeadline?: string;
    @Input() modalTitle?: string;
    @Input() modalContent?: string;

    constructor(private modalService: ModalService) {}
    //TODO: headline types
    openInfoModal() {
        if (this.modalTitle && this.modalContent) {
            this.modalService.open(this.modalTitle, this.modalContent);
        }
    }
}
