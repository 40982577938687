<div class="flex flex-col items-center text-center print:!mb-6">
    <h1 *ngIf="headline" class="mb-5 flex items-center">
        <div
            [innerHTML]="headline"
            class="text-primary text-[1.75rem] font-semibold leading-tight md:text-4xl print:text-xl print:font-medium"
        ></div>
        <rza-mean-info-btn *ngIf="modalTitle && modalContent" (click)="openInfoModal()"></rza-mean-info-btn>
    </h1>
    <p *ngIf="subHeadline" [innerHTML]="subHeadline" class="mb-5 max-w-prose text-[1rem] leading-tight md:text-xl"></p>
</div>
