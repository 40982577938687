<div class="">
    <button
        rzaMeanShiny
        type="button"
        (click)="toggleOpen()"
        class="bg-secondary group flex w-full cursor-pointer items-center justify-between px-3 py-3 text-lg font-medium text-white transition duration-300 print:hidden"
        [ngClass]="{
            'rounded-t-2xl': isFirst,
            'rounded-b-2xl': isLast && !isOpen,
            'from-primary bg-gradient-to-r': isOpen,
            '!text-white': isOpen
        }"
    >
        <ng-content select="[accordion-header]"></ng-content>
        <fa-icon
            icon="caret-down"
            class="text-white transition duration-300 group-hover:text-white"
            [ngClass]="{ '!text-white': isOpen }"
        ></fa-icon>
    </button>
    <div @slideDown *ngIf="isOpen" class="overflow-hidden">
        <div [class]="contentClasses" [ngClass]="{ 'rounded-b-2xl': isLast && isOpen, 'border-b-0': !isLast }">
            <ng-content></ng-content>
        </div>
    </div>
</div>
