import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb, Profession } from '../shared/interfaces';
import { Store } from '@ngrx/store';
import { selectConfiguredBundleId } from '../store/configuration/configuration.selectors';
import { SessionService } from './session.service';
import { ProductTypeEnum } from '@rza-mean/api-interfaces';
import { selectSelectedProfession } from '../store/profession/profession.selectors';
import { MinimalProfessionActions } from '../store/profession/profession.actions';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _breadcrumbs: BehaviorSubject<Array<Breadcrumb>>;
    private breadcrumbs$: Observable<Array<Breadcrumb>>;
    private currentUrl: string;
    private isNavigating: boolean;
    private currentProfession$: Observable<Profession>;
    public isTestMode = false;
    private initialBreadcrumbs = [
        {
            url: '/',
            title: 'Berufsgruppe',
            navigationText: 'Berufsgruppe',
            order: 0,
            visited: true,
            isCurrent: true,
        },
        {
            url: '/paket',
            title: 'Paket',
            navigationText: 'Jetzt konfigurieren',
            order: 1,
            visited: false,
            isCurrent: false,
        },
        {
            url: '/umsatz',
            title: 'Patientenzahl/&shy;Umsatz',
            navigationText: 'Weiter',
            order: 3,
            visited: false,
            isCurrent: false,
        },
        {
            url: '/vorfinanzierung',
            title: 'Auszahlungs&shy;geschwindigkeit',
            navigationText: 'Weiter',
            order: 4,
            visited: false,
            isCurrent: false,
        },
        {
            url: '/zusammenfassung',
            title: 'Unsere Leistungen für Sie',
            navigationText: 'Weiter',
            order: 5,
            visited: false,
            isCurrent: false,
        },
        {
            url: '/unternehmensdaten',
            title: 'Unternehmens&shy;daten',
            navigationText: 'Weiter',
            order: 6,
            visited: false,
            isCurrent: false,
        },
        {
            url: '/kontodaten',
            title: 'Kontodaten',
            navigationText: 'Weiter',
            order: 7,
            visited: false,
            isCurrent: false,
        },
    ] as Breadcrumb[];
    private navigationEnd$: Observable<NavigationEnd>;

    constructor(private router: Router, private route: ActivatedRoute, private store: Store, private sessionService: SessionService) {
        this._breadcrumbs = new BehaviorSubject(this.initialBreadcrumbs);
        this.breadcrumbs$ = this._breadcrumbs.asObservable().pipe(filter((breadcrumbs) => breadcrumbs && breadcrumbs?.length > 0));
        this.currentUrl = '';
        this.isNavigating = false;
        this.navigationEnd$ = this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd));
        this.currentProfession$ = this.store.select(selectSelectedProfession).pipe(filter((profession) => '_id' in profession));
        this.currentProfession$.pipe(tap((profession) => this.initBreadcrumbs(profession))).subscribe();
        combineLatest([
            this.currentProfession$,
            this.store.select(selectConfiguredBundleId).pipe(
                filter((id) => id != null),
                distinctUntilChanged()
            ),
            this.breadcrumbs$.pipe(
                distinctUntilChanged((prev, curr) => {
                    if (prev.length !== curr.length) {
                        return false;
                    }
                    const breadcrumbs = curr.filter((breadcrumb, index) => JSON.stringify(breadcrumb) !== JSON.stringify(prev[index]));
                    return breadcrumbs.length === 0;
                })
            ),
        ])
            .pipe(tap(([profession, bundleId, breadcrumbs]) => this.filterBreadcrumbs(profession, bundleId, breadcrumbs)))
            .subscribe();
    }

    init(): void {
        this.store.dispatch(MinimalProfessionActions.load());
        this.navigationEnd$
            .pipe(
                take(1),
                switchMap(({ urlAfterRedirects }) =>
                    this.sessionService.init().pipe(
                        tap((session) => {
                            if (!session && urlAfterRedirects !== '/') {
                                this.router.navigate(['/']).then();
                            }
                        })
                    )
                )
            )
            .subscribe();

        this.initNavigationListening();
    }

    private initNavigationListening() {
        combineLatest([this.navigationEnd$, this.currentProfession$])
            .pipe(
                map(([event, profession]) => ({
                    event,
                    profession,
                })),
                switchMap(({ event, profession }) =>
                    this.breadcrumbs$.pipe(
                        distinctUntilChanged((prev, curr) => {
                            return prev.filter((b) => b.visited).length === curr.filter((b) => b.visited).length;
                        }),
                        tap((breadcrumbs) => {
                            this.currentUrl = event.urlAfterRedirects.split('?')[0];
                            const currentIndex = breadcrumbs.findIndex((breadcrumb) => breadcrumb.url === this.currentUrl);
                            breadcrumbs = breadcrumbs.map((breadcrumb, index) => ({
                                ...breadcrumb,
                                isCurrent: index === currentIndex,
                                visited: breadcrumb.visited || index <= currentIndex,
                            }));
                            this._breadcrumbs.next(breadcrumbs);
                        })
                    )
                )
            )
            .subscribe();
    }

    public initBreadcrumbs({ navigationItems }: Profession): void {
        if (navigationItems?.length) {
            const breadcrumbs: Breadcrumb[] = [...navigationItems]
                .sort((a, b) => a.order - b.order)
                .map((_) => ({
                    ..._,
                    visited: false,
                    isCurrent: false,
                }));
            breadcrumbs[0].isCurrent = true;
            breadcrumbs[0].visited = true;
            this._breadcrumbs.next(breadcrumbs);
        } else {
            this._breadcrumbs.next(this.initialBreadcrumbs);
        }
    }

    public getNextBreadcrumb(step: number = 1): Observable<Breadcrumb | null> {
        return this.getVisibleBreadcrumbs().pipe(
            map((breadcrumbs) => {
                const index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.isCurrent) + step;
                return index > -1 ? breadcrumbs[index] : null;
            })
        );
    }

    public getPreviousBreadcrumb(): Observable<Breadcrumb | null> {
        return this.getVisibleBreadcrumbs().pipe(
            map((breadcrumbs) => {
                const index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.isCurrent);
                if (index > 0) {
                    return breadcrumbs[index - 1];
                }
                return null;
            })
        );
    }

    public isFirstPage(): Observable<boolean> {
        return this.breadcrumbs$.pipe(
            map((breadcrumbs) => {
                const breadcrumb = breadcrumbs.find((_) => _.isCurrent);
                return breadcrumb != null && breadcrumbs.indexOf(breadcrumb) === 0;
            })
        );
    }

    public isLastPage(): Observable<boolean> {
        return this.breadcrumbs$.pipe(
            map((breadcrumbs) => {
                return breadcrumbs.findIndex((breadcrumb) => breadcrumb.isCurrent) === breadcrumbs.length - 1;
            })
        );
    }

    navigateToNextPage(): void {
        if (!this.isNavigating) {
            this.isNavigating = true;
            this.getNextBreadcrumb()
                .pipe(
                    filter((_): _ is Breadcrumb => _ !== null),
                    take(1)
                )
                .subscribe((breadcrumb) => {
                    if (breadcrumb) {
                        this.router.navigate([breadcrumb.url]).then(() => {
                            this.isNavigating = false;
                        });
                    }
                });
        }
    }

    navigateToPreviousPage(): void {
        if (!this.isNavigating) {
            this.isNavigating = true;
            this.getPreviousBreadcrumb()
                .pipe(
                    filter((_): _ is Breadcrumb => _ != null),
                    take(1)
                )
                .subscribe((breadcrumb) => {
                    this.router.navigate([breadcrumb.url]).then(() => {
                        this.isNavigating = false;
                    });
                });
        }
    }

    public setPage(breadcrumb: Breadcrumb): void {
        const nextUrl = [breadcrumb.url];
        this.router.navigate(nextUrl).then(() => {
            this.currentUrl = breadcrumb.url;
            breadcrumb.isCurrent = true;
        });
    }

    getVisibleBreadcrumbs(): Observable<Breadcrumb[]> {
        return this.breadcrumbs$.pipe(map((breadcrumbs) => breadcrumbs.filter((breadcrumb) => !breadcrumb.hidden)));
    }

    getRouteParams() {
        return this.route.queryParams;
    }

    setTestMode() {
        this.isTestMode = true;
    }

    getSubtitle(): Observable<string | undefined> {
        return this.breadcrumbs$.pipe(
            map((breadcrumbs) => {
                const index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.isCurrent);
                return breadcrumbs[index].subtitle;
            })
        );
    }

    private filterBreadcrumbs({ promotedBundles, products }: Profession, bundleId: string | undefined, breadcrumbs: Breadcrumb[]) {
        // hide additional services page, if no services in bundle
        const breadcrumb = breadcrumbs.find((item) => item.url === '/zusatzoptionen');
        if (breadcrumb && promotedBundles && products) {
            const bundle = promotedBundles[0].bundles.find((bun) => bun._id === bundleId);
            if (bundle) {
                const includedProductIds = bundle.config?.configuredProfession?.products
                    .filter((product) => !product.products?.length)
                    .map((product) => product._id);
                const includedProductWithNestedProductIds = bundle.config?.configuredProfession?.products
                    .filter((service) => !includedProductIds?.includes(service._id) && !bundle.excludedProducts?.includes(service._id))
                    .filter((product) => product.products?.length)
                    .map((product) => product._id);
                const additionalServicesInBundle = products
                    .filter(
                        (product) =>
                            !bundle.excludedProducts?.includes(product._id) &&
                            product.type === ProductTypeEnum.SERVICE &&
                            !product.isOmittedFromSelection
                    )
                    .filter((service) => !includedProductIds?.includes(service._id))
                    .filter((service) => {
                        // filter products with only one nested product, that are pre-configured and not customizable
                        if (service.products?.length === 1) {
                            if (includedProductWithNestedProductIds?.includes(service._id)) {
                                const preConfigured = bundle?.config?.configuredProfession?.products.find((_) => _._id === service._id);
                                if (
                                    preConfigured &&
                                    preConfigured.products?.length === 1 &&
                                    preConfigured.products[0]._id === service.products[0]._id
                                ) {
                                    return false;
                                }
                            }
                        }
                        return true;
                    });
                breadcrumb.hidden = additionalServicesInBundle.length === 0;
            }
        }
        this._breadcrumbs.next(breadcrumbs);
    }
}
