export enum DocumentTypeEnum {
    HANDELSREGISTERAUSZUG = 'Handelsregisterauszug',
    AUSWEIS_VORDERSEITE = 'Ausweis Vorderseite',
    AUSWEIS_RUECKSEITE = 'Ausweis Rückseite',
    UNTERSCHRIFT = 'Unterschrift',
    VEREINSREGISTERAUSZUG = 'Vereinsregisterauszug',
    HANDELSREGISTERAUSZUG_KOMPLEMENTAER = 'Handelsregisterauszug Komplementär',
    GESCHAEFTSFUEHRERBESTELLUNG = 'Geschäftsführerbestellung',
    ZULASSUNG_VDEK = 'Zulassung vdek',
    GESELLSCHAFTSVERTRAG = 'Gesellschaftsvertrag',
    BESTAETIGUNG_DES_BEITRITTS_ZUM_VERBANDSTARIF = 'Bestätigung des Beitritts zum Verbandstarif',
    VERBANDSMITGLIEDSCHAFT = 'Verbandsmitgliedschaft (Nachweis)',
    MIETWAGENGENEHMIGUNG_TAXIKONZESSION = 'Mietwagengenehmigung oder Taxikonzession',
    SATZUNG = 'Satzung',
    GESELLSCHAFTERLISTE = 'Gesellschafterliste',
    WKN_ISIN = 'WKN/ISIN',
    PARTNERSCHAFTSREGISTERAUSZUG = 'Partnerschaftsregisterauszug',
    GEWERBEANMELDUNG_FINANZAMT = 'Gewerbeanmeldung Finanzamt',
    GEWERBEANMELDUNG_KOMMUNE = 'Gewerbeanmeldung Kommune',
    GENOSSENSCHAFTSREGISTERAUSZUG = 'Genossenschaftsregisterauszug',
    MITGLIEDSCHAFT_TAXIZENTRALE = 'Bestätigung Mitgliedschaft Taxizentrale',
    ARGE_ZULASSUNG = 'ARGE-Zulassung',
    PRAEQUALIFIZIERUNGSURKUNDEN = 'Präqualifizierungsurkunden',
    ZULASSUNG_KNAPPSCHAFT = 'Zulassung Knappschaft',
    ZULASSUNG_PRIMAERKASSEN = 'Zulassung Primärkassen',
    ABRECHNUNGSKUENDIGUNGSBESTAETIGUNG = 'Abrechnungskündigungsbestätigung',
}
