import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { enableMapSet } from 'immer';
import { NavigationService } from './services/navigation.service';
import { map } from 'rxjs';
import { GoogleTagManagerService } from './services/google-tag-manager.service';

@Component({
    selector: 'rza-mean-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private title = 'rza-angular-client';
    testMode = false;

    constructor(public navigationService: NavigationService, private gtmService: GoogleTagManagerService) {
        registerLocaleData(localeDe);
        enableMapSet();
        this.navigationService.init();
        this.navigationService
            .getRouteParams()
            .pipe(map((params) => 'test' in params))
            .subscribe((isTestMode) => {
                if (isTestMode) navigationService.setTestMode();
            });
        this.initUsercentrics();
        this.gtmService.init();
    }

    private initUsercentrics() {
        const d = document;
        const g = d.createElement('script');
        g.type = 'application/javascript';
        g.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
        g.id = 'usercentrics-cmp';
        g.setAttribute('data-settings-id', '9WYbDvlaY');
        d.head.insertBefore(g, d.head.firstChild);
    }
}
