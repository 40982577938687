import { Company, Customer, Document } from '../../shared/interfaces';
import { environment } from '../../../environments/environment';

export interface CompanyState {
    company: Company;
    customer: Customer;
    documents: Document[];
    loading: boolean;
    error: unknown;
}
// TODO: delete test data
export const initialCompanyState: CompanyState = {
    company: {
        name: environment.companyData.name || '',
        businessForm: {
            _id: '',
            name: '',
        },
        contact: {
            firstname: environment.companyData.firstName || '',
            lastname: environment.companyData.lastName || '',
            email: environment.companyData.email || '',
            phone: environment.companyData.phone || '',
            salutation: environment.companyData.salutation,
            title: environment.companyData.title,
            address: {
                street: environment.companyData.street || '',
                zip: environment.companyData.zip || '',
                city: environment.companyData.city || '',
                country: environment.companyData.country,
            },
            isPep: false,
        },
        institutionCode: environment.companyData.institutionCode || '',
        taxId: environment.companyData.taxId || '',
        taxCountryCode: environment.companyData.taxCountryCode,
    },
    customer: {
        _id: '',
        bankCredentials: {
            iban: environment.companyData.iban,
            bic: environment.companyData.bic,
            institute: environment.companyData.bankInstitute,
            owner: environment.companyData.accountOwner,
        },
    },
    documents: [],
    loading: false,
    error: {},
};
