<header class="relative z-10 shadow-sm">
    <nav class="container flex h-14 items-center justify-between">
        <a class="block" href="/">
            <img alt="" src="../../../assets/images/optadata.svg" class="h-[25px] w-auto" />
        </a>

        <div class="flex">
            @if (phone$ | async; as phoneNumber) {
            <a
                [href]="'tel:' + (hrefPhone$ | async)"
                class="text-muted hover:text-primary group flex cursor-pointer items-center pl-2 lg:pl-4"
            >
                <fa-icon class="text-muted group-hover:text-primary pr-1" icon="phone"></fa-icon>
                <span class="hidden lg:block">{{ phoneNumber }}</span></a
            >
            } @if (email$ | async; as email) {
            <a [href]="'mailto:' + email" class="text-muted hover:text-primary group flex cursor-pointer items-center pl-2 lg:pl-4">
                <fa-icon class="text-muted group-hover:text-primary pr-1" icon="envelope"></fa-icon>
                <span class="hidden lg:block">{{ email }}</span>
            </a>
            }
        </div>
    </nav>
</header>

<router-outlet></router-outlet>

<footer class="footer-shadow mt-auto">
    <div class="container flex h-10 items-center justify-between">
        <img alt="optadata" src="../../../assets/images/optadata-light.svg" class="h-auto w-[70px]" />
        <div class="flex gap-4 font-light">
            <!--            <a href="https://www.optadata.de/agb" target="_blank">AGB</a>-->
            <a href="https://www.optadata.de/datenschutz" target="_blank">Datenschutz</a>
            <a href="https://www.optadata.de/impressum" target="_blank">Impressum</a>
        </div>
    </div>
</footer>
