<form [formGroup]="bankCredentialsForm">
    <div class="mb-6">
        <rza-mean-input formControlName="institute" label="Kreditinstitut*"></rza-mean-input>
    </div>
    <div class="mb-6">
        <rza-mean-input formControlName="owner" label="Kontoinhaber*"></rza-mean-input>
    </div>
    <div class="mb-6">
        <rza-mean-input formControlName="iban" [autocomplete]="'off'" label="IBAN*"></rza-mean-input>
    </div>
    <div>
        <rza-mean-input formControlName="bic" label="BIC"></rza-mean-input>
    </div>
</form>
