import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { selectConfiguredIdProfession } from '../../store/configuration/configuration.selectors';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { ContactRequestService } from '../../services/contact-request.service';
import { selectEmail, selectPhoneNumber } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-contact-request',
    templateUrl: './contact-request.component.html',
    styleUrl: './contact-request.component.css',
})
export class ContactRequestComponent implements OnInit, AfterViewInit, OnDestroy {
    userCentricButton: HTMLButtonElement | undefined;
    phone$: Observable<string | undefined> = of(undefined);
    hrefPhone$: Observable<string | undefined> = of(undefined);
    email$: Observable<string | undefined> = of(undefined);
    private unsubscribe$ = new Subject();

    @ViewChild('contact', { static: true }) contact!: ElementRef;

    constructor(private renderer: Renderer2, private store: Store, public contactRequestService: ContactRequestService) {}

    ngOnInit(): void {
        this.store
            .select(selectConfiguredIdProfession)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((id) => {
                this.contactRequestService.setIdProfession(id);
            });
        this.getPhoneAndEmail()
    }

    ngAfterViewInit(): void {
        this.contactRequestService.setElementRef(this.contact);
        this.waitForUserCentricsButton();
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    waitForUserCentricsButton(): void {
        const targetNode = this.renderer.selectRootElement('body', true);
        const config = { childList: true, subtree: true };

        const callback = (mutationsList: MutationRecord[]) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const usercentricsRoot = document.getElementById('usercentrics-root');
                    const shadowRoot = usercentricsRoot?.shadowRoot;

                    if (shadowRoot) {
                        const shadowObserver = new MutationObserver((shadowMutations) => {
                            for (const shadowMutation of shadowMutations) {
                                if (shadowMutation.type === 'childList' && shadowMutation.addedNodes.length > 0) {
                                    const nestedButton = shadowRoot.querySelector('button');
                                    if (nestedButton instanceof HTMLButtonElement) {
                                        // shadowObserver.disconnect();
                                        // observer.disconnect();
                                        this.userCentricButton = nestedButton;
                                        break;
                                    }
                                }
                            }
                        });

                        const shadowConfig = { childList: true, subtree: true };
                        shadowObserver.observe(shadowRoot, shadowConfig);
                        break; // Exit the loop once shadowRoot is found and observed
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }

    private getPhoneAndEmail() {
        this.phone$ = this.store.select(selectPhoneNumber);
        this.hrefPhone$ = this.phone$.pipe(
            map((phone) => {
                phone = phone?.replace(/ /g, '') || '';
                if (phone?.length > 0 && phone?.charAt(0) === '0') {
                    phone = phone.replace('0', '+49');
                }
                return phone;
            })
        );
        this.email$ = this.store.select(selectEmail);
    }
}
