<rza-mean-page>
    <rza-mean-section>
        <rza-mean-title
            headline="Zusatzleistungen"
            subHeadline="Passen Sie unseren Tarif genau an Ihren persönlichen Bedarf an – mit unseren Zusatzleistungen."
        >
        </rza-mean-title>
    </rza-mean-section>
    <rza-mean-section *ngFor="let category of servicesCategorized$ | async" class="flex flex-grow flex-col justify-center">
        <h3 class="text-primary mb-5 text-lg font-bold">{{ category[0] }}</h3>
        <div class="grid grid-cols-1 gap-12 lg:grid-cols-2 xl:grid-cols-3">
            <div
                *ngFor="let service of category[1]"
                class="max-lg:col-span-1"
                [ngClass]="{
                    'col-span-3': service.products && service.products.length > 2,
                    'col-span-2': service.products && service.products.length === 2
                }"
            >
                <rza-mean-service-card [product]="service" (infoModal)="openModal($event)"></rza-mean-service-card>
            </div>
        </div>
    </rza-mean-section>
    <rza-mean-page-navigation></rza-mean-page-navigation>
</rza-mean-page>
