<rza-mean-page [showBreadcrumbs]="!orderCompleted">
    <ng-container *ngIf="!orderCompleted">
        <rza-mean-section>
            <rza-mean-title headline="Kontodaten" subHeadline="Fast geschafft. Es fehlen nur noch die Kontodaten."></rza-mean-title>
        </rza-mean-section>
        <rza-mean-section class="flex flex-grow flex-col justify-center">
            <rza-mean-bank-credentials-form
                (updateBankCredentials)="updateBankCredentialsForm($event)"
                (isValid)="isBankCredentialFormValid = $event"
            ></rza-mean-bank-credentials-form>
            <hr class="mb-6 mt-4" />
            <form [formGroup]="recommendedByForm">
                <div>
                    <rza-mean-input formControlName="recommendedBy" label="Empfohlen von"></rza-mean-input>
                </div>
            </form>
            <div class="mb-5"><small>*Pflichtfelder</small></div>
            <rza-mean-checkbox
                [(ngModel)]="agbAccepted"
                (ngModelChange)="acceptAgb()"
                [label]="agbLabel"
                uncheckedClasses="!text-primary"
                checkedClasses="text-primary"
            ></rza-mean-checkbox>
        </rza-mean-section>

        <rza-mean-page-navigation
            form_id="246"
            nextPageBtnText="Antrag einreichen"
            [disabled]="!disableNavigation()"
            [callback]="complete.bind(this)"
            [showSpinner]="showSpinner"
        ></rza-mean-page-navigation>
    </ng-container>

    <rza-mean-section *ngIf="orderCompleted">
        <div class="grid grid-cols-12">
            <div class="col-span-12 flex items-end xl:col-span-8">
                <rza-mean-title
                    headline="Herzlichen Glückwunsch!"
                    subHeadline="Ihr Antrag ist eingegangen. In Kürze erhalten Sie dazu eine Bestätigung per E-Mail."
                >
                </rza-mean-title>
            </div>
            <div class="col-span-12 flex items-end justify-end xl:col-span-4">
                <div class="max-w-[450px] flex-grow">
                    <object
                        class="w-full"
                        data="../../../assets/images/Berufsgruppenwahl.svg"
                        id="svg-profession-branches"
                        type="image/svg+xml"
                    >
                        <img alt="" class="w-full" src="../../../assets/images/Berufsgruppenwahl.svg" />
                    </object>
                </div>
            </div>
        </div>
    </rza-mean-section>
</rza-mean-page>
