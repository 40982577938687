import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { CompanyForm, CompanyIdentificationForm, CompanyPerson } from '../../shared/interfaces';
import { updateCompanyComponentGroup } from '../../store/company/company.actions';

@Component({
    selector: 'rza-mean-company-data',
    templateUrl: './company-data.component.html',
    styleUrls: ['./company-data.component.scss'],
})
export class CompanyDataComponent implements OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    private unsubscribe$ = new Subject();
    isCompanyFormValid = false;
    isCompanyPersonFormValid = false;
    isCompanyIdentificationFormValid = false;

    constructor(private store: Store) {}

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    updateCompanyForm(companyForm: CompanyForm) {
        this.store.dispatch(updateCompanyComponentGroup.updateCompanyForm({ companyForm }));
    }

    updateCompanyPersonForm(companyPerson: CompanyPerson) {
        this.store.dispatch(updateCompanyComponentGroup.updateCompanyPerson({ companyPerson }));
    }

    updateCompanyIdentificationForm(companyIdentification: CompanyIdentificationForm) {
        this.store.dispatch(updateCompanyComponentGroup.updateCompanyIdentificationForm({ companyIdentification }));
    }

    disableNavigation() {
        return this.isCompanyFormValid && this.isCompanyPersonFormValid && this.isCompanyIdentificationFormValid;
    }
}
